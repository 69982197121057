import React, { useEffect, useRef } from 'react';
import { Text } from 'styles';
import { Icon } from 'components/icon/Icon';
import * as S from './ModalStyle';
import { Dim } from '../Dim';

export function Modal({ open, setOpen, children, title, $color, notClose, $path, ...props }) {
  const conRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (conRef.current) {
        conRef.current.scrollTop = 0;
      }
    }, 0);
  }, [open]);

  return (
    <Dim open={open}>
      <S.ModalContainer $path={$path} {...props}>
        <S.TitleBox $path={$path}>
          <Text $type="b24" $tType="b18" $color={(notClose && 'gray800') || $color || 'primary'}>
            {title || 'Info'}
          </Text>
          {!notClose && <Icon name="Close" $tSvgWidth={30} $tSvgHeight={30} onClick={() => setOpen()} />}
        </S.TitleBox>
        <S.ContentBox ref={conRef} $path={$path}>
          {children}
        </S.ContentBox>
      </S.ModalContainer>
    </Dim>
  );
}
