import styled, { css } from 'styled-components';
import { Box, RowBox, slideDown } from 'styles';

export const ModalContainer = styled(Box)`
  background-color: white;
  border-radius: 8px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  @media ${({ theme }) => theme.device.tablet} {
    width: 90%;
    max-width: unset;
    min-width: unset;

    ${({ $path }) =>
      ($path === 'price' || $path === 'info') &&
      css`
        padding: 16px;
      `}
  }
`;

export const TitleBox = styled(RowBox)`
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  padding: 16px 0 10px;
  margin: 0 16px;

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $path }) =>
      ($path === 'price' &&
        css`
          padding: 8px 8px 16px;
          margin: 0;
        `) ||
      ($path === 'info' &&
        css`
          padding: 8px 8px 10px;
          margin: 0;
        `) ||
      css`
        padding: 24px 0 10px;
        margin: 0 24px;
      `}
  }
`;

export const ContentBox = styled(Box)`
  margin-top: 24px;
  max-height: 80vh;
  overflow: auto;
  padding: 0 16px 16px;

  @media ${({ theme }) => theme.device.tablet} {
    max-height: calc(80vh - 140px);
    margin-top: 0;

    ${({ $path }) =>
      ($path === 'price' &&
        css`
          padding: 8px 22px 8px 8px;
          margin-top: 0;
        `) ||
      ($path === 'info' &&
        css`
          padding: 28px 0 0;
        `) ||
      css`
        padding: 24px;
      `}
  }
`;

// delModal

export const DelModalContainer = styled(Box)`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  max-width: 516px;
  min-width: 300px;

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    min-width: unset;
  }
`;
