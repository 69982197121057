export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const DEFAULT_PAGE_SIZE = 10;

export const SIGNUP_ROUTE = {
  email: "email",
  kakao: "kakao",
  naver: "naver",
  google: "google"
}

export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_FORMAT_KO = 'YY년 MM월 DD일 (ddd)';
export const API_DATETIME_FORMAT = 'YYYYMMDDHHmmss';
export const API_DATE_FORMAT = 'YYYYMMDD';

export const MODES = {
  VIEW: 'VIEW',
  EDIT: 'EDIT'
}