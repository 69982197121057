import HttpRequest from '../HttpRequest';

const getMyInfo = async () => {
  const response = await HttpRequest.get('/api/auth/me');

  return response;
};

const login = async (url, form) => {
  const response = await HttpRequest.request(url, {}, form, 'POST', 'include');

  return response;
};

const loginWithGoogle = async (url, form) => {
  const response = await HttpRequest.request(url, {}, form, 'POST', 'include');

  return response;
};

const signup = async (form) => {
  const response = await HttpRequest.request('/api/auth/signup', {}, form, 'POST');

  return response;
};

const signupWithGoogle = async (form) => {
  const response = await HttpRequest.request('/api/auth/signupWithGoogle', {}, form, 'POST');

  return response;
};

const logout = async (userId) => {
  const response = await HttpRequest.request('/api/auth/logout', {}, { id: userId }, 'POST', 'include');

  return response;
};

const findPwd = async (form) => {
  const response = await HttpRequest.request('/api/auth/findPwd', {}, form, 'POST');

  return response;
};

const resetPwd = async (form) => {
  const response = await HttpRequest.request('/api/auth/resetPwd', {}, form, 'POST');

  return response;
};

const checkPwd = async (form) => {
  const response = await HttpRequest.request('/api/auth/checkPwd', {}, form, 'POST');

  return response;
};

const verifyCode = async (userId, code) => {
  const response = await HttpRequest.request('/api/auth/verifyCode', {}, { id: userId, verifyCode: code }, 'POST');

  return response;
};

const checkEmail = async (email) => {
  const response = await HttpRequest.request('/api/auth/checkEmail', {}, { email }, 'POST');

  return response;
};

const resendVerifyEmail = async (id, code) => {
  const response = await HttpRequest.request('/api/auth/resendVerificationEmail', {}, { id, verifyCode: code }, 'POST');

  return response;
};

const resendFindPwdEmail = async (id, code) => {
  const response = await HttpRequest.request('/api/auth/resendFindPwdEmail', {}, { id, verifyCode: code }, 'POST');

  return response;
};

const verifySignup = async (code) => {
  const response = await HttpRequest.request('/api/auth/verifySignup', {}, { verifyCode: code }, 'POST');

  return response;
};

const changePassword = async (currentPassword, newPassword) => {
  const response = await HttpRequest.request('/api/auth/changePassword', {}, { currentPassword, newPassword }, 'POST');

  return response;
};

export default {
  getMyInfo,
  login,
  loginWithGoogle,
  logout,
  signup,
  signupWithGoogle,
  findPwd,
  resendFindPwdEmail,
  resetPwd,
  checkPwd,
  verifyCode,
  checkEmail,
  resendVerifyEmail,
  verifySignup,
  changePassword,
};
