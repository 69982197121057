import HttpRequest from 'services/HttpRequest';
import { objectToFormData } from 'utils';

const getUserDetail = async () => {
  const response = await HttpRequest.get('/api/user/detail');
  return response;
};

const photo = async (photo) => {
  const formData = new FormData();
  formData.append('photo', photo, photo.name);

  const response = await HttpRequest.requestMultipart('/api/user/photo', {}, formData, 'POST');

  return response;
};

const editProfile = async (user) => {
  const formData = new FormData();

  objectToFormData(formData, user);

  const response = await HttpRequest.requestMultipart('/api/user/editProfile', {}, formData, 'POST');

  return response;
};

const deleteUser = async (id) => {
  const formData = new FormData();

  objectToFormData(formData, { id });

  const response = await HttpRequest.requestMultipart('/api/user/delete', {}, formData, 'POST');

  return response;
};

export default {
  photo,
  editProfile,
  delete: deleteUser,
  getUserDetail
};
