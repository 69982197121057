import styled from 'styled-components';
import { ListItem, RowBox } from 'styles';

export const FilterItem = styled(ListItem)`
  padding: 11px 24px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid ${({ $selected, theme }) => ($selected ? theme.colors.blue100 : theme.colors.gray50)};
  background-color: ${({ $selected, theme }) => ($selected ? theme.colors.blue100 : 'white')};
  color: ${({ $selected, theme }) => ($selected ? 'white' : theme.colors.gray900)};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const FaqItem = styled(ListItem)`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.gray50};
  transition: max-height 150ms;
  cursor: pointer;
`;

export const QuestionBox = styled(RowBox)`
  padding: 24px 32px;
  border-radius: 8px;
  background-color: white;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 16px;
  }
`;

export const AnswerBox = styled(RowBox)`
  margin: 0 24px 8px;
  padding: 8px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.gray50};

  & > div {
    width: 100%;
    padding: 8px;
    display: flex;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.background};
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin: 0 8px 8px;
    & > div {
      width: 100%;
      padding: 8px;
    }
  }
`;
