import React, { useEffect } from 'react';
import { AccountLayout } from 'layouts';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export function AccountOutlet(props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/myaccount') {
      navigate(`/myaccount/account`);
    }
  }, [location]);

  return (
    <AccountLayout>
      <Outlet />
    </AccountLayout>
  );
}
