import React from 'react';
import { Icon } from 'components';
import { useViewport } from 'hooks';
import { menuList } from 'data/navData';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, FlexList, H2, RowBox, Text } from 'styles';
import * as S from './SubLayoutStyle';
import { MoSubLayout } from './MoSubLayout';

export function SubLayout({ h2, moH2, children }) {
  const { width } = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const currentMenu = menuList.find((menu) => location.pathname.includes(menu.path));

  return (
    <>
      <Container $margin="auto" $type="sub">
        {width <= 1023 ? (
          <MoSubLayout h2={moH2} navigate={navigate} location={location || ''} />
        ) : (
          <RowBox $padding="24px 30px" $alignItems="flex-start">
            <Icon name="Dotted" $transform="rotate(-180deg)" />
            <Box $width="100%">
              <Box $borderBottom={{ color: 'gray500' }}>
                <H2 $type="b38" $color="gray800" $padding="0 4px 26px">
                  {h2}
                </H2>
                {currentMenu && currentMenu.subList && (
                  <FlexList $gap={16} $borderBottom={{ color: 'blue50' }}>
                    {currentMenu.subList.map((sub, index) => (
                      <S.TabListItem
                        key={`subTap_${index}`}
                        onClick={(e) => {
                          if (sub.path !== 'disabled') {
                            navigate(`${sub.path}`);
                          } else {
                            e.stopPropagation();
                          }
                        }}
                        $selected={location.pathname.includes(sub.path)}>
                        <Text $color={location.pathname.includes(sub.path) ? 'primary600' : 'gray100'} $type="b14">
                          {sub.label}
                        </Text>
                      </S.TabListItem>
                    ))}
                  </FlexList>
                )}
              </Box>
              <S.BreadCrumbs>
                <RowBox $alignItems="center" $gap={4} onClick={() => navigate('/')} $cursor="pointer">
                  <Icon name="Home" />
                  <Text $color="gray800" $type="12">
                    Main
                  </Text>
                </RowBox>
                <Icon name="Arrow" />
                <Text $color="gray800" $type="12" $cursor="pointer">
                  {location.pathname.split('/')[1]}
                </Text>
                {location.pathname.split('/')[2] && (
                  <>
                    <Icon name="Arrow" />
                    <Text $color="gray800" $type="12" $cursor="pointer">
                      {location.pathname.split('/')[2].toUpperCase()}
                    </Text>
                  </>
                )}
              </S.BreadCrumbs>
            </Box>
            <Icon name="Dotted" />
          </RowBox>
        )}
      </Container>
      <Box $minHeight={700} $tMinWidth="100%">
        {children}
      </Box>
    </>
  );
}
