import { create } from 'zustand'
import { produce } from 'immer'

import CommonCodeService from 'services/common/CommonCodeService';

const useCommonCodeStore = create((set, get) => ({
  commonCodes: {},

  getCommonCode: async (groupCode) => {
    const commonCode = get().commonCodes[groupCode];

    if (!commonCode) {
      const reponse = await CommonCodeService.listCommonCodes(groupCode);

      set(
        produce((state) => {
          state.commonCodes[groupCode] = reponse;
        })
      )

      return reponse;
    }

    return commonCode;
  }
}))

export default useCommonCodeStore;