import React, { useState } from 'react';
import { Box, InvalidText } from 'styles';
import { Icon } from 'components/icon/Icon';
import * as S from './InputStyle';
import { InputLabel } from '../inputLabel/InputLabel';

export function BaseFormikInput({ path, label, htmlFor, require, onChange, disabled, invalidText, $invalid, $touch, ...props }) {
  const [tempType, setTempType] = useState(props.type || 'text');

  return (
    <Box {...props}>
      {label && <InputLabel path={path} label={label} htmlFor={htmlFor} require={require}></InputLabel>}
      <Box $position="relative">
        <S.InputBox $invalid={$invalid}>
          <S.Input
            id={props.name}
            name={props.name}
            value={props.value}
            onChange={onChange}
            placeholder={props.placeholder}
            $invalid={$touch ? ($invalid ? 'error' : 'success') : null}
            disabled={disabled}
            type={tempType !== 'tel' ? tempType : 'text'}
          />
          {$touch && $invalid && <Icon name="Alert" />}
        </S.InputBox>
        {$touch && $invalid && <InvalidText $color="error">{invalidText ?? 'invalid.text'}</InvalidText>}
      </Box>
    </Box>
  );
}
