import { useNavigate } from 'react-router-dom';
import { useCookie } from './useCookie';

export function useLogout(props) {
  const navigate = useNavigate();
  const { removeCookies } = useCookie();

  const logout = () => {
    navigate('/');
    removeCookies('token');
  };

  return logout;
}
