import styled, { css } from 'styled-components';
import { Box } from 'styles';
import { checkPixel } from 'utils';

export const IconContainer = styled(Box)`
  display: inline-block;
  line-height: 0;
  transition: opacity 150ms;
  ${(props) => props.$opacity && `opacity:${props.$opacity}`};

  .icon {
    ${(props) => props.$svgWidth && `width:${checkPixel(props.$svgWidth)}`};
    ${(props) => props.$svgHeight && `height:${checkPixel(props.$svgHeight)}`};
    ${(props) => props.$fill && `fill:${props.theme.colors[props.$fill] || props.$fill}`};
    ${(props) => props.$stroke && `stroke:${props.theme.colors[props.$stroke] || props.$stroke}`};
    & path {
      ${(props) => props.$pathFill && `fill:${props.theme.colors[props.$pathFill] || props.$pathFill}`};
      ${(props) => props.$pathStroke && `stroke:${props.theme.colors[props.$pathStroke] || props.$pathStroke}`};
    }
  }
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: 0.6;
        }
      }
    `};

  @media ${({ theme }) => theme.device.tablet} {
    .icon {
      ${(props) => props.$tSvgWidth && `width:${checkPixel(props.$tSvgWidth)}`};
      ${(props) => props.$tSvgHeight && `height:${checkPixel(props.$tSvgHeight)}`};
      ${(props) => props.$tFill && `fill:${props.theme.colors[props.$tFill] || props.$tFill}`};
      ${(props) => props.$tStroke && `stroke:${props.theme.colors[props.$tStroke] || props.$tStroke}`};
      & path {
        ${(props) => props.$tPathFill && `fill:${props.theme.colors[props.$tPathFill] || props.$tPathFill}`};
        ${(props) => props.$tPathStroke && `stroke:${props.theme.colors[props.$tPathStroke] || props.$tPathStroke}`};
      }
    }
  }
`;
