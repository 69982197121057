import React, { useState, useRef } from 'react';

import { SubLayout } from 'layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ColorCard, Icon, Modal, Switch } from 'components';
import { Box, CardTag, Container, CustomH3, FlexList, FlexListItem, RowBox, Span, Text } from 'styles';
import { stepList } from 'data';
import { PaymentForm } from './PaymentForm';
import { PayForm } from './PayForm';

import * as S from './PaymentStyle';

export function Payment(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);
  const { productType } = location.state;

  const [currentStep, setCurrentStep] = useState(1);

  return (
    <SubLayout h2="Payment" moH2="PAYMENT">
      <Container $padding="50px 20px 128px" $tPadding="40px 16px 88px" $margin="auto">
        <CustomH3>PAYMENT</CustomH3>
        <Box $padding="16px 0 24px">
          <Text $type="b24" $tType="b20" $color="gray100" $textAlign="center">
            {currentStep === 3 ? `Review Payment` : `Service Payment Information`}
          </Text>
          <Text $marginTop={16} $mMarginTop={8} $type="m14" $color="gray800" $textAlign="center">
            Thank you for choosing our services! We aim to make
            <br />
            the payment process straightforward and secure for your convenience.
          </Text>
        </Box>
        <S.Stepper>
          {stepList.map((step) => (
            <S.StepItem key={`step_${step.id}`} $active={currentStep === step.id}>
              <p>{step.title}</p>
            </S.StepItem>
          ))}
        </S.Stepper>
        {currentStep === 1 && (
          <>
            <ColorCard $bgColor={productType?.color} $maxWidth={600} $margin="auto">
              <Box $maxWidth={300} $tMaxWidth="100%" $margin="auto" $padding="10px 0" $tPadding="16px 24px">
                <Text $tDisplay="none" $type="b" $color="black19" $textAlign="center">
                  {productType?.type}
                </Text>
                <Switch
                  name="type"
                  checked={productType?.periodType === 'PT02'}
                  label1="Billed Monthly"
                  label2="Billed Yearly"
                />
                <Box $tPadding="8px 0 16px" $tBorderBottom={{ color: 'gray500' }}>
                  <Text $display="none" $tDisplay="block" $type="b" $color="black19" $textAlign="center">
                    {productType?.type}
                  </Text>
                  <Text $type="b38" $color="black70" $textAlign="center">
                    ${productType?.discount}&nbsp;
                    <Span $display="none" $type="m14" $color="black30" $decoColor="black30" $tDisplay="inline-block" $textDecoration="line-through">
                      ${productType?.cost}
                    </Span>
                  </Text>
                  <Text $tDisplay="none" $type="b18" $color="black30" $textAlign="center">
                    ${productType?.cost}
                  </Text>
                </Box>
                <FlexList $marginTop={4} $tMarginTop={8} $gap={4} $flexDirection="column">
                  {productType?.list.map((item, index) => (
                    <FlexListItem key={`item_${index}`} $alignItems="center" $justifyContent="space-between" $padding="8px 0" $tPadding="4px 0">
                      <RowBox $gap={4}>
                        <Icon name="CardCheck" />
                        <Text $type="m12" $color="black19">
                          {item.service && (
                            <CardTag $type="sb10" $letter>
                              {item.service}
                            </CardTag>
                          )}
                          {item.text}
                          {item.desc && (
                            <Span $type="m12" $color="orange" $display="block">
                              {item.desc}
                            </Span>
                          )}
                        </Text>
                      </RowBox>
                      {item.info && <Icon name="Info" $padding="2px 0" onClick={() => setModalOpen(true)} />}
                      {/* {item.info && <Tooltip />} */}
                    </FlexListItem>
                  ))}
                </FlexList>
              </Box>
            </ColorCard>
            <Box $maxWidth={600} $margin="32px auto 0" $tMargin="16px auto 0">
              <Button
                $width="100%"
                $type="b14"
                $height={44}
                // onClick={() => setCurrentStep(2)}
                onClick={() => {
                  setCurrentStep(2);
                }}>
                Next : Payment information
              </Button>
              <Button
                mode="line"
                $color="gray900"
                $type="b14"
                $width="100%"
                $height={44}
                $margin="16px 0 0"
                $tMargin="8px 0 0"
                onClick={() => navigate(-1)}>
                Previous : Subscription Plans
              </Button>
            </Box>

            <Modal open={modalOpen} setOpen={setModalOpen} $path="price" notClose type="empty" title="Route Recommendation">
              <Box $margin="0 0 28px" $minWidth={404} $tMinWidth="100%" $tMaxHeight={237} $overflowY="auto">
                <Text>Route Recommendation</Text>
              </Box>
              <Button mode="line" $width="100%" $height={44} $type="b" onClick={() => setModalOpen()}>
                OK
              </Button>
            </Modal>
          </>
        )}

        {currentStep === 2 && (
          <PayForm
            productType={productType}
            onSuccess={(orderNo) => {
              navigate(`/payment/success?orderId=${orderNo}`);
            }}
          />
        )}
      </Container>
    </SubLayout>
  );
}
