import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from "react-use";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ColorCard,
  Dropdown,
  Icon,
  Img,
  Input,
  Modal,
  Radio,
  Switch,
  Confirm,
  Alert,
  FormikInput, FormikDropdown
} from 'components';
import {
  Box,
  CardTag,
  ColBox,
  Container,
  CustomH3,
  FlexList,
  FlexListItem,
  GridList, InvalidText,
  ListItem,
  RowBox,
  RowCenter,
  Span,
  Text,
} from 'styles';
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";
import CommonCodeService from 'services/common/CommonCodeService';
import OrderService from 'services/order/OrderService';
import {correctCvcInput, getByteSize, generateRandomString} from "utils";
import { cardType } from 'data';

import userAuthenticationStore from "stores/AuthenticationStore";
import * as S from './PaymentStyle';

const clientKey = process.env.REACT_APP_TOSS_PAYMENT_CLIENT_KEY;
const customerKey = process.env.REACT_APP_TOSS_PAYMENT_CUSTOMER_KEY;
const variantKey = "DEFAULT";

export function PayForm({ productType, onSuccess }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userInfo = userAuthenticationStore((state) => state.userInfo) || {};

  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);

  const [alert, setAlert] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [countryCodes, setCountryCodes] = useState([]);
  const [stateCodes, setStateCodes] = useState([]);

  const [formValues, setFormValues] = useState({});


  useAsync(async () => {
    const amount = {
      currency: "KRW",
      value: productType.discount,
    };

    console.log('amount:-----------');
    console.log(amount);

    // ------  결제위젯 초기화 ------
    const tossPayments = await loadTossPayments(clientKey);
    // 회원 결제
    // 비회원 결제
    const widgets = tossPayments.widgets({ customerKey });
    await widgets.setAmount(amount);

    await widgets.renderPaymentMethods({
      selector: "#payment-widget",
      variantKey: "DEFAULT",
    });

    // ------  이용약관 UI 렌더링 ------
    await  widgets.renderAgreement({
      selector: "#agreement",
      variantKey: "AGREEMENT",
    });



    paymentWidgetRef.current = widgets;
  }, [productType]);

  const loadCommonCodeState = useAsync(async () => {
    const ctCodes = await CommonCodeService.listCommonCodes('CO');
    ctCodes.sort((a, b) => (a.commonCodeName > b.commonCodeName) ? 1 : -1)

    const scCodes = await CommonCodeService.listCommonCodes('SC');
    ctCodes.unshift({commonCode: '', commonCodeName: 'Select'});
    scCodes.unshift({commonCode: '', commonCodeName: 'Select'});
    setCountryCodes(ctCodes);
    setStateCodes(scCodes);
  }, []);

  const processOrder = (values) => {
    if(productType.productCd === 'F01'){
      const formData = {...values}
      console.info(formData)
      OrderService.orderFreeTrialProduct(formData).then((response)=>{
        if(response === null){
          setAlert({ content: t('common.error.server') });
          setLoading(false);
          setDisabledSubmit(false);
        }else if(response.status === 200){
          onSuccess(response.data);
        }
      });
    }else{
      requestPayment();
    }

  };

  const requestPayment = ()=>{
    try {
      const paymentWidget = paymentWidgetRef.current;
      setLoading(true);
      setDisabledSubmit(true);

      OrderService.generateOrderNo(formValues).then((response )=> {
        if(response.status === 200){
          const orderNo = `${response.data.orderNo}`;
          const orderData = {...formValues, orderNo}
          console.info(orderData)

          localStorage.setItem("orderData", JSON.stringify(orderData));

          const paymentOptions = {
            orderId: orderNo,
            orderName: userInfo?.name,
            customerName: userInfo?.name,
            customerEmail: userInfo?.id,
            successUrl: `${window.location.origin}/payment/success`,
            failUrl: `${window.location.origin}/payment/fail`,
          };

          console.info(paymentOptions);
          paymentWidget?.requestPayment(paymentOptions).catch((res) => {
            setLoading(false);
            setDisabledSubmit(false);
          });
        }


      });


    } catch (error) {
      console.error(error);

    }
  }
  const schema = yup.object().shape({
    address1: yup.string()
      .required(t('validation.required.entry'))
      .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
    address2: yup.string()
      .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
    countryCode: yup.string()
      .required(t('validation.required.entry')),
    city: yup.string()
      .required(t('validation.required.entry'))
      .test('maxByte', t('validation.max.byte', { maxByte: 50 }), (val) => getByteSize(val) <= 50),
    stateCode: yup.string().when("countryCode", {
      is: "US",
      then: () => yup.string().required(t('validation.required.entry')),
      otherwise:() => yup.string().notRequired()
    }),
    state: yup.string().when("countryCode", {
      is: "US",
      then: () => yup.string().notRequired(),
      otherwise:() => yup.string().required(t('validation.required.entry'))
        .test('maxByte', t('validation.max.byte', { maxByte: 50 }), (val) => getByteSize(val) <= 50),
    }),
    zipcode: yup.string()
      .required(t('validation.required.entry'))
      .test('maxByte', t('validation.max.byte', { maxByte: 10 }), (val) => getByteSize(val) <= 10),
  });

  const formik = useFormik({
    initialValues: {
      productCd: productType?.productCd,
      maxRouteCount: productType?.maxRoute,
      address1: '',
      address2: '',
      countryCode: '',
      city: '',
      stateCode: '',
      state: '',
      zipcode: '',
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values)=>{
      setFormValues(values);
      setConfirm(true);
    },
  });

  return (
    <>
      <ColorCard $bgColor={productType.color} $maxWidth={600} $margin="auto">
        <S.ReviewBox $small $tPadding="24px 16px 8px">
          <S.RowTextBox>
            <S.PayText>Subscription</S.PayText>
            <Text $type="b20" $tType="b16">
              {productType?.type} Plan
            </Text>
          </S.RowTextBox>
          {productType?.productCd !== 'F01' &&(
            <S.RowTextBox>
              <S.PayText>Billing cycle</S.PayText>
              <RowBox $gap={16}>
                <Radio disabled={productType?.periodType === 'PT02'}
                       checked={productType?.periodType === 'PT01'}>
                  Monthly
                </Radio>
                <Radio disabled={productType?.periodType === 'PT01'}
                       checked={productType?.periodType === 'PT02'}>
                  Yearly
                </Radio>
              </RowBox>
            </S.RowTextBox>
          )}

          <div>
            <S.RowTextBox>
              <S.PayText>Subtotal</S.PayText>
              <Text $type="b20" $tType="b16" $color="gray800">
                ${productType?.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Text>
            </S.RowTextBox>
            <Text $textAlign="center" $type="m12" $color="black19" $tPaddingTop={8}>
              {productType?.period}
            </Text>
          </div>
        </S.ReviewBox>
        <ColBox
          $gap={24}
          $marginTop={20}
          $mMarginTop={10}
          $maxWidth={400}
          $padding="24px 0 20px"
          $tPadding="24px 16px 20px"
          $borderTop={{ color: 'gray500' }}
          $margin="auto"
        >
          <S.PaySubText>Enter your payment details</S.PaySubText>
          <FormikInput
            path="payment"
            name="address1"
            label="Address line 1"
            htmlFor="address1"
            placeholder="Street address"
            $flex={1}
            $tWidth="100%"
            value={formik.values.address1}
            invalid={formik.errors.address1}
            touched={formik.touched.address1}
            onChange={(_name, value) => {
              formik.setFieldValue('address1', value);
            }}
            onBlur={formik.handleBlur}
            $require
          />
          <FormikInput
            path="payment"
            name="address2"
            label="Address line 2"
            htmlFor="address2"
            placeholder="Apt, unit, suite, etc. (optional)"
            $flex={1}
            $tWidth="100%"
            value={formik.values.address2}
            invalid={formik.errors.address2}
            touched={formik.touched.address2}
            onChange={(_name, value) => {
              formik.setFieldValue('address2', value);
            }}
            onBlur={formik.handleBlur}
          />
          <FormikDropdown
            label="Country"
            name="country"
            placeholder=""
            textField="commonCodeName"
            valueField="commonCode"
            value={formik.values.countryCode || ''}
            invalid={formik.errors.countryCode}
            touched={formik.touched.countryCode}
            onChange={(name, value) => {
              formik.setFieldValue('countryCode', value);
            }}
            $require
            list={countryCodes}
          />
          <FormikInput
            path="payment"
            name="city"
            label="City"
            htmlFor="city"
            placeholder=""
            $flex={1}
            $tWidth="100%"
            value={formik.values.city}
            invalid={formik.errors.city}
            touched={formik.touched.city}
            onChange={(_name, value) => {
              formik.setFieldValue('city', value);
            }}
            onBlur={formik.handleBlur}
            $require
          />

          <RowBox $gap={16}>
            {formik?.values?.countryCode === 'US' && (
              <FormikDropdown
                label="State"
                name="state"
                placeholder=""
                textField="commonCodeName"
                valueField="commonCode"
                $flex={1}
                value={formik.values.stateCode || ''}
                invalid={formik.errors.stateCode}
                touched={formik.touched.stateCode}
                onChange={(name, value) => {
                  formik.setFieldValue('stateCode', value);
                }}
                $require
                list={stateCodes}
              />


            )}
            {formik?.values?.countryCode !== 'US' && (
              <FormikInput
                path="payment"
                name="state"
                label="Province / region"
                htmlFor="state"
                placeholder=""
                $flex={1}
                $tWidth="100%"
                value={formik.values.state}
                invalid={formik.errors.state}
                touched={formik.touched.state}
                onChange={(_name, value) => {
                  formik.setFieldValue('state', value);
                }}
                onBlur={formik.handleBlur}
                $require
              />
            )}

            <FormikInput
              path="payment"
              name="zipcode"
              label="Zip code"
              htmlFor="zipcode"
              placeholder=""
              $flex={1}
              $tWidth="100%"
              value={formik.values.zipcode}
              invalid={formik.errors.zipcode}
              touched={formik.touched.zipcode}
              onChange={(_name, value) => {
                formik.setFieldValue('zipcode', value);
              }}
              onBlur={formik.handleBlur}
              $require
            />

          </RowBox>
        </ColBox>
        <div>
          <div id="payment-widget" style={{ width: "100%" }} />
          <div id="agreement" style={{ width: "100%" }} />
        </div>
      </ColorCard>
      <Box $maxWidth={600} $margin="32px auto 0" $tMargin="16px auto 0">
        <Button $width="100%" $type="b14" $height={44}
                loading={loading}
                disabled={disabledSubmit}
                onClick={formik.handleSubmit}>
          Checkout
        </Button>
        <Button
          mode="line"
          $color="gray900"
          $type="b14"
          $width="100%"
          $height={44}
          $margin="16px 0 0"
          $tMargin="8px 0 0"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Box>

      {alert && (
        <Alert
          open={alert}
          setOpen={setAlert}
        >{alert.content}</Alert>
      )}

      <Confirm
        open={confirm}
        setOpen={setConfirm}
        onClickOk={() => {
          setConfirm(false);
          processOrder(formValues);
        }}
      >
        Are you sure you want to purchase this product?
      </Confirm>

    </>
  );
}
