export const about = [
  {
    title: 'Optimized Fuel Efficiency',
    pagination: `Optimized Fuel\nEfficiency`,
    content:
      'Using advanced algorithms and real-time data, the service optimizes ship routes to reduce fuel consumption, leading to significant cost savings and reduced environmental impact.',
  },
  {
    title: 'Precision in Navigation',
    pagination: `Precision in\nNavigation`,
    content:
      'By leveraging marine meteorological data and big data, an AI-based ship route recommendation service ensures accurate real-time analysis. This minimizes navigation risks, preventing accidents due to sudden weather changes or adverse sea conditions.',
  },
  {
    title: 'Enhanced Safety and Decision-Making',
    pagination: `Enhanced Safety and\nDecision-Making`,
    content:
      'With comprehensive data analysis and real-time monitoring, the service enhances maritime safety by providing timely alerts and recommendations to avoid hazardous conditions.',
  },
];

export const features = [
  {
    title: 'Route Recommendation',
    subTitle: 'AI-based Voyage simulation for search routes',
    content: `Recommended route, standard route and alternative routes Routes comparison : distance sailing, ETA, time en route, engine speed, weather factor, current factor, average speed Route forecast : date, UTC, latitude, longitude, distance, engine speed, heading, beaufort scale, wind speed, wind direction, wave height, wave direction, current, current direction Limit option : wave, wind and beaufort scale`,
    img: 'mainTest1',
  },
  {
    title: 'Weather Display',
    subTitle: 'GFS wave and ECMWF model',
    content: `Wave height, wave direction : GFS wave, ECMWF\nWind barbs : GFS, ECMWF\nSurface pressure : GFS\nSwell height, swell direction : GFS wave\nCurrent : HYCOM\nTropical storm : JTWC, NHC\nIce cover : GFS ensemble wave\n500hPa height : GFS\nVisibility : GDAPs`,
    img: 'mainTest2',
  },
  {
    title: 'Port Forecast',
    subTitle: `GFS wave and ECMWF model\n3,000 ports, 216 hours`,
    content: `Wind speed, wind direction, gust wind : GFS\nWave direction, maximum wave height, significant wave height, significant wave period : GFS wave\nCurrent : HYCOM\nWeather condition : GFS\nVisibility : GDAPs\nPort name\nCountry\nLocation\nTime (UTC and local)`,
    img: 'mainTest3',
  },
];
