import styled, { css } from 'styled-components';
import { Box, ColBox, RowBox } from 'styles';

export const LineCard = styled(Box)`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.background};

  @media ${({ theme }) => theme.device.tablet} {
    padding: 16px 8px;
  }
`;

export const PlanBox = styled(Box)`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  margin-top: 16px;
`;

export const ImgContainer = styled(Box)`
  position: relative;
  height: fit-content;

  @media ${({ theme }) => theme.device.tablet} {
    margin: auto;
  }
`;
export const ImgBox = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;

  box-shadow:
    0px 3px 10px 2px rgba(0, 0, 0, 0.25),
    0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
`;

export const IconBox = styled(RowBox)`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow:
    0px 1px 4px 0px rgba(0, 0, 0, 0.25),
    0px 1px 2px 0px rgba(0, 0, 0, 0.08);
`;

export const TextBox = styled(RowBox)`
  align-items: center;
  gap: 40px;
  padding: 8px 16px;

  & > p:nth-child(1) {
    min-width: 100px;
    color: ${({ theme }) => theme.colors.gray800};
  }

  & > p:nth-child(2) {
    color: ${({ theme }) => theme.colors.black19};
  }

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    align-items: unset;
    gap: 0;
    padding: 8px;
  }
`;

// modify

const tFormBoxCss = css`
  flex-direction: column;
  align-items: unset;
  gap: 0;
  padding: 8px;

  & > p:nth-child(1) {
    margin-bottom: 4px;
  }
`;

const labelCss = css`
  min-width: 100px;
  padding: 13px 0;
  color: ${({ theme }) => theme.colors.gray800};
`;

export const FormBox = styled(RowBox)`
  align-items: ${({ $start }) => ($start && `flex-start`) || 'center'};
  padding: 10px;
  gap: 40px;

  & > p:nth-child(1) {
    ${labelCss}
  }

  @media ${({ theme }) => theme.device.tablet} {
    ${tFormBoxCss}
  }
`;

export const ColFormBox = styled(ColBox)`
  align-items: 'center';
  padding: 10px;

  & > p:nth-child(1) {
    ${labelCss}
  }

  & > div:nth-child(2) {
    margin-left: 140px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    ${tFormBoxCss}

    & > div:nth-child(2) {
      margin-left: 0px;
    }
  }
`;

export const FormLabel = styled.p`
  ${({ $required }) =>
    $required &&
    `
    position: relative;
    display: inline-block;

    &::after {
      content: ' *';
      white-space: pre;
      position: absolute;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
      color: #E12C25;
    }
  `};
`;
