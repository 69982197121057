import React from 'react';
import { useViewport } from 'hooks';
import { Button, ColorCard, Img } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomH2, Text, Box, H2, Span } from 'styles';
import { MoSubLayout } from 'layouts/subLayout/MoSubLayout';

export function Failed(props) {
  const { width } = useViewport();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.state;

  return (
    <Container $padding="50px 20px" $tPadding={path === 'payment' ? '0' : '24px 16px 48px'} $margin="auto">
      {width <= 1023 && path === 'payment' ? (
        <MoSubLayout
          h2={<>Payment</>}
          navigate={navigate}
          path1={path === 'payment' && 'pricing'}
          path2={path === 'payment' && 'payment'}
        />
      ) : (
        <CustomH2>
          {path === 'change' ? (
            <>
              Change <Span $display="none" $tDisplay="block" />
              Password
            </>
          ) : (
            <>
              Create <Span $display="none" $tDisplay="block" />
              Account
            </>
          )}
        </CustomH2>
      )}
      <ColorCard
        $margin="30px auto 20px"
        $tMargin={path === 'payment' ? '64px 16px 112px' : '24px auto'}
        $bgColor="gray500"
        $maxWidth={600}
      >
        <Box $padding="80px 100px" $tPadding="24px 16px">
          <H2 $type="b38" $tType="b24" $color="error" $textAlign="center">
            Failed
          </H2>
          <Text $color="gray800" $margin="4px 0 16px" $tMargin="4px 0 0" $textAlign="center">
            The process could not be completed due to an unknown error. Please try again.
          </Text>
          <Img src="fail" $width="fit-content" $tWidth={180} $margin="auto" $tMargin="48px auto" />
          <Button mode="line" $type="b14" $width="100%" $height={44} $margin="48px 0 0" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
      </ColorCard>
    </Container>
  );
}
