import React, {useState} from 'react';
import { Button } from 'components/button/Button';
import { ColBox, RowBox, Text } from 'styles';
import { useAlertContext } from 'hooks';

import qs from 'qs';
import { BaseFormikInput, Spinner } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AuthenticationService from 'services/authentication/AuthenticationService';

export function FortgotPwdModal({ setModalOpen }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const { alert } = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [couldNotFind, setCouldNotFind] = useState(null);

  const schema = yup.object().shape({
    id: yup.string().email(t('validation.valid.email.address')).required(t('validation.required.email.address')),
    lastName: yup.string().required(t('validation.required.entry')),
    firstName: yup.string().required(t('validation.required.entry')),
  });

  const handleOnSubmit = async (values) => {
    let response = null;

    try {
      setLoading(true);

      response = await AuthenticationService.findPwd({
        id: values.id,
        firstName: values.firstName,
        lastName: values.lastName
      });
    } catch (e) {
      console.error(e);
    }

    setLoading(false);

    if (response && response.status === 200) {
      setModalOpen('sendMail')
    } else if (response && response.message) {
      if (response.message === 'Account does not exist') {
        setCouldNotFind(true);
        setModalOpen('error')
      } else {
        alert({
          message: response.message
        });
      }
    } else {
      alert({
        message: t('common.error.server')
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      lastName: '',
      firstName: '',
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });

  return (
    <>
      <ColBox $gap={24}>
        { couldNotFind === true && <Text $color="gray800">We couldn't find any information matching the information you entered. Please check your email or name again.</Text> }

        <RowBox $gap={16} $tGap={24} $tFlexDirection="column">
          <BaseFormikInput
            $flex={5}
            name="lastName"
            value={formik.values.lastName}
            placeholder=""
            label="Last Name"
            htmlFor="lastName"
            require
            $invalid={formik.errors.lastName}
            $touch={formik.touched.lastName}
            invalidText={formik.errors.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <BaseFormikInput
            $flex={5}
            name="firstName"
            value={formik.values.firstName}
            placeholder=""
            label="First Name"
            htmlFor="firstName"
            require
            $invalid={formik.errors.firstName}
            $touch={formik.touched.firstName}
            invalidText={formik.errors.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </RowBox>
        <BaseFormikInput
          name="id"
          value={formik.values.id}
          placeholder=""
          label="email"
          htmlFor="id"
          require
          $invalid={formik.errors.id}
          $touch={formik.touched.id}
          invalidText={formik.errors.id}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        { couldNotFind === null && <Text $color="gray800">Enter your email address and name that you use with your account to continue.</Text> }
        { loading && <Spinner />}
        { !loading && 
          <Button $width="100%" $height={44} onClick={() => formik.submitForm()}>
            Continue
          </Button>
        }
      </ColBox>
    </>
  );
}