import React from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
}`

const LoadingSpinner = styled.div`
  border: 4px solid ${({ theme }) => theme.colors.line};
  border-radius: 50%;
  border-top-color: white;
  opacity: 1;
  position: relative;
  margin: auto;
  width: 3.625rem;
  height: 3.625rem;
  transition: opacity 200ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
  transition-delay: '200ms'
`

export function Spinner() {
  return (
    <Container>
      <LoadingSpinner />
    </Container>
  );
}