import { Textarea } from './Textarea';

export function FormikTextarea({ invalid, touched, ...props }) {
  return (
    <Textarea
      $invalid={touched && invalid && invalid.length > 0 && 'error'}
      invalidText={touched && invalid && invalid.length > 0 ? invalid : undefined}
      {...props}
    />
  );
}
