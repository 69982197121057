import React, { useState } from 'react';
import { menuList } from 'data';
import { useLogout } from 'hooks';
import { useCookie } from 'hooks/useCookie';
import {
  Confirm,
  DeActivatedModal,
  DelModal,
  Dim,
  FortgotPwdModal,
  Icon,
  LoginModal,
  Modal,
  SendModal,
  SignupModal,
  SignupSendModal,
  InfoModal,
} from 'components';
import { FlexListItem, H3, List, RowBox, Text } from 'styles';

import userAuthenticationStore from 'stores/AuthenticationStore';

import * as S from './HeaderStyle';

export function MoHeader({ delOpen, setDelOpen, background, moOpen, setMoOpen, navigate, location, token }) {
  const isAuthenticated = userAuthenticationStore((state) => state.isAuthenticated);
  const userInfo = userAuthenticationStore((state) => state.userInfo);
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const [info, setInfo] = useState();
  const [modalList, setModalList] = useState();
  const logout = useLogout();
  const { setCookies } = useCookie();
  const [subOpen, setSubOpen] = useState({});
  const [modalOpen, setModalOpen] = useState();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [loginValues, setLoginValues] = useState({ email: '', password: '', save: false });
  const [forgotValues, setForgotValues] = useState({ lastName: '', firstName: '', email: '' });
  const [signupValues, setSignupValues] = useState({
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    confirmPassoword: '',
    plan: '',
    best: '',
    industry: '',
    department: '',
    otherIndustry: '',
    otherDepartment: '',
    company: '',
    vesselsType: '',
    otherVesselType: '',
    agree: '',
  });

  const loginOnClick = () => {
    setModalOpen('login');
  };

  const forgotPwd = () => {
    setModalOpen('forgot');
  };

  const sampleLogin = () => {
    setModalOpen();
    setCookies('token', 'token');
    navigate('/');
  };

  const closeModal = () => {
    setModalOpen();
    setLoginValues({ email: '', password: '', save: false });
    setForgotValues({ lastName: '', firstName: '', email: '' });
    setSignupValues({
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      confirmPassoword: '',
      plan: '',
      best: '',
      industry: '',
      department: '',
      otherIndustry: '',
      otherDepartment: '',
      company: '',
      vesselsType: '',
      otherVesselType: '',
      agree: '',
    });
  };

  const [signinUpRes, setSigninUpRes] = useState({
    id: '',
    verificationCode: ''
  });
  const signupGoogleSuccess = (data) => {
    setSigninUpRes(data);
    setModalOpen('signupSend');
  };

  return (
    <>
      <S.Header $bgColor={background || location.pathname === '/'}>
        <h1>seaStory700</h1>
        <S.Container>
          <Icon
            className="logo"
            name={background || location.pathname === '/' ? 'Logo' : 'LogoGray'}
            $padding="20px 25px"
            onClick={() => navigate('/')}
          />
          <S.MoIconBox>
            <Icon
              id="btn-mo-header-login"
              name="MoProfile"
              $padding="8px"
              $pathFill={(!background || location.pathname !== '/') && 'gray800'}
              $pathStroke={(!background || location.pathname !== '/') && 'gray800'}
              onClick={() => {
                if (isAuthenticated) {
                  navigate('/myaccount')
                } else {
                  loginOnClick();
                }
              }}
            />
            <Icon
              name="MoGlobal"
              $padding="8px"
              $pathStroke={(!background || location.pathname !== '/') && 'gray800'}
              onClick={() => setConfirmOpen(true)}
            />
            <Icon
              name="MoMenu"
              $padding="8px"
              $pathStroke={(!background || location.pathname !== '/') && 'gray800'}
              onClick={() => {
                setMoOpen(true);
                setSubOpen({});
              }}
            />
          </S.MoIconBox>
          <Dim open={moOpen} />
          <S.MoMenuBox $open={moOpen}>
            <RowBox $justifyContent="flex-end" $padding="10px 8px">
              <Icon
                name="Close"
                $padding="8px"
                $svgWidth={24}
                $svgHeight={24}
                onClick={() => {
                  setMoOpen(false);
                  setSubOpen({});
                }}
              />
            </RowBox>
            <nav>
              <h2>menu</h2>
              <List>
                {menuList.map((menu, index) => (
                  <React.Fragment key={`header_menu_${menu.label}_${index}`}>
                    <FlexListItem
                      $justifyContent="space-between"
                      $padding="16px"
                      onClick={(e) => {
                        if (menu.subList) {
                          e.stopPropagation();
                          setSubOpen((prev) => ({
                            ...prev,
                            [index]: !prev[index],
                          }));
                        } else if (menu.path !== 'disabled') {
                          navigate(`/${menu.path}`);
                          setMoOpen(false);
                          setSubOpen({});
                        } else {
                          e.stopPropagation();
                          setMoOpen(false);
                          setSubOpen({});
                        }
                      }}
                    >
                      <H3 $color={location.pathname.includes(menu.path) ? 'secondary' : 'black19'} $type="m18">
                        {menu.label}
                      </H3>
                      {menu.subList && (
                        <Icon name="ArrowMo" $transform={subOpen[index] ? 'rotate(180deg)' : 'rotate(0)'} />
                      )}
                    </FlexListItem>
                    {menu.subList && subOpen[index] && (
                      <List>
                        {menu.subList.map((subMenu, subIndex) => (
                          <FlexListItem
                            key={`sub_menu_${subMenu.label}_${subIndex}`}
                            $justifyContent="flex-start"
                            $padding="16px 24px"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (subMenu.path !== 'disabled') {
                                navigate(`/${menu.path}/${subMenu.path}`);
                              }
                              setMoOpen(false);
                            }}
                          >
                            <H3 $color={location.pathname.includes(subMenu.path) ? 'secondary' : 'gray700'}>
                              {subMenu.label}
                            </H3>
                          </FlexListItem>
                        ))}
                      </List>
                    )}
                  </React.Fragment>
                ))}
              </List>
            </nav>
          </S.MoMenuBox>
        </S.Container>
      </S.Header>
      {/* <DelModal open={delOpen} setOpen={setDelOpen} /> */}
      {/* login, signup */}
      <Modal
        open={modalOpen}
        setOpen={closeModal}
        $color={modalOpen === 'deActivated' && 'error'}
        title={
          modalOpen === 'login'
            ? 'Login'
            : modalOpen === 'error'
              ? 'Not Found.'
              : modalOpen === 'deActivated'
                ? 'Account de-activated'
                : modalOpen === 'signup'
                  ? 'Create Account'
                  : 'Reset your password'
        }
      >
        {modalOpen === 'login' && (
          <LoginModal
            setModalOpen={setModalOpen}
          />
        )}
        {(modalOpen === 'forgot' || modalOpen === 'error') && (
          <FortgotPwdModal setModalOpen={setModalOpen} />
        )}
        {modalOpen === 'sendMail' && <SendModal setModalOpen={setModalOpen} />}
        {modalOpen === 'signupSend' && <SignupSendModal setModalOpen={setModalOpen} id={signinUpRes.id} verifyCode={signinUpRes.verificationCode} />}
        {modalOpen === 'deActivated' && <DeActivatedModal setModalOpen={setModalOpen} />}
        {modalOpen === 'signup' && <SignupModal setModalOpen={setModalOpen} signupGoogleSuccess={signupGoogleSuccess} setInfo={setInfo} setModalList={setModalList} />}


      </Modal>
      <Confirm
        $mode="empty"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="Please select a Language."
        onClick={() => setConfirmOpen(false)}
      >
        <List $padding="16px 0 22px">
          <FlexListItem
            $alignItems="center"
            $justifyContent="space-between"
            $padding="8px 8px 8px 16px"
            $borderBottom={{ color: 'background' }}
          >
            <Text $color="gray900">English</Text>
            <Icon name="LangCheck" />
          </FlexListItem>
          <FlexListItem $padding="8px 8px 8px 16px">
            <Text $color="gray900">Korean</Text>
          </FlexListItem>
        </List>
      </Confirm>
      <InfoModal type="info" open={info} setOpen={setInfo} list={modalList} />
    </>
  );
}
