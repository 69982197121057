import React, { useState, useRef } from 'react';
import { about } from 'data';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { Img } from 'components/img/Img';
import { Box, RowBox, Text } from 'styles';
import * as S from './ProductsStyle';

export function PcSlider(props) {
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePaginationClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
      setActiveIndex(index);
    }
  };
  return (
    <Box $margin="32px auto 0" $maxWidth={834}>
      <S.SliderList>
        {about.map((slide, index) => (
          <S.SliderItem key={index} $active={activeIndex === index} onClick={() => handlePaginationClick(index)}>
            <Text $type="m14" $color={activeIndex === index ? 'gray800' : 'gray100'}>
              {slide.pagination}
            </Text>
          </S.SliderItem>
        ))}
      </S.SliderList>
      <S.CustomSwiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Navigation, Pagination, A11y, Autoplay]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        pagination={{ clickable: false }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {about.map((slide, index) => (
          <SwiperSlide key={index}>
            <RowBox $gap={30} $cursor="pointer">
              <Img src="about" />
              <Box $order={index % 2 !== 0 && -1}>
                <Text $color="gray800" $type="b18">
                  {slide.title}
                </Text>
                <Text $marginTop={24} $color="gray100" $type="m14">
                  {slide.content}
                </Text>
              </Box>
            </RowBox>
          </SwiperSlide>
        ))}
      </S.CustomSwiper>
    </Box>
  );
}
