import { keyframes } from 'styled-components';

export const slideUp = keyframes`
    from {
        top: 50%;
        opacity: 0;
    }
    to {
        top: 44%;
        opacity: 1;
    }
`;

export const slideDown = keyframes`
 from {
        top: 44%;
        opacity: 0;
    }
    to {
        top: 50%;
        opacity: 1;
    }
`;

export const floating = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-30px);
  }
  100% {
    transform: translatey(0px);
  }`;
