import React, {useEffect} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PGService from "services/pg/PGService";


export function PaymentFail(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    const errorCode = searchParams.get("code");
    const errorMessage = searchParams.get("message");

    PGService.postPaymentError({errorCode, errorMessage});
    navigate('/pricing');

  }, []);

  return  (
    <>

    </>
  );
}
