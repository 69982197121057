import styled from 'styled-components';
import { Box } from 'styles';
import { checkPixel } from 'utils';

export const ImgContainer = styled(Box)`
  line-height: 0;
`;

export const Image = styled.img`
  ${({ onClick }) => onClick && `cursor:pointer`};
  object-fit: ${({ $objectFit }) => $objectFit || 'contain'};
  ${({ $transform }) => $transform && `transform:${$transform}`};
  ${({ $objectPosition }) => $objectPosition && `object-position: ${$objectPosition}`};
  ${({ $imgWidth }) => $imgWidth && `width:${checkPixel($imgWidth)}`};

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
`;
