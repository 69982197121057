import { Button, DateDisplay, Icon } from 'components';
import { useAlertContext, useViewport } from 'hooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import orderService from 'services/order/OrderService';
import userAuthenticationStore from 'stores/AuthenticationStore';
import { Box, ColBox, Container, CustomH3, H4, List, ListItem, RowBox, Span, Text } from 'styles';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import * as S from './ManagementStyle';

export function Management(props) {
  const [historyList, setHistoryList] = useState([]);

  // logic
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useViewport();
  const { alert } = useAlertContext();
  const [order, setOrder] = useState(null);
  const [listOpen, setListOpen] = useState(false);

  const userInfo = userAuthenticationStore((state) => state.userInfo) || {};

  const getOrder = async (orderNo) => {
    try {
      const response = await orderService.get(orderNo);
      if (!response) return null;

      return response;
    } catch (error) {
      console.error(error);
      alert({ message: t('common.error.server') });
      return null;
    }
  };

  const getHistory = async (userId) => {
    try {
      const response = await orderService.getHistory(userId);
      if (!response) return null;

      return response;
    } catch (error) {
      console.error(error);
      alert({ message: t('common.error.server') });
      return null;
    }
  };

  const DisplayPeriod = function ({ periodType }) {
    return (
      <>
        {periodType === 'PT01' && ' (per month, billed monthly)'}
        {periodType === 'PT02' && ' (per year, billed yearly)'}
      </>
    );
  };
  useMemo(() => {
    if (userInfo.subscribeOrderNo) {
      getOrder(userInfo.subscribeOrderNo).then((result) => setOrder(result));
      getHistory(userInfo.id).then((result) => setHistoryList(result));
    }
  }, [userInfo.subscribeOrderNo]);

  return (
    <Container $padding="50px 20px 128px" $tPadding="40px 16px 88px" $margin="auto">
      <CustomH3 $tDisplay="none">SUBSCRIPTION MANAGEMENT</CustomH3>
      <Box $padding="24px 0" $tPadding="0">
        <S.PlanBox>
          {order && (
            <>
              <RowBox $gap={10} $alignItems="center">
                <Icon name="PlanCheck" />
                <Box>
                  <H4 $type="sb18" $tType="m18" $color="primary600">
                    Subscription Plan
                  </H4>
                </Box>
              </RowBox>
              <ColBox $gap={15.5} $padding="17px 0" $borderBottom={{ color: 'gray500' }}>
                <Text $color="gray100" $type="14">
                  Currently Subscription&nbsp;:&nbsp;
                  <Span $color="gray800" $type="14">
                    {order.productName}
                  </Span>
                  <Span $display="none" $tDisplay="block" />
                  <DisplayPeriod periodType={order.periodType} />
                </Text>
                <Text $color="gray100" $type="14">
                  Order ID&nbsp;:&nbsp;
                  <Span $display="none" $tDisplay="block" />
                  <Span $type="b14">{order.orderNo}</Span>
                </Text>
                <Text $color="gray100" $type="14">
                  Subscription Date&nbsp;:&nbsp;
                  <DateDisplay date={order.subscribeStartDate} /> ~ <DateDisplay date={order.subscribeEndDate} />
                  &nbsp;(&nbsp;
                  <Span $type="14" $color="#DE0000">
                    {moment(order.subscribeEndDate).diff(moment(), 'days')} days before subscription ends
                  </Span>
                  &nbsp;)
                </Text>
              </ColBox>
            </>
          )}
          <RowBox $paddingTop={17} $justifyContent="flex-end" $gap={15}>
            <Button mode="text" $type="14" $color="primary600" onClick={() => navigate('/pricing')}>
              Update for subscription
            </Button>
          </RowBox>
        </S.PlanBox>
        {width <= 1023 ? (
          <>
            <S.HistoryBox>
              <RowBox $cursor="pointer" $justifyContent="space-between" $alignItems="center" onClick={() => setListOpen(!listOpen)}>
                <RowBox $gap={10} $alignItems="center">
                  <Icon name="PlanCheck" />
                  <H4 $type="sb18" $tType="m18" $color="primary600">
                    Subscription Payment History
                  </H4>
                </RowBox>
                <Icon name="ArrowPage" $height={24} $transform={listOpen ? `rotate(-90deg)` : `rotate(90deg)`} />
              </RowBox>
            </S.HistoryBox>
            {listOpen && (
              <List $borderRadius={10} $marginTop={8} $padding="24px 32px" $backgroundColor="background">
                {historyList &&
                  historyList.map((item, index) => (
                    <ListItem
                      key={`item_${index}`}
                      $paddingTop={index !== 0 && 17}
                      $borderTop={index !== 0 && { color: 'gray500' }}
                      $marginTop={index !== 0 && 17}>
                      <Text $color="gray800" $type="14">
                        Currently Subscription&nbsp;:&nbsp;
                        <Span $type="14" $color="blue100">
                          {item.productName}
                        </Span>
                        <br />
                        &nbsp;
                        <DisplayPeriod periodType={item.periodType} />
                      </Text>
                      <Text $color="gray800" $type="14" $marginTop={15.5}>
                        Order ID&nbsp;:&nbsp;
                        <Span $color="gray800" $type="b14">
                          {item.orderNo}
                        </Span>
                      </Text>
                      <Text $color="gray800" $type="14" $marginTop={15.5}>
                        Subscription Date&nbsp;:&nbsp;
                        <DateDisplay date={item.subscribeStartDate} /> ~ <DateDisplay date={item.subscribeEndDate} />
                        &nbsp;( subscription expired )
                      </Text>
                    </ListItem>
                  ))}
              </List>
            )}
          </>
        ) : (
          <S.HistoryBox>
            <RowBox $gap={10} $alignItems="center">
              <Icon name="PlanCheck" />
              <H4 $type="sb18" $color="primary600">
                Subscription Payment History
              </H4>
            </RowBox>
            <List $paddingTop={17}>
              {historyList &&
                historyList.map((item, index) => (
                  <ListItem
                    key={`item_${index}`}
                    $paddingTop={index !== 0 && 17}
                    $borderTop={index !== 0 && { color: 'gray500' }}
                    $marginTop={index !== 0 && 17}>
                    <Text $color="gray100" $type="14">
                      Currently Subscription&nbsp;:&nbsp;
                      <Span $type="14" $color="gray800">
                        {item.productName}
                      </Span>
                      &nbsp;
                      <DisplayPeriod periodType={item.periodType} />
                    </Text>
                    <Text $color="gray100" $type="14" $marginTop={15.5}>
                      Order ID&nbsp;:&nbsp;
                      <Span $color="gray100" $type="b14">
                        {item.orderNo}
                      </Span>
                    </Text>
                    <Text $color="gray100" $type="14" $marginTop={15.5}>
                      Subscription Date&nbsp;:&nbsp;
                      <DateDisplay date={item.subscribeStartDate} /> ~ <DateDisplay date={item.subscribeEndDate} />
                      &nbsp;( subscription expired )
                    </Text>
                  </ListItem>
                ))}
            </List>
          </S.HistoryBox>
        )}
      </Box>
    </Container>
  );
}
