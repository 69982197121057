import styled from 'styled-components';
import check from 'assets/icons/check.svg';
import { space, typography } from 'styles/css';

export const Label = styled.label`
  ${space}
  ${typography}
  width:fit-content;
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: ${({ $align }) => ($align && $align) || 'center'};
  color: ${({ theme }) => theme.colors.gray800};

  @media ${({ theme }) => theme.device.tablet} {
    align-items: flex-start;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  box-sizing: unset;
  background-color: white;
  cursor: pointer;
  appearance: none;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  border-radius: 4px;
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 150ms ease;

  &:checked {
    border-color: ${({ theme }) => theme.colors.blue100};
    background-color: ${({ theme }) => theme.colors.blue100};
    background-image: url(${check});
  }
`;
