import React from 'react';
import { Icon } from 'components';
import { useViewport } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, FlexList, H2, RowBox, Text } from 'styles';
import * as S from './SubLayoutStyle';
import { MoSubLayout } from './MoSubLayout';

export function AccountLayout({ children }) {
  const { width } = useViewport();
  const navigate = useNavigate();
  const location = useLocation();

  const list = {
    label: 'My account',
    path: 'myaccount',
    subList: [
      { label: `Account`, path: 'account' },
      { label: `Subscription Management`, path: 'management' }
    ]
  };
  return (
    <>
      <Container $margin="auto" $type="sub">
        {width <= 1023 ? (
          <MoSubLayout h2="My account" navigate={navigate} location={location} accountMenu={list} />
        ) : (
          <RowBox $padding="24px 30px" $alignItems="flex-start">
            <Icon name="Dotted" $transform="rotate(-180deg)" />
            <Box $width="100%">
              <Box $borderBottom={{ color: 'gray500' }}>
                <H2 $type="b38" $tType="b24" $color="gray800" $padding="0 4px 26px">
                  My account
                </H2>
                <FlexList $gap={16} $borderBottom={{ color: 'blue50' }}>
                  {list.subList.map((sub, index) => (
                    <S.TabListItem key={`sub_${index}`} onClick={() => navigate(`${sub.path}`)} $selected={location.pathname.split('/')[2] === sub.path}>
                      <Text $color={location.pathname.split('/')[2] === sub.path ? 'primary600' : 'gray100'} $type="b14">
                        {sub.label}
                      </Text>
                    </S.TabListItem>
                  ))}
                </FlexList>
              </Box>
              <S.BreadCrumbs>
                <RowBox $alignItems="center" $gap={4} onClick={() => navigate('/')} $cursor="pointer">
                  <Icon name="Home" />
                  <Text $color="gray800" $type="12">
                    Main
                  </Text>
                </RowBox>
                <Icon name="Arrow" />
                <Text $color="gray800" $type="12" $cursor="pointer">
                  {location.pathname.split('/')[1]}
                </Text>
                {location.pathname.split('/')[2] && (
                  <>
                    <Icon name="Arrow" />
                    <Text $color="gray800" $type="12" $cursor="pointer">
                      {location.pathname.split('/')[2].toUpperCase()}
                    </Text>
                  </>
                )}
              </S.BreadCrumbs>
            </Box>
            <Icon name="Dotted" />
          </RowBox>
        )}
      </Container>
      <Box $minHeight={700}>{children}</Box>
    </>
  );
}
