import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {
  const { pathname } = useLocation();

  // 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // 새로고침
  useEffect(() => {
    const scrollPosition = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', scrollPosition);

    return () => {
      window.removeEventListener('beforeunload', scrollPosition);
    };
  }, []);

  return null;
}
