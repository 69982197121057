import React, { useEffect, useState } from 'react';
import { useViewport } from 'hooks';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCookie } from 'hooks/useCookie';
import styled from 'styled-components';
import Header from './header/Header';
import Footer from './footer/Footer';
import { MoHeader } from './header/MoHeader';

export function Layout(props) {
  const { width } = useViewport();
  const location = useLocation();
  const navigate = useNavigate();
  const [delOpen, setDelOpen] = useState(false);
  const [moOpen, setMoOpen] = useState(false);
  const [background, setBackground] = useState(false);
  const { getCookie } = useCookie();
  const token = getCookie('token');

  useEffect(() => {
    const paths = ['login', 'signup', 'forgotpassword', 'changepassword', 'complete'];
    setBackground(paths.some((path) => location.pathname.includes(path)));
  }, [location]);

  return (
    <>
      {width <= 1023 ? (
        <MoHeader
          delOpen={delOpen}
          setDelOpen={setDelOpen}
          background={background}
          moOpen={moOpen}
          setMoOpen={setMoOpen}
          navigate={navigate}
          location={location}
          token={token}
        />
      ) : (
        <Header
          delOpen={delOpen}
          setDelOpen={setDelOpen}
          background={background}
          navigate={navigate}
          location={location}
          token={token}
        />
      )}
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
}

const Main = styled.main`
  padding-top: 100px;

  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 60px;
  }
`;
