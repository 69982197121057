import React from 'react';
import { Span } from 'styles';
import * as S from './InputLabelStyle';

export function InputLabel({ path, label, htmlFor, require, $invalid, ...props }) {
  return (
    <S.InputLabel $type="m14" $color={$invalid === 'error' ? 'alert' : 'gray900'} htmlFor={htmlFor} {...props}>
      {label}&nbsp;
      {require && (
        <Span $type="m14" $color="alert">
          *
        </Span>
      )}
    </S.InputLabel>
  );
}
