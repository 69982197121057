import React from 'react';
import { Box, RowBox, Text } from 'styles';
import { Button } from 'components/button/Button';
import * as S from './ConfirmStyle';
import { Dim } from '../Dim';

export function Confirm({ open, setOpen, children, title, btnCancelLabel, btnOkLabel, $mode, ...props }) {
  return (
    <Dim open={open}>
      <S.ConfirmContainer>
        <Text $type="b16" $color={props.$color || 'blue100'}>
          {title || ''}
        </Text>
        {$mode === 'empty' ? (
          <>{children}</>
        ) : (
          <Box $padding="16px 0 24px">
            <Text $type="m14" $color="black19">
              {children}
            </Text>
          </Box>
        )}
        <RowBox $gap={8}>
          <Button mode="line" $type="b14" $width="100%" $height={44} onClick={() => setOpen(false)}>
            {btnCancelLabel ?? 'Cancel'}
          </Button>
          <Button
            $width="100%"
            $type="b14"
            $height={44}
            onClick={() => {
              props.onClickOk();
              setOpen(false);
            }}
          >
            {btnOkLabel ?? 'OK'}
          </Button>
        </RowBox>
      </S.ConfirmContainer>
    </Dim>
  );
}
