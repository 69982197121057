import { Products } from 'components';
import { MainContact, Visual } from 'components/main';
import { H2 } from 'styles';

export function Main(props) {
  return (
    <section>
      <H2 $display="none">main</H2>
      <Visual />
      <Products />
      <MainContact />
    </section>
  );
}
