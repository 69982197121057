import { Alert, Confirm, Contact, Icon } from 'components';
import { useEffect, useRef, useState } from 'react';
import { Box, ColBox, Container, CustomH3, FlexListItem, Line, Link, List, RowBox, Span, Text } from 'styles';
import { useAlertContext } from 'hooks';

import userAuthenticationStore from 'stores/AuthenticationStore';
import boardService from 'services/board/BoardService';

export function MainContact() {
  const contactRef = useRef();

  const userInfo = userAuthenticationStore((state) => state.userInfo);
  const [saving, setSaving] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [boardContent, setBoardContent] = useState();
  const [contactValues, setContactValues] = useState({
    email: '',
    name: ''
  });
  const { alert } = useAlertContext();

  const saveContent = async () => {
    if (!saving) {
      setSaving(true);

      const response = await boardService.saveContent(boardContent);

      setSaving(false);

      if (response && response.success) {
        alert({
          message: `Message is sent successfully.`,
          onClose: () => {
            setContactValues({
              email: userInfo.id,
              name: userInfo.name && userInfo.lastName ? `${userInfo.name} ${userInfo.lastName}` : '',
              company: '',
              message: '',
              agree: false
            });
          }
        });
      }
    }
  };

  const handleOnSubmit = (values) => {
    setConfirm(true);
    setBoardContent({
      regUserEmail: values.email,
      regUserName: values.name,
      regUserCompany: values.company,
      content: values.message
    });
  };

  useEffect(() => {
    if (userInfo) {
      setContactValues({
        email: userInfo.id,
        name: userInfo.name && userInfo.lastName ? `${userInfo.name} ${userInfo.lastName}` : ''
      });
    }
  }, [userInfo]);

  return (
    <Box $backgroundColor="background">
      <Container $padding="100px 20px" $tPadding="40px 16px" $margin="auto">
        <RowBox $justifyContent="space-between" $tFlexDirection="column">
          <ColBox $justifyContent="space-between" $maxWidth={438}>
            <Box>
              <CustomH3 $start>Get in Touch</CustomH3>
              <Text $color="gray800" $paddingTop={4}>
                The 'SeaStory700' provides you with the information of routes and weather for your safe and economic voyage.If there are no ports or
                routes, let us know then we will reflect the addition immediately.
                <br />
                Welcome all ideas and opinions about our services.
              </Text>
            </Box>
            <Box $border={{ color: 'gray300' }} $borderRadius={16} $padding="24px" $tPadding="16px" $margin="24px 0">
              <Text $type="b24" $tType="b18" $color="gray800">
                Contact
              </Text>
              <List $marginTop={24} $mMarginTop={8}>
                {/* <FlexListItem $gap={16} $tGap={8}>
                  <Icon name="Location" $tSvgWidth={16} />
                  <Text $type="m" $color="primary">
                    348 Greenpoint Avenue Brooklyn, NY
                  </Text>
                </FlexListItem>
                <FlexListItem $gap={16} $tGap={8} $margin="16px 0">
                  <Icon name="Call" $tSvgWidth={16} />
                  <Box>
                    <Text $type="m" $color="primary">
                      +61 3928 3324
                    </Text>
                    <RowBox>
                      <Span $type="m" $color="gray800">
                        Mon - Fri
                      </Span>
                      <Line />
                      <Text $type="m" $color="gray800">
                        9am - 5pm
                      </Text>
                    </RowBox>
                  </Box>
                </FlexListItem> */}
                <FlexListItem $gap={16} $tGap={8}>
                  <Icon name="Email" $tSvgWidth={16} />
                  <Text $type="m">
                    <Link href="mailto:support@seastory700.com" $color="primary">
                      support@seastory700.com
                    </Link>
                  </Text>
                </FlexListItem>
              </List>
            </Box>
          </ColBox>
          <Box $maxWidth={434}>
            <Contact ref={contactRef} onSubmit={handleOnSubmit} loading={saving} values={contactValues} />
          </Box>
        </RowBox>
      </Container>
      <Confirm
        open={confirm}
        setOpen={setConfirm}
        onClickOk={() => {
          setConfirm(false);
          saveContent();
        }}>
        Would you like to submit a QnA?
      </Confirm>
    </Box>
  );
}
