import { css } from 'styled-components';
import { checkPixel, hexToRGB } from 'utils';

const borderStyle = ({ width = 1, style = 'solid', color = 'gray200', opacity = 1 }, theme) =>
  `${checkPixel(width)} ${style} ${hexToRGB(theme.colors[color] || theme.colors.primary, opacity)}`;

const apply = (props, theme, propertyName = 'border') => css`
  ${propertyName}: ${borderStyle(props, theme)};
`;

export const border = css`
  ${({ $borderRadius }) => $borderRadius && `border-radius:${checkPixel($borderRadius)}`};
  ${({ $border, theme }) => $border && apply($border, theme)};
  ${({ $borderTop, theme }) => $borderTop && apply($borderTop, theme, 'border-top')};
  ${({ $borderRight, theme }) => $borderRight && apply($borderRight, theme, 'border-right')};
  ${({ $borderBottom, theme }) => $borderBottom && apply($borderBottom, theme, 'border-bottom')};
  ${({ $borderLeft, theme }) => $borderLeft && apply($borderLeft, theme, 'border-left')};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $tBorderRadius }) => $tBorderRadius && `border-radius:${checkPixel($tBorderRadius)}`};
    ${({ $tBorder, theme }) => $tBorder && apply($tBorder, theme)};
    ${({ $tBorderTop, theme }) => $tBorderTop && apply($tBorderTop, theme, 'border-top')};
    ${({ $tBorderRight, theme }) => $tBorderRight && apply($tBorderRight, theme, 'border-right')};
    ${({ $tBorderBottom, theme }) => $tBorderBottom && apply($tBorderBottom, theme, 'border-bottom')};
    ${({ $tBorderLeft, theme }) => $tBorderLeft && apply($tBorderLeft, theme, 'border-left')};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({ $mBorderRadius }) => $mBorderRadius && `border-radius:${checkPixel($mBorderRadius)}`};
    ${({ $mBorder, theme }) => $mBorder && apply($mBorder, theme)};
    ${({ $mBorderTop, theme }) => $mBorderTop && apply($mBorderTop, theme, 'border-top')};
    ${({ $mBorderRight, theme }) => $mBorderRight && apply($mBorderRight, theme, 'border-right')};
    ${({ $mBorderBottom, theme }) => $mBorderBottom && apply($mBorderBottom, theme, 'border-bottom')};
    ${({ $mBorderLeft, theme }) => $mBorderLeft && apply($mBorderLeft, theme, 'border-left')};
  }
`;
