import React from 'react';
import { Box, InvalidText } from 'styles';
import * as S from './TextareaStyle';
import { InputLabel } from '../inputLabel/InputLabel';

export function Textarea({
  label,
  htmlFor,
  require,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  type,
  invalidText,
  $invalid,
  $require,
  ...props
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    const maxLength = props.$maxLength || 0;
    const valMaxLength = maxLength ? value.substring(0, maxLength) : value;
    onChange(name, valMaxLength);
  };

  return (
    <Box $position="relative">
      {label && <InputLabel label={label} htmlFor={htmlFor} require={$require}></InputLabel>}
      <S.Textarea
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        maxLength={props.maxLength}
        $invalid={$invalid}
      />
      {$invalid === 'error' && <InvalidText $color="error">{invalidText ?? 'invalid.text'}</InvalidText>}
    </Box>
  );
}
