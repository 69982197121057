import styled from 'styled-components';
import { Box, slideUp } from 'styles';

export const ConfirmContainer = styled(Box)`
  background-color: white;
  border-radius: 8px;
  min-width: 280px;
  max-width: 320px;
  padding: 24px;

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-name: ${slideUp};
  animation-fill-mode: forwards;

  @media ${({ theme }) => theme.device.tablet} {
    width: 80%;
    min-width: unset;
  }
`;
