import React, { useState, useRef, useEffect } from 'react';
import { Icon, Pagination } from 'components';
import { faqList, filterList } from 'data';
import { Box, Container, CustomH3, FlexList, List, Span, Text } from 'styles';

import BoardService from "services/board/BoardService";
import {useAsync} from "react-use";
import {renderHTML} from "utils";
import * as S from './SupportStyle';

export function Faq(props) {
  const refs = useRef([]);
  const [categories, setCategories] = useState([]);
  const [openItems, setOpenItems] = useState([]);
  const [heights, setHeights] = useState({});
  const [pagination, setPagination] = useState({});

  const [filters, setFilters] = useState({
    subjectNo: '',
    pageNumber: 1,
    pageSize: 10
  });

  const list = faqList.filter((faq) => filters.subjectNo === '' || faq.subjectNo === filters.subjectNo);

  const toggleOpen = (id) => {
    setOpenItems((el) => (el.includes(id) ? el.filter((itemId) => itemId !== id) : [...el, id]));
  };

  // useEffect(() => {
  //   const newHeights = {};
  //   if (list) {
  //     list.forEach((faq, index) => {
  //       const height = refs.current[index]?.scrollHeight || 0;
  //       newHeights[faq.id] = height;
  //     });
  //     setHeights(newHeights);
  //   }
  // }, []);

  useAsync(async () => {
    const subjectResponse = await BoardService.getBoardSubjects(2);
    const responses = await BoardService.selectFaqPagination(filters);

    if(subjectResponse.status === 200){
      setCategories(subjectResponse.data);
    }
    console.log('subjectResponse');
    console.log(subjectResponse);
    console.log('responses');
    console.log(responses);
  }, []);

  useAsync(async () => {
    const responses = await BoardService.selectFaqPagination(filters);

    if(responses.status === 200){
      setPagination(responses.data);
    }

  }, [filters]);

  return (
    <Container $padding="74px 20px 128px" $tPadding="40px 8px 64px" $margin="auto">
      <CustomH3 $tDisplay="none">Frequently asked questions</CustomH3>
      <Box $marginTop={48} $tMarginTop="0">
        <FlexList $gap={8} $justifyContent="center" $tFlexWrap="wrap" $tJustifyContent="unset">
          <S.FilterItem
            key="filter_all"
            onClick={() => {
              setFilters({ ...filters, subjectNo: '', pageNumber: 1 });
              setOpenItems([]);
            }}
            $selected={filters.subjectNo === ''}
          >
            All
          </S.FilterItem>

          {categories?.map((category, index) => (
            <S.FilterItem
              key={`filter_${index}`}
              onClick={() => {
                setFilters({ ...filters, subjectNo: category.subjectNo, pageNumber: 1 });
                setOpenItems([]);
              }}
              $selected={filters.subjectNo === category.subjectNo}
            >
              {category.subjectTitle}
            </S.FilterItem>
          ))}
        </FlexList>
        <List $marginTop={32}>
          {pagination?.rows?.map((faq, index) => (
            <S.FaqItem
              key={`faq_${faq.contentNo}`}
              ref={(el) => (refs.current[index] = el)}
              $open={openItems.includes(faq.contentNo)}
              $maxHeight={openItems.includes(faq.contentNo) ? heights[faq.contentNo] : 75}
              $tMaxHeight={openItems.includes(faq.contentNo) ? heights[faq.contentNo] : 60}
              $marginTop={index !== 0 && 4}
            >
              <S.QuestionBox onClick={() => toggleOpen(faq.contentNo)}>
                <Text>
                  <Span $color="gray800" $type="b18" $marginRight={16}>
                    Q.
                  </Span>
                  [{faq.categoryName}] {faq.title}
                </Text>
                <Icon name="ArrowPage" $transform={openItems.includes(faq.id) ? 'rotate(-90deg)' : 'rotate(90deg)'} />
              </S.QuestionBox>
              <S.AnswerBox $open={openItems.includes(faq.id)}>
                <div>
                  <Span $color="gray800" $type="b18" $marginRight={16}>
                    A.
                  </Span>
                  <Text $whiteSpace="pre">{renderHTML(faq.content)}</Text>
                </div>
              </S.AnswerBox>
            </S.FaqItem>
          ))}
        </List>
        <Pagination paginationList={pagination} onChange={(page)=>{
          setFilters({ ...filters, pageNumber: page });
        }}/>
      </Box>
    </Container>
  );
}
