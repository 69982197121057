import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text } from 'styles';
import { Button } from 'components/button/Button';
import {Alert, Confirm} from 'components';
import userAuthenticationStore from 'stores/AuthenticationStore';
import OrderService from "services/order/OrderService";
import moment from 'moment';
import * as S from './VisualStyle';

export function Visual(props) {
  const isAuthenticated = userAuthenticationStore((state) => state.isAuthenticated);
  const userInfo = userAuthenticationStore((state) => state.userInfo);

  const navigate = useNavigate();

  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(null);

  return (
    <>
    <S.Container>
      <S.TextBox>
        <Text $type="b44" $tType="b38" $color="white">
          AI-based Voyage simulation for search routes
        </Text>
        <Text $type="b14" $color="white" $marginTop={8}>
          Recommended Route, Standard Route and Alternative Routes from one of 3,000 ports in the world to another
        </Text>
        <Box $marginTop={40}>
          <Text $color="white" $type="14" $textAlign="center">
            Recommended Routed Anyting
          </Text>
          <Text $type="b20" $color="white" $textAlign="center">
            AROUND THE WORLD
          </Text>
          <Button $width={125} $height={44} $type="m14" $margin="16px auto 0" onClick={() => {
            if (isAuthenticated) {
              console.info(userInfo);
              if(userInfo?.subscribeOrderNo){
                OrderService.get(userInfo?.subscribeOrderNo).then((response)=>{
                  if(response &&response.orderNo){
                    if(moment.parseZone(response.subscribeEndDate).isSameOrAfter(moment(), 'days')){
                      window.open(`${process.env.REACT_APP_ROUTE_RECOMMEND_DOMAIN}`,'_blank');
                    }else{
                      console.log('show alert....');
                      setAlert({content: 'The service period has expired. You can check the details on My Page.'});
                    }
                  }else{
                    setConfirm(true);
                  }
                });

              }else{
                setConfirm(true);
              }

            } else {
              const btnHeaderLogin = document.getElementById('btn-header-login');
              const btnMoHeaderLogin = document.getElementById('btn-mo-header-login');

              if (btnHeaderLogin) {
                btnHeaderLogin.click();
              } else if (btnMoHeaderLogin) {
                btnMoHeaderLogin.click();
              }
            }
          }}>
            Get Started
          </Button>
        </Box>
      </S.TextBox>

      <Confirm
        open={confirm}
        setOpen={setConfirm}
        onClickOk={() => {
          setConfirm(false);
          navigate('/pricing');
        }}
      >
        To use this service, you must have a subscription to the service. Want to go to the Service subscription page?
      </Confirm>


    </S.Container>

      {alert && (
        <Alert
          open={alert}
          setOpen={setAlert}
          onClick={() => {
            setAlert(null);

          }}
        >{alert.content}</Alert>
      )}

    </>
  );
}
