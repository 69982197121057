import { InvalidText } from 'styles';
import * as S from './CheckboxStyle';

export function Checkbox({
  name,
  value,
  onChange,
  onBlur,
  label,
  children,
  checked,
  require,
  invalidText,
  $invalid,
  $type,
  $align,
  ...props
}) {
  const handleOnChange = (event) => {
    const { name, checked } = event.target;
    onChange(name, checked);
  };

  return (
    <S.Label htmlFor={name} $type={$type} require={require} $align={$align} $position="relative" {...props}>
      <S.Checkbox id={name} name={name} checked={checked || false} onChange={handleOnChange} onBlur={onBlur} />
      {children}
      {$invalid === 'error' && <InvalidText $color="error">{invalidText ?? 'invalid.text'}</InvalidText>}
    </S.Label>
  );
}
