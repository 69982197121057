import { Alert, Button, DelModal, FormikInput, Icon, Img } from 'components';
import { useFormik } from 'formik';
import { useLogout } from 'hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HttpRequest from 'services/HttpRequest';
import orderService from 'services/order/OrderService';
import userService from 'services/user/UserService';
import userAuthenticationStore from 'stores/AuthenticationStore';
import { Box, Container, CustomH3, RowBox, Span, Text } from 'styles';
import { getByteSize } from 'utils';
import * as yup from 'yup';
import * as S from './MyAccountStyle';

export function MyAccount(props) {
  const { t } = useTranslation();
  const logout = useLogout();
  const navigate = useNavigate();
  const inputFileRef = useRef();

  const BTN_CHANGE_TEXT_DEFAULT = 'Save Change';
  const BTN_CHANGE_TEXT_COMPLETE = 'Complete!';

  const [alert, setAlert] = useState(false);
  const [photoUrl, setPhotoUrl] = useState('company1');
  const [loading, setLoading] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [btnText, setBtnText] = useState(BTN_CHANGE_TEXT_DEFAULT);
  const [order, setOrder] = useState(null);
  const [detail, setDetail] = useState({});
  const [delOpen, setDelOpen] = useState(false);

  const userInfo = userAuthenticationStore((state) => state.userInfo) || {};
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const getPhotoUrl = (url) => {
    if (!url) return undefined;
    const photoUrl = HttpRequest.getImageUrlLinkByRelativePath(url);
    return photoUrl;
  };

  const uploadPhoto = async (file) => {
    try {
      const response = await userService.photo(file);
      if (!response) return;

      const url = getPhotoUrl(response.userImage);
      setPhotoUrl(url);
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
    }
  };

  const changeName = async (name) => {
    try {
      const response = await userService.editProfile({ name });
      if (!response) return;

      setUserInfo(response);
      setLoading(false);
      setBtnText(BTN_CHANGE_TEXT_COMPLETE);
      setAlert({
        content: 'Name has been successfully changed.'
      });

      setTimeout(() => {
        setBtnText(BTN_CHANGE_TEXT_DEFAULT);
        setDisabledSubmit(false);
      }, 5000);
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
    }
  };

  const getOrder = async (orderNo) => {
    try {
      const response = await orderService.get(orderNo);
      if (!response) return null;

      return response;
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
      return null;
    }
  };

  const getUserDetail = async () => {
    try {
      const response = await userService.getUserDetail();
      if (!response) return null;

      return response;
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
      return null;
    }
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t('validation.required.name'))
      .test('maxByte', t('validation.max.byte', { maxByte: 256 }), (val) => getByteSize(val) <= 256)
      .matches(/^[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|\s\w\-_]+$/, "Special characters are not allowed. (Except '_', '-')")
  });

  const handleOnSubmit = (values) => {
    setLoading(true);
    setDisabledSubmit(true);
    changeName(values.name);
  };

  const formik = useFormik({
    initialValues: {
      name: userInfo.name || ''
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit
  });

  useMemo(() => {
    if (userInfo.subscribeOrderNo) {
      getOrder(userInfo.subscribeOrderNo).then((result) => setOrder(result));
    }
  }, [userInfo.subscribeOrderNo]);

  useEffect(() => {
    if (!userInfo) return;
    formik.setFieldValue('name', userInfo.name || '');
    const photoUrl = getPhotoUrl(userInfo.userImage);
    setPhotoUrl(photoUrl || 'company1');
  }, [userInfo]);

  useEffect(() => {
    getUserDetail().then((result) => setDetail(result));
  }, []);

  return (
    <>
      <Container $padding="50px 20px 128px" $tPadding="40px 16px 64px" $margin="auto">
        <CustomH3 $tDisplay="none">ACCOUNT</CustomH3>
        <RowBox $marginTop={32} $tMarginTop="0" $gap={80} $tGap={24} $tFlexDirection="column">
          <S.ImgContainer>
            <S.ImgBox>
              <Img src={photoUrl} />
              <input
                ref={inputFileRef}
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    uploadPhoto(e.target.files[0]);
                  }
                }}
              />
            </S.ImgBox>
            {/* <S.IconBox onClick={() => inputFileRef.current && inputFileRef.current.click()}>
              <Icon name="Camera" />
            </S.IconBox> */}
          </S.ImgContainer>
          <Box $flex={1}>
            <S.LineCard>
              <S.TextBox>
                <p>Email</p>
                <Text $type="m20">{userInfo.id}</Text>
              </S.TextBox>
              <S.TextBox>
                <p>Name</p>
                <Text>
                  {detail?.lastName} {detail?.name}
                </Text>
              </S.TextBox>
              <S.TextBox>
                <p>Plan to use</p>
                <Text>{detail?.usePlanName}</Text>
              </S.TextBox>
              {detail?.usePlan === 'UP02' && (
                <>
                  <S.TextBox>
                    <p>Industry</p>
                    <Text>{detail?.industryType === 'IT99' ? detail?.industry || '' : detail?.industryTypeName || ''}</Text>
                  </S.TextBox>
                  <S.TextBox>
                    <p>Department</p>
                    <Text>{detail?.departmentType === 'DT99' ? detail?.department || '' : detail?.departmentTypeName || ''}</Text>
                  </S.TextBox>
                  <S.TextBox>
                    <p>Company</p>
                    <Text>{detail?.company}</Text>
                  </S.TextBox>
                </>
              )}
              {detail?.usePlan === 'UP01' && (
                <>
                  <S.TextBox>
                    <p>Which of the Following Describes You Best?</p>
                    <Text>{detail?.userStyleName}</Text>
                  </S.TextBox>
                </>
              )}
              <S.TextBox>
                <p>Vessel Type</p>
                <Text>{detail.vesselType === 'VT99' ? detail?.vessel || '' : detail?.vesselTypeName || ''}</Text>
              </S.TextBox>
            </S.LineCard>
            {order && (
              <Box $padding="30px 10px">
                <Text $color="black19" $type="b20">
                  Upgrade service plan
                </Text>
                <Text $padding="10px 0" $color="gray100">
                  currently using a <Span $color="blue100">{order.productName}</Span> service.
                  <br />
                  Upgrade plan for unlimited route recommendation in search routes.
                  {order.productCd === 'F01' && (
                    <>
                      <br />
                      Upgrade plan for unlimited route recommendation in search routes.
                    </>
                  )}
                </Text>
                <Button $width={108} $height={36} $type="12" onClick={() => navigate(order.productCd === 'F01' ? `/pricing` : `/myaccount/management`)}>
                  {order.productCd === 'F01' ? 'Upgrade' : 'View my plan'}
                </Button>
              </Box>
            )}

            {/* <Box $display="none" $tDisplay="block" $padding="0 8px 16px">
              <Button mode="line" $type="sb12" $width={96} $tWidth="100%" $height={44} onClick={() => logout()}>
                Logout
              </Button>
            </Box> */}
            <Box $margin="30px 10px" $height={1} $backgroundColor="gray500" />
            <RowBox $justifyContent="flex-end" $tFlexDirection="column" $tAlignItems="flex-end" $gap={28} $tGap={24}>
              <Button
                mode="text"
                $type="14"
                $color="blue100"
                $width={100}
                $tWidth={110}
                $height={28}
                onClick={() => {
                  navigate('/myaccount/modify');
                }}>
                Modify account
              </Button>
              <Button mode="text" $type="14" $color="red100" $width={100} $tWidth={110} $height={28} onClick={() => setDelOpen(true)}>
                Delete Account
              </Button>
            </RowBox>
          </Box>
        </RowBox>
      </Container>
      <DelModal open={delOpen} setOpen={setDelOpen} email={userInfo.id} />
      {alert && (
        <Alert
          alert={alert}
          setAlert={setAlert}
          content={alert.content}
          onClick={() => {
            setAlert(null);
          }}
        />
      )}
    </>
  );
}
