import React from 'react';
import { useViewport } from 'hooks';
import { Img } from 'components/img/Img';
import { Box, Container, H4, Text, CustomH3, RowBox, GridBox, ColBox } from 'styles';
import { Icon } from 'components/icon/Icon';
import { useNavigate } from 'react-router-dom';
import * as S from './ProductsStyle';
import { PcSlider } from './PcSlider';
import { MoSlider } from './MoSlider';

export function Products(props) {
  const { width } = useViewport();
  const navigate = useNavigate();

  return (
    <>
      <S.ProductsContainer $tPadding="80px 16px">
        <CustomH3>SeaStory700</CustomH3>
        <Box $maxWidth={700} $margin="48px auto 0" $tMargin="16px 0 0">
          <Text $color="gray800" $type="14">
            An AI-based ship route service, powered by marine meteorological data and big data analytics, ensures
            precise navigation, optimizes fuel efficiency, and enhances safety for cost-effective and secure maritime
            operations.
          </Text>
        </Box>
        {width <= 1023 ? <MoSlider /> : <PcSlider />}
      </S.ProductsContainer>
      <Container $padding="50px 20px" $tPadding="40px 16px 80px" $margin="auto" $tMargin="32px 0 0">
        <GridBox $grid={3} $tGrid={1} $colGap={32} $tRowGap={24}>
          <ColBox $justifyContent="space-between">
            <Box>
              <CustomH3 $start>Our Services</CustomH3>
              <Text $color="gray800" $type="sb" $tType="sb18" $marginTop={16}>
                Innovative and differentiated diverse marine weather information and ship route services
              </Text>
              <Text $marginTop={8} $color="gray100" $type="14" $tType="16">
                Embark on a journey with the "SeaStory" Platform, where innovation meets the seas. Our cutting-edge
                service is tailored to provide unparalleled marine weather information and ship route services, setting
                a new standard in maritime navigation.
              </Text>
            </Box>
            <RowBox $justifyContent="flex-end" $paddingBottom={22}>
              <RowBox $width="fit-content" $alignItems="flex-end" $marginTop={16} $borderBottom={{ color: 'gray800' }}>
                <Text $color="gray800" $type="m14" $padding="0 5px">
                  our another service
                </Text>
                <S.Triangle />
              </RowBox>
            </RowBox>
          </ColBox>
          <S.CardContainer>
            <Img src="seastory300" $imgWidth="100%" $borderRadius={8} $overflow="hidden" />
            <Box $marginTop={8} $padding="16px">
              <H4 $type="b20" $color="primary600">
                SeaStory300
              </H4>
              <Text $color="gray100" $type="14" $padding="4px 0 8px" $minHeight={117}>
                Weather on your routes and for your ships Wave, Wind, Swell, Current, Tropical Storm, Ice Cover, Surface
                Pressure, 500hPa Height, Visibility for 10days
              </Text>
              <RowBox $justifyContent="flex-end">
                <RowBox
                  $cursor="pointer"
                  $width="fit-content"
                  $gap={4}
                  $alignItems="center"
                  onClick={() => navigate('/products')}
                >
                </RowBox>
              </RowBox>
            </Box>
          </S.CardContainer>
          <S.CardContainer>
            <Img src="seastory500" $imgWidth="100%" $borderRadius={8} $overflow="hidden" />
            <Box $marginTop={8} $padding="16px">
              <H4 $type="b20" $color="primary600">
                SeaStory500
              </H4>
              <Text $color="gray100" $type="14" $padding="4px 0 8px">
                Standard Routes from one of 3,000 ports in the world to anotherAlternative Routes from one of 3,000
                ports in the world to another Custom Routes through customer-creating
              </Text>
              <RowBox $justifyContent="flex-end">
                <RowBox
                  $cursor="pointer"
                  $width="fit-content"
                  $gap={4}
                  $alignItems="center"
                  onClick={() => navigate('/products')}
                >
                </RowBox>
              </RowBox>
            </Box>
          </S.CardContainer>
        </GridBox>
        {/* <CustomH3>KEY FEATURES</CustomH3>
        <Box $maxWidth={1000}>
          <List $marginTop={48} $tMarginTop={24}>
            {features.map((key, index) => (
              <S.MainListItem key={`item_${index}`} $reverse={index % 2 !== 0}>
                <Box $tPadding="16px">
                  <Text $type="b20" $color="primary600">
                    {key.title}
                  </Text>
                  <Text $type="b" $color="gray100" $padding="4px 0 16px">
                    {key.subTitle}
                  </Text>
                  <Text $type="14" $color="gray100">
                    {key.content}
                  </Text>
                </Box>
                <Img className="img" src={key.img} alt="features" />
              </S.MainListItem>
            ))}
          </List>
        </Box> */}
      </Container>
    </>
  );
}
