import styled from 'styled-components';

export const MailTo = styled.a`
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 150ms;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`;
