const colors = {
  primary: '#001E40',
  primary900: '#34495e',
  primary700: '#12415D',
  primary600: '#104772',
  primary500: '#00A8F0',

  secondary: '#226AA0',

  cardBlue: '#39C5DD',
  blue100: '#1B9CF9',
  blue50: '#E3EBEF',

  green: '#21E610',
  orange: '#FF5C00',
  purple: '#7B61FF',

  darkGray: '#999999',
  gray900: '#242424',
  gray800: '#5A6F7E',
  gray700: '#808080',
  gray600: '#696E82',
  gray500: '#D9D9D9',
  gray400: '#4d4d4d',
  gray300: '#CCCCCC',
  gray200: '#F7F9FA',
  gray100: '#A0ADB6',
  gray80: '#EDEFF2',
  gray50: '#E6E6E6',

  placeholder: '#7F7F7F',

  background: '#F4F5F9',
  white: '#FFFFFF',
  black19: '#1F1F1F',

  line: '#E4E7F0',
  cardLine: '#D6D6D6',

  red: '#E64A19',
  red50: '#FCF5F5',
  red100: '#DE0000',
  alert: '#E12C25',

  inputLine: '#EDEBFA',
  error: '#CC3535',
  errorLine: '#FAEBEB',
  success: '#15BE53',
  successLine: '#EAFFF2',

  black28: 'rgba(0,0,0,0.28)',
  black30: 'rgba(0,0,0,0.3)',
  black70: 'rgba(0,0,0,0.7)',
  black80: 'rgba(0,0,0,0.8)',
};

const shadow = {
  default: '0 4px 16px 0 rgba(34,106,160,0.08)',
  dark: '0 4px 10px 0 rgba(13,16,68,0.3)',
  light: '0 2px 5px rgba(38,51,77,0.3)',
  second: '0 4px 16px rgba(34,106,160,0.08)',
  new: '0px 4px 16px 0px rgba(45, 45, 45, 0.08)',
  account: '0px 0px 7px 0px rgba(0, 0, 0, 0.10)',
};

const device = {
  sMobile: 'only screen and (max-width: 380px)',
  mobile: 'only screen and (max-width: 767px)',
  tablet: 'only screen and (max-width: 1023px)',
  desktop: 'only screen and (min-width: 1024px)',
  ratio: `only screen and (max-width: 1280px) and (min-aspect-ratio: 16/9),
  screen and (max-width: 1900px) and (min-aspect-ratio: 2/1)`,
};

export const theme = {
  colors,
  shadow,
  device,
};
