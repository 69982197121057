import styled from 'styled-components';
import { Box } from 'styles';
import { hexToRGB } from 'utils';

export const TootipContainer = styled(Box)`
  position: relative;
`;

export const TooltipBox = styled(Box)`
  position: absolute;
  bottom: calc(100% + 6px);
  right: -8px;
  width: fit-content;
  padding: 4px 8px;
  background-color: ${({ theme }) => hexToRGB(theme.colors.primary600, 0.9)};
  border-radius: 6px;
  z-index: 12;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 2;
  }

  &::before {
    border-width: 4px;
    border-color: ${({ theme }) => `${hexToRGB(theme.colors.primary600, 0.9)} transparent transparent transparent`};
    bottom: -6px;
    right: 12px;
    border-bottom-width: 0;
    border-top-width: 6px;
    z-index: 5;
  }

  p {
    white-space: pre;
    font-size: 12px;
    line-height: 14px;
    color: white;
  }
`;

// &::after {
//   border-width: 8px;
//   border-color: ${({ theme }) => `${hexToRGB(theme.colors.primary600, 0.9)} transparent transparent transparent`};
//   bottom: -8px;
//   left: calc(50% - 8px);
//   border-bottom-width: 0;
//   border-top-width: 8px;
// }
