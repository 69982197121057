import { Button } from 'components/button/Button';
import { FormikCheckbox, FormikInput, FormikTextarea } from 'components/form';
import { InfoModal } from 'components/modal';
import { infoList } from 'data';
import { useFormik } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColBox, RowBox, Span, Text } from 'styles';
import { getByteSize } from 'utils';
import * as yup from 'yup';
import * as S from './ContactStyle';

export const Contact = forwardRef(({ path, values, loading, onSubmit, ...props }, ref) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState('');
  const [modalList, setModalList] = useState();

  const openModal = (item) => {
    setModalOpen(item);
    setModalList(infoList.find((el) => el.title === item));
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('validation.required.email.address'))
      .email(t('validation.valid.email.address'))
      .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
    name: yup
      .string()
      .required(t('validation.required.name'))
      .test('maxByte', t('validation.max.byte', { maxByte: 256 }), (val) => getByteSize(val) <= 256),
    company: yup
      .string()
      .nullable()
      .test('maxByte', t('validation.max.byte', { maxByte: 256 }), (val) => getByteSize(val) <= 256),
    message: yup.string().required(t('validation.required.entry')),
    agree: yup.boolean().oneOf([true], t('validation.required.entry'))
  });

  const handleOnSubmit = (values) => {
    onSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      email: (values && values.email) || '',
      name: (values && values.name) || '',
      company: (values && values.company) || '',
      message: (values && values.message) || '',
      agree: (values && values.agree) || false
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit
  });

  useImperativeHandle(ref, () => ({
    resetForm(values) {
      formik.resetForm(values);
    }
  }));

  useEffect(() => {
    if (values) {
      formik.resetForm();
      if (values.email) formik.setFieldValue("email", values.email);
      if (values.name) formik.setFieldValue("name", values.name);
    }
  }, [values]);

  return (
    <>
      <S.Container {...props}>
        <form onSubmit={formik.handleSubmit}>
          <ColBox $gap={24}>
            <ColBox $gap={24} $flexDirection={path === 'qna' && 'row'} $tFlexDirection={path === 'qna' && 'column'}>
              <FormikInput
                $maxWidth={path === 'qna' && 301}
                $tMaxWidth="100%"
                $width={path === 'qna' && '100%'}
                label="Email address"
                htmlFor="email"
                name="email"
                placeholder="EMAIL"
                value={formik.values.email}
                invalid={formik.errors.email}
                touched={formik.touched.email}
                onChange={(_name, value) => {
                  formik.setFieldValue('email', value);
                }}
                onBlur={formik.handleBlur}
                $require
              />
              <RowBox $gap={24} $width={path === 'qna' && '100%'} $tFlexDirection="column">
                <FormikInput
                  $maxWidth={path === 'qna' && 301}
                  $tMaxWidth="100%"
                  $width={path === 'qna' && '100%'}
                  label="Name"
                  htmlFor="name"
                  name="name"
                  placeholder="NAME"
                  value={formik.values.name}
                  invalid={formik.errors.name}
                  touched={formik.touched.name}
                  onChange={(_name, value) => {
                    formik.setFieldValue('name', value);
                  }}
                  onBlur={formik.handleBlur}
                  $require
                />
                <FormikInput
                  $maxWidth={path === 'qna' && 301}
                  $tMaxWidth="100%"
                  $width={path === 'qna' && '100%'}
                  label="Company"
                  htmlFor="company"
                  name="company"
                  placeholder="COMPANY"
                  value={formik.values.company}
                  invalid={formik.errors.company}
                  touched={formik.touched.company}
                  onChange={(_name, value) => {
                    formik.setFieldValue('company', value);
                  }}
                  onBlur={formik.handleBlur}
                />
              </RowBox>
            </ColBox>
            <FormikTextarea
              label="Message"
              htmlFor="message"
              name="message"
              placeholder="MESSAGE"
              value={formik.values.message}
              invalid={formik.errors.message}
              touched={formik.touched.message}
              onChange={(_name, value) => {
                formik.setFieldValue('message', value);
              }}
              onBlur={formik.handleBlur}
              require
            />
            <FormikCheckbox
              $margin={path !== 'qna' && 'auto'}
              $tMargin={path === 'qna' && 'auto'}
              name="agree"
              checked={formik.values.agree}
              invalid={formik.errors.agree}
              touched={formik.touched.agree}
              onChange={(_name, value) => {
                formik.setFieldValue('agree', value);
              }}>
              <Text $type="m14">
                I agree&nbsp;
                <Span
                  $type="m14"
                  $color="blue100"
                  onClick={(e) => {
                    e.preventDefault();

                    openModal('Terms & Conditions');
                  }}>
                  Terms of use
                </Span>
                &nbsp;and&nbsp;
                <Span
                  $type="m14"
                  $color="blue100"
                  onClick={(e) => {
                    e.preventDefault();
                    openModal('Privacy Policy');
                  }}>
                  Privacy Policy
                </Span>
                <Span $type="m14" $lineHeight="14px !important" $tLineHeight="18px !important" $color="alert">
                  *
                </Span>
              </Text>
            </FormikCheckbox>
            <Button
              $width={145}
              $height={44}
              $type="b14"
              $margin="auto"
              $tMargin="16px auto 0"
              type="submit"
              loading={loading}
              onClick={formik.handleSubmit}>
              Send Message
            </Button>
          </ColBox>
        </form>
      </S.Container>
      <InfoModal open={modalOpen} setOpen={setModalOpen} list={modalList} />
    </>
  );
});
