import React, {useEffect, useState} from 'react';
import { RowBox } from 'styles';
import { Icon } from 'components/icon/Icon';
import * as S from './PaginationStyle';

export function Pagination({ paginationList, onChange }) {

  const [pageList, setPageList] = useState([]);

  useEffect(() => {
    const pages = [];
    for (let i = 1; i <= paginationList.totalPages; i++) {
      pages.push(i);
    }
    setPageList(pages)
  }, [paginationList]);

  return (
    <RowBox $gap={24} $alignItems="center" $justifyContent="center" $marginTop={32}>
      <Icon
        name="ArrowPage"
        $transform="rotate(180deg)"
        $pathStroke={paginationList.page === 1 && '#CCCCCC'}
        onClick={() => {
          if(typeof(onChange) === 'function') {
            onChange(paginationList.prevPage);
          }
        }}
        disabled={paginationList.page === 1}
      />
      <RowBox $gap={4}>
        {
          pageList.map((page, index) =>
            (
              <S.NumberBox key={`page_${index}`} onClick={() => {
                if(typeof(onChange) === 'function') {
                  onChange(page);
                }
              }} $selected={paginationList.page === page}>
                <p>{page}</p>
              </S.NumberBox>
            )
          )
        }

      </RowBox>
      <Icon
        name="ArrowPage"
        $pathStroke={paginationList.page === paginationList.lastPage && '#CCCCCC'}
        onClick={() => {
          if(typeof(onChange) === 'function') {
            onChange(paginationList.nextPage);
          }
        }}
        disabled={paginationList.page === paginationList.lastPage}
      />
    </RowBox>
  );
}
