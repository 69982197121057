import React from 'react';
import { List, ListItem, RowBox, Text } from 'styles';
import * as S from './InfoModalstyle';
import { Modal } from '../modal/Modal';

export function InfoModal({ open, setOpen, list }) {
  return (
    <Modal type="footer" open={open} setOpen={setOpen} title={list?.title}>
      <List $mMarginTop={20}>
        {list?.content.map((item, index) => (
          <ListItem key={`item_${index}`}>
            {item.refundTitle && (
              <Text $type="b24" $tType="b20" $color="primary900" $paddingBottom={10}>
                {item.refundTitle}
              </Text>
            )}
            {item.subTitle && (
              <Text $type="b24" $tType="b20" $color="primary900" $padding="30px 0 10px">
                {item.subTitle}
              </Text>
            )}
            {item.lineText &&
              item.lineText.map((text, index) => (
                <RowBox
                  key={`item_${index}`}
                  $gap={4}
                  $paddingLeft={16}
                  $mPaddingLeft="0"
                  $paddingTop={index !== 0 && 6}
                >
                  <Text>-</Text>
                  <Text $type="14" $whiteSpace="pre-wrap">
                    {text}
                  </Text>
                </RowBox>
              ))}
            {item.mailText1 && (
              <Text $type="14" $whiteSpace="pre-wrap">
                {item.mailText1}&nbsp;
                <S.MailTo href={`mailto:${item.mailTo}`}>({item.mailTo})</S.MailTo>
              </Text>
            )}
            {item.mailText2 && (
              <Text $type="14" $whiteSpace="pre-wrap" $padding="10px 0">
                {item.mailText2}&nbsp;
                <S.MailTo href={`mailto:${item.mailTo}`}>({item.mailTo})</S.MailTo>
              </Text>
            )}
            <Text $type="14" $whiteSpace="pre-wrap">
              {item.text}
            </Text>
            {item.list && (
              <List $padding="10px 0 10px 30px">
                {item.list.map((i, index) => (
                  <ListItem key={`i_${index}`} $style="disc">
                    {i}
                  </ListItem>
                ))}
              </List>
            )}
            {item.text2 && (
              <Text $type="14" $whiteSpace="pre-wrap">
                {item.text2}
              </Text>
            )}
          </ListItem>
        ))}
      </List>
    </Modal>
  );
}
