export const menuList = [
  { label: 'Products', path: 'products', pathLabel: `Products & Features` },
  { label: 'Pricing', path: 'pricing', pathLabel: `Plans & Pricing` },
  { label: 'About', path: 'disabled' },
  {
    label: 'Support',
    path: 'support',
    subList: [
      { label: `FAQ`, path: 'disabled' },
      { label: `Q&A`, path: 'qna' },
    ],
  },
];
