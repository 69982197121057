import { API_DATETIME_FORMAT, DATE_FORMAT } from 'data/constants';
import 'moment/locale/ko';
import moment from 'moment/moment';

moment.locale('ko');

function dateToString(date, format) {
  if (!date) return null;
  if (!format) {
    format = DATE_FORMAT;
  }

  return moment(date).format(format);
}

function formatApiDateStr(dateStr, format) {
  if (!dateStr) return null;
  if (!format) {
    format = DATE_FORMAT;
  }
  return moment(dateStr, API_DATETIME_FORMAT).format(format);
}

export { dateToString, formatApiDateStr };
