import React, { useState } from 'react';
import { Box, InvalidText } from 'styles';
import { Icon } from 'components/icon/Icon';
import * as S from './InputStyle';
import { InputLabel } from '../inputLabel/InputLabel';

export function Input({ path, label, htmlFor, $require, onChange, onBlur, disabled, invalidText, $invalid, ...props }) {
  const [tempType, setTempType] = useState(props.type || 'text');

  const handleChange = (e) => {
    const { name, value } = e.target;
    const maxLength = props.maxLength || 0;
    const valueMaxLength = maxLength ? value.substring(0, maxLength) : value;
    const tempValue = props.type === 'tel' ? valueMaxLength.replaceAll(/\D/g, '') : valueMaxLength;

    onChange(name, tempValue);
  };

  return (
    <Box {...props}>
      {label && <InputLabel path={path} label={label} htmlFor={htmlFor} require={$require}></InputLabel>}
      <Box $position="relative">
        <S.InputBox $invalid={$invalid}>
          <S.Input
            id={props.name}
            name={props.name}
            value={props.value}
            maxLength={props.maxLength}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={props.placeholder}
            $invalid={$invalid}
            disabled={disabled}
            type={tempType !== 'tel' ? tempType : 'text'}
          />
          {$invalid === 'error' && <Icon name="Alert" />}
        </S.InputBox>
        {$invalid === 'error' && <InvalidText $color="error">{invalidText ?? 'invalid.text'}</InvalidText>}
      </Box>
    </Box>
  );
}
