import styled from 'styled-components';
import bg from 'assets/images/bg.png';
import { Box, FlexListItem, RowBox } from 'styles';

export const OtherItem = styled(FlexListItem)`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  gap: 32px;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: ${({ $mobile }) => ($mobile ? 'column-reverse' : 'column')};
    padding: 16px;
    gap: 16px;
`;

export const BgBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primary700};
  background-image: url(${bg});
  background-position: calc(100% - 40px) bottom;
  background-repeat: no-repeat;
  padding: 50px 0;
  margin-top: 24px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 40px 0;
    background-image: none;
  }
`;

export const BorderBox = styled(Box)`
  border: 2px solid ${({ theme }) => theme.colors.gray100};
  padding: 16px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 16px 16px 42px;
  }
`;

export const GrayBox = styled(RowBox)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  padding: 24px;
  gap: 32px;
  margin-top: 24px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 16px;
    gap: 16px;
  }
`;
