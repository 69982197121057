import styled from 'styled-components';
import { RowBox } from 'styles';

export const SwitchContainer = styled(RowBox)`
  align-items: center;
  justify-content: center;
  margin: 16px 0;

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ $type }) => ($type === 'pay' ? '0 0 8px' : '8px 0')};
  }
`;

export const SwitchLabel = styled.span`
  white-space: pre;
  cursor: pointer;
  font-size: 14px;
  margin: 0 8px;
  color: ${({ $checked, theme }) => ($checked ? theme.colors.black80 : theme.colors.black28)};

  @media ${({ theme }) => theme.device.tablet} {
    line-height: 1.5;
  }
`;

export const Switch = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const SwitchCircle = styled.span`
  cursor: pointer;
  position: relative;
  width: ${({ $type }) => ($type === 'pay' ? '53px' : '50px')};
  height: ${({ $type }) => ($type === 'pay' ? '20px' : '24px')};
  background-color: ${({ checked, theme }) => (checked ? theme.colors.purple : theme.colors.blue100)};
  border-radius: 12px;
  transition: background-color 0.3s;

  &::before {
    content: '';
    position: absolute;
    width: ${({ $type }) => ($type === 'pay' ? '16px' : '20px')};
    height: ${({ $type }) => ($type === 'pay' ? '16px' : '20px')};
    background-color: white;
    border-radius: 50%;
    top: 2px;
    left: ${({ checked, $type }) =>
      checked && $type === 'pay' ? 'calc(100% - 18px)' : checked && $type !== 'pay' ? 'calc(100% - 22px)' : '2px'};
    transition: left 0.3s;
  }

  @media ${({ theme }) => theme.device.tablet} {
    height: 20px;

    &::before {
      width: 16px;
      height: 16px;
      left: ${({ checked }) => (checked ? '31px' : '2px')};
    }
  }
`;
