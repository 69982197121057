import React from 'react';
import * as S from './LoadingStyle';

export function Loading({ type }) {
  return (
    <S.LoadingContainer type={type}>
      <S.Loader type={type} />
    </S.LoadingContainer>
  );
}

export function DotLoading({$dotColor}) {
  return (
    <S.DotContainer>
      <S.DotLoader $dotColor={$dotColor}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </S.DotLoader>
    </S.DotContainer>
  );
}
