export const payInfoList = [
  {
    icon: 'MoneyChange',
    title: 'Subscription Plans',
    content:
      'Choose from our flexible subscription plans that cater to your needs. Each plan comes with its own set of features and benefits. You can upgrade, downgrade, or cancel your plan at any time.',
  },
  {
    icon: 'MoneyRecive',
    title: 'Automatic Renewal',
    content:
      'To ensure uninterrupted service, all subscription plans are set for automatic renewal. You will be notified in advance of any upcoming renewals, and you can manage your subscription preferences in your account settings.',
  },
  {
    icon: 'DeviceMsg',
    title: 'Customer Support',
    content:
      'If you have any questions or encounter issues with payments, our customer support team is here to assist you. Contact us at support@seastory700.com',
  },
];
