import React, { useState } from 'react';
import { Button } from 'components/button/Button';
import { Checkbox, Dropdown, Input } from 'components/form';
import { Icon } from 'components/icon/Icon';
import { Box, ColBox, RowBox, RowCenter, Span, Text } from 'styles';
import { LineText } from 'components/LineText';
import { Img } from 'components/img/Img';
import { infoList } from 'data';
import { InfoModal } from '../infoModal/InfoModal';

export function LoginModal({ values, setValues, sampleLogin, forgotPwd, setModalOpen }) {
  const onChange = (name, val) => {
    setValues({ ...values, [name]: val });
  };

  return (
    <ColBox $gap={24}>
      <Input label="email" name="email" value={values.email} onChange={onChange} $require />
      <Input label="Password" name="password" value={values.password} onChange={onChange} $require />
      <Checkbox name="save" $type="14" $tType="12" checked={values.save} onChange={onChange}>
        remember me
      </Checkbox>
      <Button $width="100%" $height={44} onClick={sampleLogin}>
        Login
      </Button>
      <LineText>or continue with</LineText>
      <RowCenter
        $cursor="pointer"
        onClick={() => alert('google login')}
        $height={44}
        $borderRadius={5}
        $border={{ width: '2px', color: 'black19' }}
      >
        <Icon name="Google" />
      </RowCenter>
      <Button mode="text" $height={21} $color="blue100" $type="14" onClick={forgotPwd}>
        Forgot password
      </Button>
      <RowBox $justifyContent="center">
        <Text $type="14" $color="gray800">
          No account?&nbsp;
        </Text>
        <Button mode="text" $height={21} $color="blue100" $type="14" onClick={() => setModalOpen('signup')}>
          Create one
        </Button>
      </RowBox>
    </ColBox>
  );
}

export function ForgotModal({ values, setValues, setModalOpen }) {
  const onChange = (name, val) => {
    setValues({ ...values, [name]: val });
  };
  return (
    <ColBox $gap={24}>
      <RowBox $gap={16} $tGap={24} $tFlexDirection="column">
        <Input label="Last Name" name="lastName" value={values.lastName} onChange={onChange} $flex={5} $require />
        <Input label="First Name" name="firstName" value={values.firstName} onChange={onChange} $flex={5} $require />
      </RowBox>
      <Input label="email" name="email" value={values.email} onChange={onChange} $require />
      <Text $color="gray800">Enter your email address and name that you use with your account to continue.</Text>
      <Button $width="100%" $height={44} onClick={() => setModalOpen('sendMail')}>
        Continue
      </Button>
    </ColBox>
  );
}

export function SendModal({ setModalOpen }) {
  return (
    <ColBox $gap={24}>
      <Text $color="gray800">We have sent you a temporary password by email. Please check your email.</Text>
      <Box $height={240} $margin="auto">
        <Img src="send" $margin="auto" $tWidth={180} />
      </Box>
      
      <Button $width="100%" $height={44} onClick={() => setModalOpen('')}>
        OK
      </Button>
    </ColBox>
  );
}

export function DeActivatedModal({ setModalOpen }) {
  return (
    <ColBox $gap={24}>
      <Text $color="gray800">
        The account you logged in to is de-activated. Please activate your account through the verification email sent
        to the email address you registered when creating your account.
      </Text>
      <Img src="error" $margin="auto" $tWidth={180} />
      <Text $color="gray800">
        If the verification email is not in your mailbox, check your spam mailbox. However, if the verification email
        has not been sent, please contact the '
        <Span $cursor="pointer" $color="blue100" onClick={() => alert('help center')}>
          help center
        </Span>
        ' and we will resolve the issue.
      </Text>
      <Button $width="100%" $height={44} onClick={() => setModalOpen()}>
        OK
      </Button>
    </ColBox>
  );
}
