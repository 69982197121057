import styled from 'styled-components';
import { Box, RowBox } from 'styles';

export const PlanBox = styled(Box)`
  box-shadow: ${({ theme }) => theme.shadow.account};
  border-radius: 10px;
  padding: 20px 30px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 24px 16px;
  }
`;

export const HistoryBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  padding: 20px 30px;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 16px;
    padding: 24px 16px;
  }
`;

export const HistoryItemBox = styled(Box)`
  margin-top: 8px;

  @media ${({ theme }) => theme.device.tablet} {
  }
`;
