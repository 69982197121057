import { Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { Spinner } from 'components';

import userAuthenticationStore from 'stores/AuthenticationStore';

import AuthenticationService from 'services/authentication/AuthenticationService';

function ProtectedPageWrapper({ children }) {
  const navigate = useNavigate();

  const isAuthenticated = userAuthenticationStore((state) => state.isAuthenticated);
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const [loadingMeData, setLoadingMeData] = useState(true);

  useEffect(() => {
    async function fetchMeData() {
      let userInfo = null;

      try {
        userInfo = await AuthenticationService.getMyInfo();
      } catch (e) {
        console.error(e);
      }

      if (userInfo && userInfo.id) {
        setAuthenticated(true);
        setUserInfo(userInfo);
      } else {
        setAuthenticated(false);
        setUserInfo(null);
      }

      setLoadingMeData(false);
    }

    if (!isAuthenticated) {
      setLoadingMeData(true);
      fetchMeData();
    }
  }, []);

  if (isAuthenticated) {
    return children;
  }

  if (loadingMeData) {
    return <Spinner />;
  } else if (!isAuthenticated) {
    return navigate("/", { state: { login: true } })
  } else {
    return children;
  }
}

export default ProtectedPageWrapper;
