import React from 'react';
import { RowBox, Text } from 'styles';
import { Icon } from 'components/icon/Icon';
import { DotLoading } from 'components/loading/Loading';
import * as S from './ButtonStyle';

export function Button({
  type,
  mode,
  children,
  onClick,
  $type,
  $width,
  $tWidth,
  $height,
  $tHeight,
  $margin,
  $bgColor,
  $main,
  disabled,
  loading,
  $tMargin,
  $tOrder,
  ...props
}) {
  return (
    <S.Button
      type={type ?? 'button'}
      $mode={mode}
      disabled={disabled}
      onClick={onClick}
      $width={$width}
      $tWidth={$tWidth}
      $height={$height}
      $tHeight={$tHeight}
      $margin={$margin}
      $tMargin={$tMargin}
      $bgColor={$bgColor}
      $tOrder={$tOrder}
    >
      {loading ? (
        <DotLoading />
      ) : (
        <Text $type={`btn${$type}`} {...props}>
          {children}
        </Text>
      )}
    </S.Button>
  );
}

export function IconButton({
  mode,
  $icon,
  children,
  onClick,
  $type,
  $width,
  $height,
  $margin,
  $bgColor,
  $main,
  ...props
}) {
  return (
    <S.Button $mode={mode} onClick={onClick} $width={$width} $height={$height} $margin={$margin} $bgColor={$bgColor}>
      <RowBox $alignItems="center" $gap={10}>
        <Text $type={`btn${$type}`} {...props}>
          {children}
        </Text>
        <Icon name={$icon} />
      </RowBox>
    </S.Button>
  );
}
