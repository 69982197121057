import styled from 'styled-components';
import { RowBox } from 'styles';

export const NumberBox = styled(RowBox)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ $selected, theme }) => ($selected ? theme.colors.blue100 : 'transparent')};

  p {
    line-height: 1;
    color: ${({ $selected, theme }) => ($selected ? 'white' : theme.colors.gray100)};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue100};
      p {
        color: white;
      }
    }
  }
`;
