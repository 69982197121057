import { Button } from 'components/button/Button';
import { Img } from 'components/img/Img';
import { useAlertContext } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColBox, Span, Text } from 'styles';

import AuthenticationService from 'services/authentication/AuthenticationService';

export function SignupSendModal({ setModalOpen, id, verifyCode }) {
  const { alert } = useAlertContext();
  const { t } = useTranslation();

  const resendEmail = async () => {
    let response = null;

    try {
      setLoading(true);

      response = await AuthenticationService.resendVerifyEmail(id, verifyCode);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);

    if (response && response.status === 200) {
      alert({
        message: t('message.email.is.resent')
        // onClick: () => {
        //   navigate('/complete', {
        //     replace: true
        //   });
        // }
      });
    } else if (response && response.message) {
      alert({ message: response.message });
    } else {
      alert({ message: t('common.error.server') });
    }
  };

  const [loading, setLoading] = useState(false);

  return (
    <ColBox $gap={32}>
      <ColBox $gap={16} $maxWidth={398} $margin="auto">
        <Text $color="blue100" $type="b38" $tType="b20" $textAlign="center">
          Account creation complete
        </Text>
        <Text $color="gray800" $textAlign="center">
          Thank you for creating an account. A verification
          <br /> email has been sent to the registered email address.
          <br /> You can use the service by clicking on the link in the
          <br /> verification email sent.
        </Text>
        <Text $type="b18" $color="gray800" $textAlign="center">
          {id}
        </Text>
        <Img src="send" $margin="auto" $tWidth={180} />
      </ColBox>
      <Text $type="m12" $textAlign="center">
        If the verification email is not in your mailbox, please check your spam mailbox. However, if the verification email has not been sent, please
        contact the '
        <Span $type="m12" $cursor="pointer" $color="blue100" onClick={() => alert('help center')}>
          help center
        </Span>
        ' and we will resolve the issue.
      </Text>
      <ColBox $gap={16}>
        {/* <Button
          $width="100%"
          $height={44}
          loading={loading}
          onClick={() => {
            resendEmail();
          }}>
          Resend
        </Button> */}
        <Button
          // mode="line"
          $height={44}
          onClick={() => {
            setModalOpen();
          }}>
          Ok
        </Button>
      </ColBox>
    </ColBox>
  );
}

export default SignupSendModal;
