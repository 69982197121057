/* eslint-disable no-bitwise */
/* eslint-disable prefer-template */
export const hexToRGB = (hex, opacity) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return (
    (result && `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${opacity})`) ||
    hex
  );
};

export const rgbaToHex = (rgba) => {
  if (rgba) {
    let R = rgba.r.toString(16);
    let G = rgba.g.toString(16);
    let B = rgba.b.toString(16);
    let A = '';

    if (rgba.r < 16) {
      R = '0' + R;
    }

    if (rgba.g < 16) {
      G = '0' + G;
    }

    if (rgba.b < 16) {
      B = '0' + B;
    }

    if (rgba.a < 1) {
      const alpha = (rgba.a * 255) | 0;
      A = alpha.toString(16);

      if (alpha < 16) {
        A = '0' + A;
      }
    }
    return '#' + R + G + B + A;
  }
};

export const hexToRGBObj = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return (
    (result && {r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16)}) ||
    hex
  );
};