import { css } from 'styled-components';
import { checkPixel } from 'utils';

const apply = (property, value) => `${property}:${checkPixel(value)}`;

export const space = css`
  ${({ $padding }) => $padding && apply('padding', $padding)};
  ${({ $paddingTop }) => $paddingTop && apply('padding-top', $paddingTop)};
  ${({ $paddingRight }) => $paddingRight && apply('padding-right', $paddingRight)};
  ${({ $paddingBottom }) => $paddingBottom && apply('padding-bottom', $paddingBottom)};
  ${({ $paddingLeft }) => $paddingLeft && apply('padding-left', $paddingLeft)};

  ${({ $margin }) => $margin && apply('margin', $margin)};
  ${({ $marginTop }) => $marginTop && apply('margin-top', $marginTop)};
  ${({ $marginRight }) => $marginRight && apply('margin-right', $marginRight)};
  ${({ $marginBottom }) => $marginBottom && apply('margin-bottom', $marginBottom)};
  ${({ $marginLeft }) => $marginLeft && apply('margin-left', $marginLeft)};

  ${({ $position }) => $position && apply('position', $position)};
  ${({ $top }) => $top && apply('top', $top)};
  ${({ $right }) => $right && apply('right', $right)};
  ${({ $bottom }) => $bottom && apply('bottom', $bottom)};
  ${({ $left }) => $left && apply('left', $left)};
  ${({ $zIndex }) => $zIndex && `z-index:${$zIndex}`};

  ${({ $width }) => $width && apply('width', $width)};
  ${({ $maxWidth }) => $maxWidth && apply('max-width', $maxWidth)};
  ${({ $minWidth }) => $minWidth && apply('min-width', $minWidth)};

  ${({ $height }) => $height && apply('height', $height)};
  ${({ $maxHeight }) => $maxHeight && apply('max-height', $maxHeight)};
  ${({ $minHeight }) => $minHeight && apply('min-height', $minHeight)};

  ${({ $float }) => $float && `float:${$float}`};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $tPadding }) => $tPadding && apply('padding', $tPadding)};
    ${({ $tPaddingTop }) => $tPaddingTop && apply('padding-top', $tPaddingTop)};
    ${({ $tPaddingRight }) => $tPaddingRight && apply('padding-right', $tPaddingRight)};
    ${({ $tPaddingBottom }) => $tPaddingBottom && apply('padding-bottom', $tPaddingBottom)};
    ${({ $tPaddingLeft }) => $tPaddingLeft && apply('padding-left', $tPaddingLeft)};

    ${({ $tMargin }) => $tMargin && apply('margin', $tMargin)};
    ${({ $tMarginTop }) => $tMarginTop && apply('margin-top', $tMarginTop)};
    ${({ $tMarginRight }) => $tMarginRight && apply('margin-right', $tMarginRight)};
    ${({ $tMarginBottom }) => $tMarginBottom && apply('margin-bottom', $tMarginBottom)};
    ${({ $tMarginLeft }) => $tMarginLeft && apply('margin-left', $tMarginLeft)};

    ${({ $tPosition }) => $tPosition && apply('position', $tPosition)};
    ${({ $tTop }) => $tTop && apply('top', $tTop)};
    ${({ $tRight }) => $tRight && apply('right', $tRight)};
    ${({ $tBottom }) => $tBottom && apply('bottom', $tBottom)};
    ${({ $tLeft }) => $tLeft && apply('left', $tLeft)};
    ${({ $tZIndex }) => $tZIndex && `z-index:${$tZIndex}`};

    ${({ $tWidth }) => $tWidth && apply('width', $tWidth)};
    ${({ $tMaxWidth }) => $tMaxWidth && apply('max-width', $tMaxWidth)};
    ${({ $tMinWidth }) => $tMinWidth && apply('min-width', $tMinWidth)};

    ${({ $tHeight }) => $tHeight && apply('height', $tHeight)};
    ${({ $tMaxHeight }) => $tMaxHeight && apply('max-height', $tMaxHeight)};
    ${({ $tMinHeight }) => $tMinHeight && apply('min-height', $tMinHeight)};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({ $mPadding }) => $mPadding && apply('padding', $mPadding)};
    ${({ $mPaddingTop }) => $mPaddingTop && apply('padding-top', $mPaddingTop)};
    ${({ $mPaddingRight }) => $mPaddingRight && apply('padding-right', $mPaddingRight)};
    ${({ $mPaddingBottom }) => $mPaddingBottom && apply('padding-bottom', $mPaddingBottom)};
    ${({ $mPaddingLeft }) => $mPaddingLeft && apply('padding-left', $mPaddingLeft)};

    ${({ $mMargin }) => $mMargin && apply('margin', $mMargin)};
    ${({ $mMarginTop }) => $mMarginTop && apply('margin-top', $mMarginTop)};
    ${({ $mMarginRight }) => $mMarginRight && apply('margin-right', $mMarginRight)};
    ${({ $mMarginBottom }) => $mMarginBottom && apply('margin-bottom', $mMarginBottom)};
    ${({ $mMarginLeft }) => $mMarginLeft && apply('margin-left', $mMarginLeft)};

    ${({ $mPosition }) => $mPosition && apply('position', $mPosition)};
    ${({ $mTop }) => $mTop && apply('top', $mTop)};
    ${({ $mRight }) => $mRight && apply('right', $mRight)};
    ${({ $mBottom }) => $mBottom && apply('bottom', $mBottom)};
    ${({ $mLeft }) => $mLeft && apply('left', $mLeft)};
    ${({ $mZIndex }) => $mZIndex && `z-index:${$mZIndex}`};

    ${({ $mWidth }) => $mWidth && apply('width', $mWidth)};
    ${({ $mMaxWidth }) => $mMaxWidth && apply('max-width', $mMaxWidth)};
    ${({ $mMinWidth }) => $mMinWidth && apply('min-width', $mMinWidth)};

    ${({ $mHeight }) => $mHeight && apply('height', $mHeight)};
    ${({ $mMaxHeight }) => $mMaxHeight && apply('max-height', $mMaxHeight)};
    ${({ $mMinHeight }) => $mMinHeight && apply('min-height', $mMinHeight)};
  }
`;
