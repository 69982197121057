import React, { useEffect } from 'react';
import { SubLayout } from 'layouts';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export function SupportOutlet(props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === '/support') {
      navigate(`/support/qna`);
    }
  }, [location]);

  return (
    <SubLayout h2="Support" moH2="Support">
      <Outlet />
    </SubLayout>
  );
}
