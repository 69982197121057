import React, { useEffect, useState } from 'react';
import {generateRandomString, hexToRGB} from 'utils';
import { SubLayout } from 'layouts';
import { useViewport } from 'hooks';
import { priceColors, priceList } from 'data';
import { useNavigate } from 'react-router-dom';
import { Button, ColorCard, Icon, Modal, Switch, Alert } from 'components';
import {
  Box,
  CardTag,
  Container,
  CustomH3,
  FlexList,
  FlexListItem,
  GridList,
  ListItem,
  RowBox,
  Span,
  Text,
} from 'styles';
import {useAsync} from "react-use";
import OrderService from 'services/order/OrderService';
import cloneDeep from 'lodash/cloneDeep';
import userAuthenticationStore from "../../stores/AuthenticationStore";

export function Pricing(props) {
  const navigate = useNavigate();
  const { width } = useViewport();

  const userInfo = userAuthenticationStore((state) => state.userInfo) || {};

  const [modalOpen, setModalOpen] = useState(false);
  const [periodType, setPeriodType] = useState('PT01');
  const [discountPriceYn, setDiscountPriceYn] = useState('N');
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [monthProducts, setMonthProducts] = useState([]);
  const [yearProducts, setYearProducts] = useState([]);
  const [subscribeProductCd, setSubscribeProductCd] = useState(null);
  const [subscribeProduct, setSubscribeProduct] = useState(null);

  const [alert, setAlert] = useState(false);

  const onChangePeriodType = () => {
    setPeriodType((_type) => (_type === 'PT01' ? 'PT02' : 'PT01'));
  };

  const getProductLevel = (productCd)=>{
    if(productCd === 'F01'){
      return 0;
    }else if(productCd === 'B01' || productCd === 'B02'){
      return 1;
    }else if(productCd === 'S01' || productCd === 'S02'){
      return 2;
    }else if(productCd === 'P01' || productCd === 'P02'){
      return 3;
    }else if(productCd === 'E01' || productCd === 'E02'){
      return 4;
    }
    return -1;
  }

  const goPayment = (selectedProduct) => {
    if(subscribeProductCd != null){
      if(getProductLevel(subscribeProductCd) > getProductLevel(selectedProduct.productCd)){
        setModalOpen(true);
      }else{
        navigate('/pricing/payment', { state: { productType: selectedProduct } });
      }
    }else{
      navigate('/pricing/payment', { state: { productType: selectedProduct } });
    }

  };

  const buildProductPriceData = (obj, list)=>{
    const rec = cloneDeep(obj);

    const product = list.find((o) => o.productCd === rec.productCd);
    if(product){
      if(product.productCd !== 'F01'){
        rec.cost = product.price;
        rec.discount = product.salePrice;
        rec.salePrice = product.salePrice;
      }else{
        rec.salePrice = 0;
      }

    }
    return rec;
  }

  useAsync(async () => {
    const responses = await OrderService.getAllProducts();

    if(responses.status === 200){
      const list = responses.data.products;
      setProducts(list);
      if(responses.data.subscribeProductCd){
        const product = responses.data.products.find((p)=>p.productCd === responses.data.subscribeProductCd);
        setSubscribeProduct(product);
      }

      setDiscountPriceYn(responses.data.discountPriceYn);
      setSubscribeProductCd(responses.data.subscribeProductCd);
      const monthPriceData =
        priceList.find((plan) => plan.type === "PT01").content.map((obj)=>buildProductPriceData(obj, list));

      const yearPriceData =
        priceList.find((plan) => plan.type === "PT02").content.map((obj)=>buildProductPriceData(obj, list));

      setMonthProducts(monthPriceData);
      setYearProducts(yearPriceData);
    }


  }, [userInfo.id]);

  useAsync(async () => {
    console.log(periodType);
    if(periodType === 'PT01'){
      setFilterProducts(monthProducts);
    }else{
      setFilterProducts(yearProducts);
    }

  }, [periodType, monthProducts, yearProducts]);

  return (
    <>
      <SubLayout h2="Plans & Pricing" moH2="Plans & Pricing">
        <Container $padding="50px 20px 128px" $tPadding="40px 17px 88px" $margin="auto">
          <CustomH3 $tDisplay="none">PRICING TABLE</CustomH3>
          <Switch
            checked={periodType === 'PT02'}
            onChange={onChangePeriodType}
            label1="Billed Monthly"
            label2={
              <>
                Billed Yearly&nbsp;
                <Span $tType="14" $color={periodType === 'PT02' && hexToRGB('orange', 0.8)}>
                  (15~30% off)
                </Span>
              </>
            }
          />
          <GridList $grid={5} $gap={10} $tGrid={1} $tGap={30} $tPadding="0 16px" $tMarginTop={24}>
            {filterProducts && filterProducts?.map((con, index) => (
                <ListItem key={`con_${index}`}>
                  <ColorCard $bgColor={priceColors[index]} $height="100%">
                    <Box $padding="10px" $tPadding="16px">
                      <Text $type="14" $mType="m18" $textAlign="center" $tTextAlign="start">
                        {con.type}
                      </Text>
                      <Text $paddingTop={4} $type="b38" $textAlign="center" $tTextAlign="start" $color="black70">
                        ${con.salePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        {width <= 1023 && discountPriceYn === 'Y' && (
                          <Span
                            $type="m14"
                            $paddingLeft={4}
                            $color="black30"
                            $textDecoration={con.productCd !== 'F01' && 'line-through'}
                          >
                            {con.productCd !== 'F01' && `$`}
                            {con.productCd === 'F01' ? 'FREE' : con.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </Span>
                        )}
                      </Text>
                      {width > 1023 && discountPriceYn === 'Y' && (
                        <Text
                          $padding="4px 0"
                          $type="m18"
                          $textAlign="center"
                          $tTextAlign="start"
                          $color="black30"
                          $textDecoration={con.productCd !== 'F01' && 'line-through'}
                        >
                          {con.productCd !== 'F01' && `$`}
                          {con.productCd === 'F01' ? 'FREE' : con.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Text>
                      )}
                      <Text
                        $tMarginTop={4}
                        $type="14"
                        $tType="16"
                        $textAlign="center"
                        $tTextAlign="start"
                        $color="black19"
                      >
                        {con.period}
                      </Text>
                      <Button
                        onClick={() => goPayment(con)}
                        $margin="16px 0 0 0"
                        $tMargin="8px 0 0 0"
                        $width="100%"
                        $height={36}
                        $tHeight={44}
                        $bgColor={priceColors[index]}
                      >
                        Choose {con.type}
                      </Button>
                      <FlexList $marginTop={4} $gap={4} $flexDirection="column">
                        {con.list.map((item, index) => (
                          <FlexListItem
                            key={`item_${index}`}
                            $alignItems={index === 0 ? 'flex-start' : 'center'}
                            $justifyContent="space-between"
                            $padding="8px 0"
                            $tPadding="4px 0"
                          >
                            <RowBox $gap={4}>
                              <Icon name="CardCheck" />
                              <Text $type="m12" $color="black19">
                                {item.service && <CardTag $type="sb10">{item.service}</CardTag>}
                                {item.text}
                                <br />
                                {item.desc && (
                                  <Span $type="m12" $color="orange" $display="inline-block">
                                    {item.desc}
                                  </Span>
                                )}
                              </Text>
                            </RowBox>
                            {item.info && <Icon name="Info" $padding="2px 0" onClick={() => setModalOpen(true)} />}
                            {/* {item.info && <Tooltip />} */}
                          </FlexListItem>
                        ))}
                      </FlexList>
                    </Box>
                  </ColorCard>
                </ListItem>
              ))}
          </GridList>
        </Container>
      </SubLayout>

      <Modal open={modalOpen} setOpen={setModalOpen} $path="price" notClose title="Message">
        <Box $margin="0 0 28px" $minWidth={404} $tMinWidth="100%" $tMaxHeight={237} $overflowY="auto">
          <Text>You are subscribing to <b>{subscribeProduct?.productName}</b> service.</Text>
          <Text>So you cannot subscribe to a lower-level service.</Text>
        </Box>
        <Button mode="line" $width="100%" $height={44} $type="b" onClick={() => setModalOpen(false)}>
          OK
        </Button>
      </Modal>

    </>
  );
}
