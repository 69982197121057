import styled from 'styled-components';
import { Box, ColBox, RowBox } from 'styles';

export const Header = styled.header`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid transparent;
  background-color: ${({ $bgColor, theme }) => ($bgColor ? theme.colors.primary : 'white')};
  border-bottom: 1px solid ${({ $bgColor, theme }) => ($bgColor ? 'transparent' : theme.colors.background)};

  h1,
  h2 {
    display: none;
  }

  @media ${({ theme }) => theme.device.tablet} {
    height: 60px;
  }
`;

export const Container = styled(RowBox)`
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 100%;

    .logo {
      padding: 10px 8px;

      & > svg {
        width: 116px;
        height: 40px;
      }
    }
  }
`;

export const MoIconBox = styled(RowBox)`
  display: none;
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
  }
`;

export const MoMenuBox = styled(ColBox)`
  position: fixed;
  z-index: 130;
  top: 0;
  right: ${({ $open }) => ($open ? '0' : '-85%')};
  width: 85%;
  height: 100vh;
  transition: all 0.3s ease;
  background-color: white;
`;

export const MoDim = styled(Box)``;
