import styled, { css } from 'styled-components';
import { Box, Container, FlexList, FlexListItem, RowBox } from 'styles';
import { Swiper } from 'swiper/react';

export const ProductsContainer = styled(Container)`
  padding: 68px 20px 44px;
  margin: auto;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 40px 16px;
  }
`;

export const AboutH3 = styled.h3`
  line-height: 28px;
  font-size: 38px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary600};
`;

export const CustomSwiper = styled(Swiper)`
  .swiper-pagination {
    display: none;
  }
`;

export const SliderList = styled(FlexList)`
  margin: 0 48px 48px;
  padding-bottom: 32px;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - 33.34%);
    height: 1px;
    bottom: 12px;
    background-color: ${({ theme }) => theme.colors.gray500};
  }
`;

export const SliderItem = styled.li`
  position: relative;
  cursor: pointer;
  width: 33.34%;
  transition: all 150ms;

  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    left: calc(50% - 12px);
    bottom: -32px;
    background-color: white;
    border: 1px solid ${({ theme, $active }) => ($active ? theme.colors.primary600 : theme.colors.gray500)};
    ${({ $active }) => $active && `box-shadow: 0 0 4px 4px rgba(16, 71, 114, 0.2)`};
  }

  &::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    left: calc(50% - 9px);
    bottom: -29px;
    border-radius: 50%;
    background-color: ${({ theme, $active }) => ($active ? theme.colors.primary600 : theme.colors.gray500)};
  }

  p {
    white-space: pre;
    text-align: center;
  }
`;

export const MainListItem = styled(FlexListItem)`
  gap: 64px;
  padding: ${({ $reverse }) => ($reverse ? '48px 48px 0 0' : '48px 0 0 48px')};
  border-radius: 10px;

  ${({ $reverse, theme }) =>
    $reverse &&
    css`
      background-color: ${theme.colors.background};
      margin: 48px 0;

      & > .img {
        order: -1;
      }
    `}

  p {
    white-space: pre-wrap;
  }

  @media ${({ theme }) => theme.device.tablet} {
    gap: 8px;
    background-color: white;
    box-shadow: ${({ theme }) => theme.shadow.default};
    flex-direction: ${({ $reverse }) => ($reverse ? `column` : `column-reverse`)};
    ${({ $reverse }) => $reverse && `margin:24px 0`};
    padding: 8px;

    .img > img {
      width: 100%;
    }
  }
`;

// mo slider

export const MoSlider = styled(Swiper)`
  width: 100%;

  .swiper-wrapper {
    height: 100%;
  }

  .swiper-pagination {
    display: none;
  }
`;

export const MoSlideBox = styled(Box)`
  padding: 32px 16px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 12px;
  min-height: 382px;
`;

export const MoNaviBox = styled(RowBox)`
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const SliderBar = styled(RowBox)`
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  height: 4px;
  border-radius: 2px;

  &::before {
    content: '';
    position: relative;
    top: 0;
    left: ${({ $left }) => `${$left}%`};
    display: inline-block;
    width: 33.33%;
    height: 4px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.gray800};
    transition: left 0.2s ease;
  }
`;

// 2024.06.24 add

export const Triangle = styled.span`
  width: 0;
  height: 0;
  border-right: 23px solid transparent;
  border-bottom: 18px solid #556575;
`;

export const CardContainer = styled(Box)`
  background-color: white;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadow.second};
  padding: 8px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 8px;
  }
`;
