import React, { useState } from 'react';
import { useViewport } from 'hooks';
import { infoList, menuList } from 'data';
import { Button, Icon, InfoModal } from 'components';
import { Box, FlexList, List, ListItem, RowBox, Text } from 'styles';
import { useNavigate } from 'react-router-dom';
import * as S from './FooterStyle';

function Footer(props) {
  const { width } = useViewport();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState('');
  const [modalList, setModalList] = useState();

  const openModal = (item) => {
    setModalOpen(item.title);
    setModalList(item);
  };

  return (
    <>
      <S.Footer>
        <S.Container $padding="50px 20px 96px" $tPadding="40px 8px 0">
          <Icon name="LogoWhite" onClick={() => navigate(`/`)} />
          <FlexList $gap={48} $tGap={8} $tMarginTop={16} $tFlexDirection="column">
            {menuList.map((menu) => (
              <ListItem
                key={`footer_menu_${menu.label}`}
                $height="fit-content"
                $cursor="pointer"
                $tDisplay={menu.subList && `flex`}
                $tFlexDirection={menu.subList && `column`}
                onClick={(e) => {
                  if (menu.path !== 'disabled') {
                    navigate(`/${menu.path}`);
                  } else {
                    e.stopPropagation();
                  }
                }}
              >
                <Box $padding="15.5px 8px" $tPadding="0 8px">
                  <Text $type="14" $color="gray300">
                    {menu.label}
                  </Text>
                </Box>
                {menu.subList && (
                  <List $tDisplay="flex">
                    {menu.subList.map((sub, index) => (
                      <ListItem
                        key={`footer_sub_${index}`}
                        $padding="8px"
                        $tPadding="0 8px"
                        onClick={(e) => {
                          if (sub.path !== 'disabled') {
                            console.info(`/${menu.path}/${sub.path}`);
                            navigate(`/${menu.path}/${sub.path}`);
                          } else {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <Text $type="14" $color="gray700">
                          {sub.label}
                        </Text>
                      </ListItem>
                    ))}
                  </List>
                )}
              </ListItem>
            ))}
          </FlexList>
          <S.MoBox>
            {infoList.map((info, index) => (
              <Button key={`modal_${info}_${index}`} mode="text" $type="12" onClick={() => openModal(info)}>
                {info.title}
              </Button>
            ))}
          </S.MoBox>
        </S.Container>
        <Box>
          {width <= 1023 ? (
            <Box $margin="0 8px" $height={1} $backgroundColor="gray800" />
          ) : (
            <Box $height="0.5px" $backgroundColor="gray400" />
          )}
          <S.Container $padding="12px 20px 17px" $tPadding="16px 8px 40px">
            <Text $color="gray100" $type="12" $lineHeight="16px !important">
              Copyright © Weatheri Inc.
              <br />
              Powered by Namowebiz, Inc.
            </Text>
            <S.PcBox>
              {infoList.map((info, index) => (
                <Button key={`modal_${info}_${index}`} mode="text" $type="12" onClick={() => openModal(info)}>
                  {info.title}
                </Button>
              ))}
            </S.PcBox>
          </S.Container>
        </Box>
      </S.Footer>
      <InfoModal open={modalOpen} setOpen={setModalOpen} list={modalList} />
    </>
  );
}

export default Footer;
