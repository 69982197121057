import styled from 'styled-components';
import main from 'assets/images/main.png';
import { Box, ColBox } from 'styles';

export const Container = styled(Box)`
  height: 520px;
  width: 100%;
  position: relative;
  background-image: url(${main});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 40px 16px;
    background-position: center;
  }
`;

export const TextBox = styled(ColBox)`
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 1200px;
  padding: 86px 60px 66px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0;
    max-width: 100%;
  }
`;
