import { Alert, Button, Confirm, FormikDropdown, FormikInput, Icon, Img } from 'components';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommonCodeService from 'services/common/CommonCodeService';
import HttpRequest from 'services/HttpRequest';
import userService from 'services/user/UserService';
import userAuthenticationStore from 'stores/AuthenticationStore';
import { Box, ColBox, Container, CustomH3, RowBox, RowCenter, Text } from 'styles';
import { generatePasswordPatentValidationYup, getByteSize } from 'utils';
import * as yup from 'yup';
import AuthenticationService from '../../../services/authentication/AuthenticationService';
import * as S from './MyAccountStyle';

const PASSWORD_CHANGE_DELAY = 1000;

export function EditAccount(_props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const BTN_CHANGE_TEXT_DEFAULT = 'Submit';
  const BTN_CHANGE_TEXT_COMPLETE = 'Saved!';

  const [alert, setAlert] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [photoUrl, setPhotoUrl] = useState('company1');
  const [loading, setLoading] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [btnText, setBtnText] = useState(BTN_CHANGE_TEXT_DEFAULT);
  const [isChangePass, setIsChangePass] = useState(false);

  const timer = useRef(null);

  const [detail, setDetail] = useState({});
  const [UPCodes, setUPCode] = useState([]);
  const [UTCodes, setUTCode] = useState([]);
  const [ITCodes, setITCode] = useState([]);
  const [DTCodes, setDTCode] = useState([]);
  const [VTCodes, setVTCode] = useState([]);

  const userInfo = userAuthenticationStore((state) => state.userInfo) || {};
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const getPhotoUrl = (url) => {
    if (!url) return undefined;
    const photoUrl = HttpRequest.getImageUrlLinkByRelativePath(url);
    return photoUrl;
  };

  const uploadPhoto = async (file) => {
    try {
      const response = await userService.photo(file);
      if (!response) return;

      const url = getPhotoUrl(response.userImage);
      setPhotoUrl(url);
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
    }
  };

  const modifyAccount = async (values) => {
    try {
      const response = await userService.editProfile(values);
      if (!response) return;

      setUserInfo(response);
      setLoading(false);
      setBtnText(BTN_CHANGE_TEXT_COMPLETE);
      setAlert({
        content: 'Saved.'
      });

      setTimeout(() => {
        setBtnText(BTN_CHANGE_TEXT_DEFAULT);
        setDisabledSubmit(false);

        setTimeout(() => {
          navigate('/myaccount/account');
        }, 1000);
      }, 3000);
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
    }
  };

  const checkPwd = (password) =>
    new Promise((resolve, _reject) => {
      clearTimeout(timer.current);
      timer.current = setTimeout(async () => {
        try {
          const response = await AuthenticationService.checkPwd({ password });
          if (!response) resolve(false);

          resolve(response.valid);
        } catch (error) {
          console.error(error);
          resolve(false);
        }
      }, PASSWORD_CHANGE_DELAY);
    });

  const getUserDetail = async () => {
    try {
      const response = await userService.getUserDetail();
      if (!response) return null;

      return response;
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
      return null;
    }
  };

  const getCommonCodes = async () => {
    try {
      const upCodes = await CommonCodeService.listCommonCodes('UP');
      const utCodes = await CommonCodeService.listCommonCodes('UT');
      const itCodes = await CommonCodeService.listCommonCodes('IT');
      const dtCodes = await CommonCodeService.listCommonCodes('DT');
      const vtCodes = await CommonCodeService.listCommonCodes('VT');

      return { upCodes, utCodes, itCodes, dtCodes, vtCodes };
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
      return null;
    }
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t('validation.required.name'))
      .test('maxByte', t('validation.max.byte', { maxByte: 256 }), (val) => getByteSize(val) <= 256)
      .matches(/^[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|\s\w\-_]+$/, "Special characters are not allowed. (Except '_', '-')"),
    lastName: yup.string().required(t('validation.required.entry')),
    currentPassword: yup.string().when([], {
      is: () => isChangePass,
      then: (schema) =>
        schema.required('Please enter your current password.').test('checkPwd', 'The current password is incorrect.', async (value) => {
          if (value) {
            const isValid = await checkPwd(value);
            return isValid;
          }
          return false;
        })
    }),
    newPassword: yup.string().when([], {
      is: () => isChangePass,
      then: (schema) => schema.concat(generatePasswordPatentValidationYup(t('validation.valid.password'))).required('Please enter your new password.')
    }),
    confirmPassword: yup.string().when([], {
      is: () => isChangePass,
      then: (schema) => schema.required('Please enter your confirm password.').oneOf([yup.ref('newPassword'), null], 'Passwords do not match.')
    }),
    usePlan: yup.string().required(t('validation.required.entry')),
    userStyle: yup.string().when('usePlan', {
      is: 'UP01',
      then: () => yup.string().required(t('validation.required.entry')),
      otherwise: () => yup.string().notRequired()
    }),
    industryType: yup.string().when('usePlan', {
      is: 'UP02',
      then: () => yup.string().required(t('validation.required.entry')),
      otherwise: () => yup.string().notRequired()
    }),
    industry: yup.string().when('industryType', {
      is: 'IT99',
      then: () =>
        yup
          .string()
          .required(t('validation.required.entry'))
          .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
      otherwise: () => yup.string().notRequired()
    }),
    company: yup.string().when('usePlan', {
      is: 'UP02',
      then: () =>
        yup
          .string()
          .required(t('validation.required.entry'))
          .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
      otherwise: () => yup.string().notRequired()
    }),

    departmentType: yup.string().when('usePlan', {
      is: 'UP02',
      then: () => yup.string().required(t('validation.required.entry')),
      otherwise: () => yup.string().notRequired()
    }),
    department: yup.string().when('departmentType', {
      is: 'DT99',
      then: () =>
        yup
          .string()
          .required(t('validation.required.entry'))
          .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
      otherwise: () => yup.string().notRequired()
    }),
    vesselType: yup.string().when('usePlan', {
      is: 'UP02',
      then: () => yup.string().required(t('validation.required.entry')),
      otherwise: () => yup.string().notRequired()
    }),
    vessel: yup.string().when('vesselType', {
      is: 'VT99',
      then: () =>
        yup
          .string()
          .required(t('validation.required.entry'))
          .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
      otherwise: () => yup.string().notRequired()
    })
  });

  const handleOnSubmit = (values) => {
    setConfirm(true);
    setFormValues(values);
  };

  const formik = useFormik({
    initialValues: {
      name: userInfo.name || '',
      lastName: detail?.lastName || '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      usePlan: detail?.usePlan || '',
      userStyle: detail?.userStyle || '',
      industryType: detail?.industryType || '',
      industry: detail?.industry || '',
      departmentType: detail?.departmentType || '',
      department: detail?.department || '',
      company: detail?.company || '',
      vesselType: detail?.vesselType || '',
      vessel: detail?.vessel || ''
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleOnSubmit
  });

  useEffect(() => {
    if (userInfo.userNo) {
      getUserDetail().then((response) => {
        setDetail(response);
        setPhotoUrl(getPhotoUrl(response.userImage) || 'company1');
      });
    }
  }, [userInfo.userNo]);

  useEffect(() => {
    getCommonCodes(detail.usePlan).then((results) => {
      setUPCode(results.upCodes);
      setUTCode(results.utCodes);
      setITCode(results.itCodes);
      setDTCode(results.dtCodes);
      setVTCode(results.vtCodes);
    });
  }, []);

  return (
    <>
      <Container $padding="50px 20px 128px" $tPadding="40px 16px 64px" $margin="auto">
        <CustomH3 $tDisplay="none">ACCOUNT</CustomH3>
        <RowBox $marginTop={32} $tMarginTop="0" $gap={80} $tGap={24} $tFlexDirection="column">
          <S.ImgContainer>
            <S.ImgBox>
              <Img src={photoUrl} />
              <input
                ref={inputFileRef}
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    uploadPhoto(e.target.files[0]);
                  }
                }}
              />
            </S.ImgBox>
            <S.IconBox onClick={() => inputFileRef.current && inputFileRef.current.click()}>
              <Icon name="Camera" />
            </S.IconBox>
          </S.ImgContainer>
          <Box $flex={1}>
            <S.LineCard>
              <S.FormBox>
                <p>Email</p>
                <Text $type="b20">{userInfo.id}</Text>
              </S.FormBox>
              <S.FormBox $start>
                <p>Name</p>
                <RowBox $gap={16} $width={398} $tWidth="100%">
                  <FormikInput
                    path="account"
                    name="lastName"
                    label="Last Name"
                    htmlFor="lastName"
                    placeholder=""
                    $flex={1}
                    $tWidth="100%"
                    value={formik.values.lastName}
                    invalid={formik.errors.lastName}
                    touched={formik.touched.lastName}
                    onChange={(_name, value) => {
                      formik.setFieldValue('lastName', value);
                    }}
                    onBlur={formik.handleBlur}
                    $require
                  />

                  <FormikInput
                    path="account"
                    name="name"
                    label="First Name"
                    htmlFor="name"
                    placeholder="please enter your name."
                    $flex={1}
                    $tWidth="100%"
                    value={formik.values.name}
                    invalid={formik.errors.name}
                    touched={formik.touched.name}
                    onChange={(_name, value) => {
                      formik.setFieldValue('name', value);
                    }}
                    onBlur={formik.handleBlur}
                    $require
                  />
                </RowBox>
              </S.FormBox>
              <S.FormBox $start>
                <p>Password</p>
                <ColBox $gap={24} $width={398} $tWidth="100%">
                  <Box>
                    <Button
                      $type="12"
                      $padding={16}
                      $height={36}
                      onClick={() => setIsChangePass(!isChangePass)}
                      loading={loading}
                      disabled={disabledSubmit}>
                      {isChangePass ? 'Cancel' : 'Change Password'}
                    </Button>
                  </Box>
                  {isChangePass && (
                    <>
                      <FormikInput
                        path="account"
                        type="password"
                        name="currentPassword"
                        label="Current Password"
                        htmlFor="currentPassword"
                        placeholder=""
                        $flex={1}
                        $tWidth="100%"
                        value={formik.values.currentPassword}
                        invalid={formik.errors.currentPassword}
                        touched={formik.touched.currentPassword}
                        onChange={(_name, value) => {
                          formik.setFieldValue('currentPassword', value);
                        }}
                        onBlur={formik.handleBlur}
                        $require
                      />
                      <Box>
                        <FormikInput
                          path="account"
                          type="password"
                          name="newPassword"
                          label="New Password"
                          htmlFor="newPassword"
                          placeholder=""
                          $flex={1}
                          $tWidth="100%"
                          value={formik.values.newPassword}
                          invalid={formik.errors.newPassword}
                          touched={formik.touched.newPassword}
                          onChange={(_name, value) => {
                            formik.setFieldValue('newPassword', value);
                          }}
                          onBlur={formik.handleBlur}
                          $require
                        />
                        <Text $type="m12" $color="primary600" $textAlign="right">
                          8 or more characters, Upper & lowercase letters, At least on number
                        </Text>
                      </Box>
                      <FormikInput
                        path="account"
                        type="password"
                        name="confirmPassword"
                        label="Confirm Password"
                        htmlFor="confirmPassword"
                        placeholder=""
                        $flex={1}
                        $tWidth="100%"
                        value={formik.values.confirmPassword}
                        invalid={formik.errors.confirmPassword}
                        touched={formik.touched.confirmPassword}
                        onChange={(_name, value) => {
                          formik.setFieldValue('confirmPassword', value);
                        }}
                        onBlur={formik.handleBlur}
                        $require
                      />
                    </>
                  )}
                </ColBox>
              </S.FormBox>

              <S.FormBox>
                <S.FormLabel $required>Plan to use</S.FormLabel>
                <FormikDropdown
                  placeholder="Select"
                  name="usePlan"
                  $width={398}
                  $tWidth="100%"
                  onChange={(_name, value) => {
                    formik.setFieldValue('usePlan', value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.usePlan}
                  invalid={formik.errors.usePlan}
                  touched={formik.touched.usePlan}
                  list={UPCodes}
                  textField="commonCodeName"
                  valueField="commonCode"
                  $require
                />
              </S.FormBox>

              {formik?.values?.usePlan === 'UP01' && (
                <>
                  <S.ColFormBox $width={398} $tWidth="100%">
                    <S.FormLabel $required>Which of the Following Describes You Best?</S.FormLabel>
                    <FormikDropdown
                      placeholder="Select"
                      name="userStyle"
                      $width={398}
                      $tWidth="100%"
                      onChange={(_name, value) => {
                        formik.setFieldValue('userStyle', value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.userStyle}
                      invalid={formik.errors.userStyle}
                      touched={formik.touched.userStyle}
                      list={UTCodes}
                      textField="commonCodeName"
                      valueField="commonCode"
                      $require
                    />
                  </S.ColFormBox>
                </>
              )}

              {formik?.values?.usePlan === 'UP02' && (
                <>
                  <S.FormBox $start>
                    <S.FormLabel $required>Industry</S.FormLabel>
                    <ColBox $gap={24} $width={398} $tWidth="100%">
                      <FormikDropdown
                        placeholder="Select"
                        name="industryType"
                        onChange={(_name, value) => {
                          formik.setFieldValue('industryType', value);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.industryType}
                        invalid={formik.errors.industryType}
                        touched={formik.touched.industryType}
                        list={ITCodes}
                        textField="commonCodeName"
                        valueField="commonCode"
                        $require
                      />
                      {formik?.values?.industryType === 'IT99' && (
                        <Box>
                          <FormikInput
                            name="industry"
                            htmlFor="industry"
                            $tWidth="100%"
                            value={formik.values.industry}
                            invalid={formik.errors.industry}
                            touched={formik.touched.industry}
                            onChange={(_name, value) => {
                              formik.setFieldValue('industry', value);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </Box>
                      )}
                    </ColBox>
                  </S.FormBox>

                  <S.FormBox $start>
                    <S.FormLabel $required>Department</S.FormLabel>
                    <ColBox $gap={24} $width={398} $tWidth="100%">
                      <FormikDropdown
                        placeholder="Select"
                        name="departmentType"
                        onChange={(_name, value) => {
                          formik.setFieldValue('departmentType', value);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.departmentType}
                        invalid={formik.errors.departmentType}
                        touched={formik.touched.departmentType}
                        list={DTCodes}
                        textField="commonCodeName"
                        valueField="commonCode"
                        $require
                      />
                      {formik?.values?.departmentType === 'DT99' && (
                        <Box>
                          <FormikInput
                            name="department"
                            $tWidth="100%"
                            value={formik.values.department}
                            invalid={formik.errors.department}
                            touched={formik.touched.department}
                            onChange={(_name, value) => {
                              formik.setFieldValue('department', value);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </Box>
                      )}
                    </ColBox>
                  </S.FormBox>

                  <S.FormBox>
                    <S.FormLabel $required>Company</S.FormLabel>
                    <FormikInput
                      $width={398}
                      name="company"
                      htmlFor="company"
                      $tWidth="100%"
                      value={formik.values.company}
                      invalid={formik.errors.company}
                      touched={formik.touched.company}
                      onChange={(_name, value) => {
                        formik.setFieldValue('company', value);
                      }}
                      onBlur={formik.handleBlur}
                      $require
                    />
                  </S.FormBox>
                </>
              )}

              <S.FormBox $start>
                <S.FormLabel $required>Vessel Type</S.FormLabel>
                <ColBox $gap={24} $width={398} $tWidth="100%">
                  <FormikDropdown
                    placeholder="Select"
                    name="vesselType"
                    value={formik.values.vesselType}
                    invalid={formik.errors.vesselType}
                    touched={formik.touched.vesselType}
                    list={VTCodes}
                    textField="commonCodeName"
                    valueField="commonCode"
                    onChange={(_name, value) => {
                      formik.setFieldValue('vesselType', value);
                    }}
                    onBlur={formik.handleBlur}
                    $require
                  />

                  {formik?.values?.vesselType === 'VT99' && (
                    <Box>
                      <FormikInput
                        name="vessel"
                        $tWidth="100%"
                        value={formik.values.vessel}
                        invalid={formik.errors.vessel}
                        touched={formik.touched.vessel}
                        onChange={(_name, value) => {
                          formik.setFieldValue('vessel', value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Box>
                  )}
                </ColBox>
              </S.FormBox>
            </S.LineCard>
            <Box $margin="30px 10px" $width="100%" $height={1} $backgroundColor="gray500" />
            <RowCenter $gap={10}>
              <Button $type="12" $width={87} $height={36} onClick={formik.handleSubmit} loading={loading} disabled={disabledSubmit}>
                {btnText}
              </Button>
              <Button
                mode="line"
                $type="12"
                $width={87}
                $height={36}
                onClick={() => {
                  navigate('/myaccount/account');
                }}>
                Cancel
              </Button>
            </RowCenter>
          </Box>
        </RowBox>
      </Container>

      {alert && (
        <Alert
          alert={alert}
          setAlert={setAlert}
          content={alert.content}
          onClick={() => {
            setAlert(null);
            navigate('/myaccount/account');
          }}
        />
      )}

      <Confirm
        open={confirm}
        setOpen={setConfirm}
        onClickOk={() => {
          setConfirm(false);
          setLoading(true);
          setDisabledSubmit(true);
          modifyAccount(formValues);
        }}>
        Would you like to save as modified information?
      </Confirm>
    </>
  );
}
