import { Button, ColorCard, Input, Alert, Spinner, BaseFormikInput, Img } from 'components';
import { useEffect, useState } from 'react';
import qs from 'qs';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, CustomH2, Span, Text, H2 } from 'styles';
import { generatePasswordValidationYup, generateCheckPasswordValidationYup } from 'utils/validationUtils';

import AuthenticationService from 'services/authentication/AuthenticationService';

export function ChangePw(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  let search = '';

  if (location.search && location.search.startsWith('?')) {
    search = location.search.substring(1);
  } else {
    search = location.search;
  }

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    verifyCode: qs.parse(search).code
  })
  const [alert, setAlert] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(null);

  const schema = yup.object().shape({
    password: generatePasswordValidationYup(t('validation.valid.password')),
    checkPassword: generateCheckPasswordValidationYup('password', t('validation.password.check.same')),
  });

  const handleOnSubmit = async (values) => {
    let response = null;

    try {
      setLoading(true);

      response = await AuthenticationService.resetPwd({
        password: values.password,
        checkPassword: values.checkPassword,
        verifyCode: params.verifyCode
      });
    } catch (e) {
      console.error(e);
    }

    setLoading(false);

    setLoading(false);

    if (response && response.status === 200) {
      setChangePasswordSuccess(true);
    } else if (response && response.message) {
      setChangePasswordSuccess(false);
      setAlert({content: response.message});
    } else {
      setChangePasswordSuccess(false);
      setAlert({content: t('common.error.server')});
    }
  };
  
  const formik = useFormik({
    initialValues: {
      password: '',
      checkPassword: '',
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });

  return (
    <>
      <Container $padding="50px 20px" $tPadding="24px 16px 48px" $margin="auto">
        <CustomH2>
          Change <Span $display="none" $tDisplay="block" />
          Password
        </CustomH2>
        <ColorCard $margin="30px auto 20px" $tMargin="24px auto" $bgColor="cardBlue" $maxWidth={600}>
          <form onSubmit={formik.handleSubmit} >
            <Box $padding="80px 100px" $tPadding="24px 16px">
              {
                !loading && changePasswordSuccess === null &&
                <>
                  <BaseFormikInput
                    type="password"
                    name="password"
                    value={formik.values.password}
                    placeholder="Password"
                    label="Password"
                    htmlFor="password"
                    require
                    $invalid={formik.errors.password}
                    $touch={formik.touched.password}
                    invalidText={formik.errors.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <BaseFormikInput
                    $marginTop={24}
                    type="password"
                    name="checkPassword"
                    value={formik.values.checkPassword}
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    htmlFor="checkPassword"
                    require
                    $invalid={formik.errors.checkPassword}
                    $touch={formik.touched.checkPassword}
                    invalidText={formik.errors.checkPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </>
              }
              
              {
                !loading && changePasswordSuccess === true &&
                <>
                  <Text $type="b38" $tType="b24" $color="blue100" $textAlign="center">
                    Complete!
                  </Text>
                  <Img src="complete" $width="fit-content" $tWidth={180} $margin="auto" $tMargin="48px auto" />
                </>
              }
              {
                !loading && changePasswordSuccess === false &&
                <>
                  <H2 $type="b38" $tType="b24" $color="error" $textAlign="center">
                    Failed
                  </H2>
                  <Text $color="gray800" $margin="4px 0 16px" $tMargin="4px 0 0" $textAlign="center">
                    The process could not be completed due to an unknown error. Please try again.
                  </Text>
                  <Img src="fail" $width="fit-content" $tWidth={180} $margin="auto" $tMargin="48px auto" />
                </>
              }
              { loading && <Spinner />}
              { !loading && changePasswordSuccess === null &&
                <Button
                  $margin="48px 0 0"
                  $width="100%"
                  $type="b14"
                  $height={44}
                  onClick={() => formik.submitForm()}
                >
                  Submit
                </Button>
              }
              <Button mode="line" $type="b14" $width="100%" $height={44} $margin="16px 0 0" onClick={() => {
                navigate(-1)
              }}>
                Cancel
              </Button>
            </Box>
          </form>
        </ColorCard>
      </Container>
      {alert && <Alert alert={alert} setAlert={setAlert} content={alert.content} onClick={() => {setAlert(null)}}/>}
    </>
  );
}
