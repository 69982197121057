import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/button/Button';
import { Checkbox } from 'components/form';
import { Icon } from 'components/icon/Icon';
import { ColBox, RowBox, RowCenter, Text } from 'styles';
import { LineText } from 'components/LineText';
import { BaseFormikInput, Spinner } from 'components';
import { useAlertContext } from 'hooks';

import userAuthenticationStore from 'stores/AuthenticationStore';
import AuthenticationService from 'services/authentication/AuthenticationService';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'data/constants';

export function LoginModal({ setModalOpen }) {
  const { alert } = useAlertContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const [loggingIn, setLoggingIn] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);

  const schema = yup.object().shape({
    id: yup.string()
        .required(t('validation.required.email.address')),
    password: yup.string()
        .required(t('validation.required.password'))
  });

  const handleOnSubmit = async (values) => {
    let loginResponse = null;

    try {
      setLoggingIn(true);

      loginResponse = await AuthenticationService.login('/api/auth/login', {
        rememberMe,
        ...values
      });
    } catch (e) {
      console.error(e);
    }

    setLoggingIn(false);

    if (loginResponse && loginResponse.access_token) {
      localStorage.setItem(ACCESS_TOKEN, loginResponse.access_token);
      localStorage.setItem(REFRESH_TOKEN, loginResponse.refresh_token);

      let userInfo = null;

      try {
        userInfo = await AuthenticationService.getMyInfo();
      } catch(e) {
        console.error(e);
      }

      if (userInfo && userInfo.id) {
        setAuthenticated(true);
        setUserInfo(userInfo);
      } else {
        setAuthenticated(false);
        setUserInfo(null);
      }

      setModalOpen('');
    } else if (loginResponse && loginResponse.message) {
      if (loginResponse.message === 'login.user.not.activated') {
        setModalOpen('deActivated')
      } else {
        alert({message: loginResponse.message});
      }
    } else {
      alert({message: t('common.error.server')});
    }
  };
  
  const formik = useFormik({
    initialValues: {
      id: '',
      password: ''
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });

  const loginWithGoogle = async (googleUserInfo, googleToken) => {
    let loginResponse = null;

    try {
      loginResponse = await AuthenticationService.login('/api/auth/loginWithGoogle', {
        googleId: googleUserInfo.sub,
        googleEmail: googleUserInfo.email,
        googleName: googleUserInfo.name,
        googleCredential: googleToken
      });
    } catch (e) {
      console.log(JSON.stringify(e));
      navigate('/login', { replace: true });
    }

    if (loginResponse && loginResponse.access_token) {
      localStorage.setItem(ACCESS_TOKEN, loginResponse.access_token);
      localStorage.setItem(REFRESH_TOKEN, loginResponse.refresh_token);

      let userInfo = null;

      try {
        userInfo = await AuthenticationService.getMyInfo();
      } catch(e) {
        console.error(e);
      }

      if (userInfo && userInfo.id) {
        setAuthenticated(true);
        setUserInfo(userInfo);
      } else {
        setAuthenticated(false);
        setUserInfo(null);
      }

      // navigate('/');

      setModalOpen('');
    } else if (loginResponse.message) {
      alert({ message: loginResponse.message });
    } else {
      alert({ message: t('common.error.server') });
    }
  };

  
  const googleSignin = useGoogleLogin({
    onSuccess: (response) => {
      console.log(response);

      const getGoogleInfo = async (tokenResponse) => {
        try {
          const userInfoResponse = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json'
            }
          });

          const jsonResponse = await userInfoResponse.json();

          if (jsonResponse && jsonResponse) {
            loginWithGoogle(jsonResponse, tokenResponse.access_token);
          }
        } catch (e) {
          console.error(e);
        }
      }

      getGoogleInfo(response);
    },
    onError: (respone) => {
      console.log(respone)
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} >
      <ColBox $gap={24}>
          <BaseFormikInput
            name="id"
            value={formik.values.id}
            placeholder=""
            label="email"
            htmlFor="id"
            require
            $invalid={formik.errors.id}
            $touch={formik.touched.id}
            invalidText={formik.errors.id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <BaseFormikInput
            type="password"
            name="password"
            value={formik.values.password}
            placeholder=""
            label="Password"
            htmlFor="password"
            require
            $invalid={formik.errors.password}
            $touch={formik.touched.password}
            invalidText={formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Checkbox name="save" $type="14" $tType="12" checked={rememberMe} onChange={() => {
            setRememberMe(!rememberMe)
          }}>
            remember me
          </Checkbox>
          { loggingIn && <Spinner />}
          {
            !loggingIn &&
            <Button
              $width="100%"
              $height={44}
              disabled={!formik.isValid}
              onClick={() => {
                formik.submitForm();
              }}
            >
              Login
            </Button>
          }
        <LineText>or continue with</LineText>
        <RowCenter
          $cursor="pointer"
          onClick={() => googleSignin()}
          $height={44}
          $borderRadius={5}
          $border={{ width: '2px', color: 'black19' }}
        >
          <Icon name="Google" />
        </RowCenter>
        <Button mode="text" $height={21} $color="blue100" $type="14" onClick={() => {
          setModalOpen('forgot')
        }}>
          Forgot password
        </Button>
        <RowBox $justifyContent="center">
          <Text $type="14" $color="gray800">
            No account?&nbsp;
          </Text>
          <Button mode="text" $height={21} $color="blue100" $type="14" onClick={() => setModalOpen('signup')}>
            Create one
          </Button>
        </RowBox>
      </ColBox>
    </form>
  );
}