import { useEffect, useState } from 'react';
import { formatApiDateStr } from 'utils';
import { useTranslation } from 'react-i18next';

export function DateDisplay({ date }) {
  const [tempDate, setTempDate] = useState('');
  const { t } = useTranslation();
  const formatDate = t('date_format');

  useEffect(() => {
    setTempDate(formatApiDateStr(date, formatDate));
  }, [date]);

  return <>{tempDate}</>;
}

export default DateDisplay;
