import styled, { css } from 'styled-components';
import { Box, RowBox } from 'styles';

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.black19};
`;

export const Container = styled(RowBox)`
  max-width: 1240px;
  margin: auto;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 100%;
    flex-direction: column;
  }
`;

const btnCss = css`
  button {
    font-size: 12px;
    line-height: 16px;
    border-right: 1px solid;
    height: fit-content;
    padding: 0 7px;
  }

  button:last-child {
    border-right: none;
  }
`;

export const PcBox = styled(RowBox)`
  ${btnCss}
  button {
    color: white;
    border-color: white;
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

export const MoBox = styled(RowBox)`
  display: none;

  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    padding: 16px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray800};
    flex-wrap: wrap;
    gap: 8px;
    button {
      color: ${({ theme }) => theme.colors.blue100};
      border-color: ${({ theme }) => theme.colors.gray800};
    }

    ${btnCss};
  }
`;
