import React, { useState } from 'react';
import { Icon } from 'components/icon/Icon';
import styled from 'styled-components';
import { RowBox, Span, Text } from 'styles';

export function InputFile(props) {
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [test, setTest] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    // const files = e.dataTransfer.files;
    // if (files.length > 0) {
    //   onFileUpload(files);
    // }
  };

  const handleFileInputChange = (e) => {
    // const files = e.target.files;
    // if (files.length > 0) {
    //   onFileUpload(files);
    // }
  };

  const sampleOnClick = () => {
    setIsLoading(true);
    setTest(true);
  };

  return (
    <Dropzone $loading={isLoading} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
      <label htmlFor="upload" onClick={sampleOnClick}>
        <FileBox $loading={isLoading}>{isLoading ? `Uploading..` : `Upload`}</FileBox>
        &nbsp;
        <span>or Drop Files</span>
        <input type="file" id="upload" className="fileInput" onChange={handleFileInputChange} multiple />
      </label>
      {test && (
        <UploadBox>
          <UploadFileBox>
            <div>
              <Text $type="b14">Brainstorming documentation</Text>
              <Text $color="gray100" $type="12">
                Size&nbsp;-&nbsp;
                <Span $color="gray100" $type="12">
                  1.00
                </Span>
                MB
              </Text>
            </div>
            <Icon name="Close" $svgWidth={30} $svgHeight={30} onClick={() => alert('del item')} />
          </UploadFileBox>
          <UploadFileBox>
            <div>
              <Text $type="b14">Brainstorming documentation</Text>
              <Text $color="gray100" $type="12">
                Size&nbsp;-&nbsp;
                <Span $color="gray100" $type="12">
                  1.00
                </Span>
                MB
              </Text>
            </div>
            <Icon name="Close" $svgWidth={30} $svgHeight={30} onClick={() => alert('del item')} />
          </UploadFileBox>
        </UploadBox>
      )}
    </Dropzone>
  );
}
const Dropzone = styled.div`
  max-width: 344px;
  margin-top: 12px;
  width: 100%;

  font-size: 14px;
  color: #667085;
  cursor: pointer;

  .fileInput {
    display: none;
  }
  span {
    padding-left: 8px;
  }

  label {
    border-radius: 10px;
    border: 1px dashed ${({ $loading }) => ($loading ? '#39C5DD' : '#d0d5dd')};
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    ${({ $loading, theme }) => $loading && `box-shadow: 0 0 0 3px ${theme.colors.inputLine}`};
  }
`;

const FileBox = styled.div`
  position: relative;
  border: 1px solid ${({ $loading }) => ($loading ? '#39C5DD' : '#d0d5dd')};
  padding: 8px 14px 8px 40px;
  border-radius: 4px;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: ${({ $loading, theme }) => ($loading ? theme.colors.cardBlue : 'white')};

  ${({ $loading }) => $loading && `color:white;`}

  &::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: ${({ $loading }) => ($loading ? 'rgba(255,255,255,0.2)' : '#e0e2e7')};
    border-radius: 3px;
    position: absolute;
    top: 8.5px;
    left: 14px;
  }
`;

const UploadBox = styled.div`
  padding-bottom: 26px;
`;

const UploadFileBox = styled(RowBox)`
  margin-top: 16px;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray800};
`;
