import { useEffect, useState } from 'react';
import { Dropdown } from './Dropdown';

export function FormikDropdown({ invalid, showValid, touched, ...props }) {
  const [invalidValue, setInvalidValue] = useState(null);

  useEffect(() => {
    if (touched && invalid && invalid.length > 0) {
      setInvalidValue('error');
    } else if (touched && showValid && !invalid) {
      setInvalidValue('success');
    } else {
      setInvalidValue(null);
    }
  }, [invalid, showValid, touched]);

  return <Dropdown $invalid={invalidValue} invalidText={touched && invalid && invalid.length > 0 ? invalid : undefined} {...props} />;
}
