import React, { useEffect } from 'react';
import styled from 'styled-components';
import { preventScroll, allowScroll } from 'utils';

export function Dim({ type, open, onClick, children, ...props }) {
  useEffect(() => {
    if (open) {
      const prevScrollY = preventScroll();
      return () => {
        allowScroll(prevScrollY);
      };
    }
  }, [open]);

  return (
    <Container $type={type} $open={open} onClick={onClick} {...props}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  z-index: 110;
`;
