import React from 'react';
import * as S from './SwitchStyle';

export function Switch({ name, type, checked, onChange, label1, label2 }) {
  const handleLabelClick = (label) => {
    if ((label === label1 && checked) || (label === label2 && !checked)) {
      onChange(name, label);
    }
  };
  return (
    <S.SwitchContainer $type={type}>
      <S.SwitchLabel $checked={!checked} onClick={() => handleLabelClick(label1)}>
        {label1}
      </S.SwitchLabel>
      <S.Switch checked={checked} readOnly />
      <S.SwitchCircle $type={type} checked={checked} onClick={onChange} />
      <S.SwitchLabel $checked={checked} onClick={() => handleLabelClick(label2)}>
        {label2}
      </S.SwitchLabel>
    </S.SwitchContainer>
  );
}
