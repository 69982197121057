import styled, { css } from 'styled-components';
import { Box, FlexList, FlexListItem, ListItem, RowBox, RowCenter, Text } from 'styles';

export const Stepper = styled(FlexList)`
  max-width: 600px;
  margin: 16px auto 32px;
  position: relative;
  height: 60px;

  &::before {
    content: '';
    position: absolute;
    width: 64%;
    left: 18%;
    height: 1px;
    bottom: 12px;
    background-color: ${({ theme }) => theme.colors.gray500};
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin: 16px auto;
  }
`;

export const StepItem = styled.li`
  position: relative;
  cursor: pointer;
  width: 33.34%;
  transition: all 150ms;

  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    left: calc(50% - 12px);
    bottom: 0;
    background-color: white;
    border: 1px solid ${({ theme, $active }) => ($active ? theme.colors.primary600 : theme.colors.gray500)};
    ${({ $active }) => $active && `box-shadow: 0 0 4px 4px rgba(16, 71, 114, 0.2)`};
  }

  &::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    left: calc(50% - 9px);
    bottom: 3px;
    border-radius: 50%;
    background-color: ${({ theme, $active }) => ($active ? theme.colors.primary600 : theme.colors.gray500)};
  }

  p {
    white-space: pre;
    text-align: center;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    color: ${({ $active, theme }) => ($active ? theme.colors.primary600 : theme.colors.gray100)};
  }
`;

export const RowTextBox = styled(RowBox)`
  align-items: ${({ $align }) => ($align && $align) || 'center'};
  gap: 16px;
  padding: 16px 0;

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ $tPadding }) => $tPadding || '16px 0'};
  }
`;

export const InfoList = styled(FlexList)`
  margin-top: 32px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  padding: 24px;
  gap: 32px;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 16px;
  }
`;

export const ReviewBox = styled(Box)`
  max-width: ${({ $small }) => ($small ? `300px` : `400px`)};
  margin: auto;
  padding: 10px 0;
  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ $tPadding }) => $tPadding || '24px 16px'};
    max-width: 100%;
  }
`;

export const PayText = styled(Text)`
  width: 100px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray800};

  @media ${({ theme }) => theme.device.tablet} {
    width: 80px;
    font-size: 14px;
  }
`;

export const PaySubText = styled(Text)`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray100};
`;

export const CardListItem = styled(FlexListItem)`
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray500};
  cursor: pointer;

  ${({ $active, theme }) =>
    $active &&
    css`
      border-color: ${theme.colors.blue100};
      background-color: ${theme.colors.background};
    `}

  @media ${({ theme }) => theme.device.tablet} {
    height: 50px;
  }
`;
