import React, { useState, useRef, useEffect } from 'react';
import { about } from 'data';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { Box, ColBox, RowBox, Text } from 'styles';
import { Img } from 'components/img/Img';
import { Icon } from 'components/icon/Icon';
import * as S from './ProductsStyle';

export function MoSlider(props) {
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.slidePrev) {
      swiperRef.current.slidePrev();
    }
  };

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.slideNext) {
      swiperRef.current.slideNext();
    }
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on('slideChange', () => {
        setActiveIndex(swiperRef.current.activeIndex);
      });
    }
  }, []);

  return (
    <ColBox $gap={8} $marginTop={24} $width="100%">
      <S.MoSlider
        spaceBetween={8}
        slidesPerView={1.1}
        modules={[Navigation, Pagination, A11y, Autoplay]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {about.map((slide, index) => (
          <SwiperSlide key={`mo_slide_${index}`}>
            <S.MoSlideBox>
              <Img src="about" $width="64%" $margin="auto" />
              <Box>
                <Text $color="gray800" $type="b18">
                  {slide.title}
                </Text>
                <Text $marginTop={8} $color="gray100" $type="m14">
                  {slide.content}
                </Text>
              </Box>
            </S.MoSlideBox>
          </SwiperSlide>
        ))}
      </S.MoSlider>
      <S.MoNaviBox>
        <RowBox $gap={24}>
          <Icon
            name="ArrowPage"
            $opacity={activeIndex === 0 && 0.3}
            $transform="rotate(180deg)"
            onClick={goToPrevSlide}
          />
          <Icon name="ArrowPage" $opacity={activeIndex === about.length - 1 && 0.3} onClick={goToNextSlide} />
        </RowBox>
        <S.SliderBar $left={activeIndex * 33.33} />
      </S.MoNaviBox>
    </ColBox>
  );
}
