import styled, { css, keyframes } from 'styled-components';
import { hexToRGB } from 'utils';

const spin = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); }
  to { transform: translate(-50%, -50%) rotate(359deg); }`;

const dot = keyframes`
  0%, 100% { width: 4px; height: 4px;  }
  50% { width: 8px; height: 8px }
`;

const mainLoader = keyframes`
  0%{transform:translate(-50%, -50%) rotate(0deg);}
  100%{transform:translate(-50%, -50%) rotate(360deg);}`;

export const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  gap: 9px;
`;

export const DotLoader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 60px;
  width: 60px;

  div {
    ${({ $dotColor }) =>
      $dotColor === 'primary'
      ? css`
          background-color: ${({ theme }) => theme.colors.primary};
        `
      : css`
          background-color: #fff;
        `}
    border-radius: 50%;
    animation: ${dot} 1.2s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
    &:nth-child(5) {
      animation-delay: 0.8s;
    }
  }
`;

export const LoadingContainer = styled.div`
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${({ type }) =>
    type === 'spin'
      ? css`
          position: absolute;
        `
      : css`
          position: fixed;
        `}
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ type }) =>
    type === 'spin'
      ? css`
          width: 12px;
          height: 12px;
          border: 2px solid white;
          border-right-color: ${({ theme }) => theme.colors.primary};
          border-top-color: ${({ theme }) => theme.colors.primary};
          border-radius: 999px;
          animation: ${spin} 900ms infinite linear;
        `
      : css`
          position: relative;
          height: 60px;
          width: 60px;
          border-radius: 99px;
          border: 3px solid ${({ theme }) => hexToRGB(theme.colors.primary, 0.5)};
          -webkit-animation: ${mainLoader} 2.3s linear infinite;
          animation: ${mainLoader} 2.3s linear infinite;
          &:after {
            content: '';
            position: absolute;
            top: -5px;
            left: 20px;
            width: 11px;
            height: 11px;
            border-radius: 10px;
            background-color: ${({ theme }) => theme.colors.main};
          }
        `}
`;
