import React from 'react';
import styled from 'styled-components';
import { Box } from 'styles';

export function LineText({ children, ...props }) {
  return (
    <LineBox {...props}>
      <p>{children}</p>
    </LineBox>
  );
}

export const LineBox = styled(Box)`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray500};
  }

  p {
    color: ${({ theme }) => theme.colors.gray100};
    font-size: 14px;
    position: relative;
    z-index: 1;
    width: fit-content;
    background-color: white;
    padding: 0 4px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
