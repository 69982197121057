import React, { useState, useEffect } from 'react';
import { Icon } from 'components';
import { menuList } from 'data/navData';
import { Box, FlexList, H2, RowBox, Text } from 'styles';
import * as S from './SubLayoutStyle';

export function MoSubLayout({ h2, navigate, location = { pathname: '' }, path1, path2, accountMenu }) {
  const [currentMenu, setCurrentMenu] = useState();

  useEffect(() => {
    let menu;
    if (location.pathname.includes('account') && accountMenu) {
      setCurrentMenu(accountMenu);
    } else {
      menu = location.pathname !== '' && menuList.find((menu) => location.pathname.includes(menu.path));
      setCurrentMenu(menu);
    }
  }, [location.pathname, accountMenu]);

  return (
    <>
      <Box>
        <S.MoRowBox>
          <Icon name="Dotted" $transform="rotate(-180deg)" />
          <H2 $type="b24" $color="gray800">
            {h2}
          </H2>
          <Icon name="Dotted" />
        </S.MoRowBox>
        {currentMenu && currentMenu.subList && (
          <FlexList $borderBottom={{ color: 'blue50' }} $margin="0 8px">
            {currentMenu.subList.map((sub, index) => (
              <S.TabListItem
                key={`subTap_${index}`}
                onClick={(e) => {
                  if (sub.path !== 'disabled') {
                    navigate(`${sub.path}`);
                  } else {
                    e.stopPropagation();
                  }
                }}
                $selected={location.pathname.includes('account') ? location.pathname.split('/')[2] === sub.path : location.pathname.includes(sub.path)}>
                <Text
                  $textAlign="center"
                  $color={
                    (location.pathname.includes('account') && location.pathname.split('/')[2] === sub.path) ||
                    (!location.pathname.includes('account') && location.pathname.includes(sub.path))
                      ? 'primary600'
                      : 'gray100'
                  }
                  $type="b14">
                  {location.pathname.includes('account') ? sub.label.split(' ')[0] : sub.label}
                </Text>
              </S.TabListItem>
            ))}
          </FlexList>
        )}
      </Box>
      <S.BreadCrumbs $mobile $borderTop={{ color: 'gray500' }}>
        <RowBox $alignItems="center" $gap={4} onClick={() => navigate('/')} $cursor="pointer">
          <Icon name="Home" />
          <Text $color="gray800" $type="12">
            Main
          </Text>
        </RowBox>
        <Icon name="Arrow" />
        <Text $color="gray800" $type="12" $cursor="pointer">
          {(path1 && path1) || location.pathname.split('/')[1]}
        </Text>
        {(location.pathname.split('/')[2] || path2) && (
          <>
            <Icon name="Arrow" />
            <Text $color="gray800" $type="12" $cursor="pointer">
              {(path2 && path2) || location.pathname.split('/')[2].toUpperCase()}
            </Text>
          </>
        )}
      </S.BreadCrumbs>
    </>
  );
}
