import styled from 'styled-components';
import { List, RowBox } from 'styles';

export const DropBox = styled(RowBox)`
  align-items: center;
  padding: 3px 10px;
  gap: 4px;
  border-radius: 6px;
  cursor: pointer;
  transition: backgkround 150ms;
  background-color: ${({ $listOpen, $background, theme }) =>
    $listOpen && $background ? theme.colors.black19 : $listOpen ? theme.colors.gray500 : 'transparent'};

  &:hover {
    background-color: ${({ $background, theme }) => ($background ? '#104772' : theme.colors.background)};
  }
`;

export const DropList = styled(List)`
  position: absolute;
  z-index: 1;
  top: 30px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px 0;
  border: 1px solid ${({ theme }) => theme.colors.background};
  box-shadow:
    0px 1px 4px 0px rgba(0, 0, 0, 0.25),
    0px 1px 2px 0px rgba(0, 0, 0, 0.08);

  & > li {
    padding: 8px 16px;
  }
`;
