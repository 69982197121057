import { objectToFormData } from 'utils';
import HttpRequest from '../HttpRequest';

const saveContent = async (board) => {
  const formData = new FormData();

  objectToFormData(formData, board);

  const response = await HttpRequest.post('/api/qna/saveContent.json', {}, {}, formData);

  return response;
};

const selectFaqPagination = async (params) => {
  const response = await HttpRequest.get('/api/faq/list.json', params);
  return response;
};

const getBoardSubjects = async (moduleNo) => {
  const response = await HttpRequest.get('/api/board/getBoardSubjects.json', {moduleNo});
  return response;
};

export default {
  saveContent,
  selectFaqPagination,
  getBoardSubjects,
};
