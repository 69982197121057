import * as yup from 'yup';
import { getByteSize } from 'utils/getByteSize';

export function generatePasswordPatentValidationYup(passwordValidationMessage) {
  return yup
    .string()
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, passwordValidationMessage);
}

export function generatePasswordValidationYup(passwordValidationMessage) {
  const passwordScheme = yup.string().required(passwordValidationMessage)
      .min(8, passwordValidationMessage)
      .max(11, passwordValidationMessage);
  return passwordScheme.concat(generatePasswordPatentValidationYup(passwordValidationMessage));
}

export function generateCheckPasswordValidationYup(passworRefName, validationMessage) {
  return yup
    .string()
    .required(validationMessage)
    .oneOf([yup.ref(passworRefName), null], validationMessage);
}

export function generateUrlValidationYup(validationMessage) {
  return yup
    .string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S*)?$/, validationMessage);
}

export function generatePatentFormCommonValidationYup(t) {
  return yup.object().shape(
    {
      // patentCustomerNo: yup.string()
      //     .required(t('validation.required.entry'))
      //     .test('maxByte', t('validation.max.byte', {maxByte: 50}), (val) => getByteSize(val) <= 50),
      // patentCustomerName: yup.string()
      //     .required(t('validation.required.entry'))
      //     .test('maxByte', t('validation.max.byte', {maxByte: 100}), (val) => getByteSize(val) <= 100),
      // patentCustomerSsn: yup.string()
      //     .required(t('validation.required.entry'))
      //     .matches(/^\d+$/, t('validation.numbers.only'))
      //     .test('maxByte', t('validation.max.byte', {maxByte: 13}), (val) => getByteSize(val) <= 13),
      ordererNameKo: yup
        .string()
        .ensure()
        .when('ordererNameEn', {
          is: (value) => !value || value.length === 0,
          then: () =>
            yup
              .string()
              .required(t('validation.required.entry'))
              .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
          otherwise: () =>
            yup
              .string()
              // .required(t('validation.required.entry'))
              .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
        }),
      ordererNameEn: yup
        .string()
        .ensure()
        .when('ordererNameKo', {
          is: (value) => !value || value.length === 0,
          then: () =>
            yup
              .string()
              .required(t('validation.required.entry'))
              .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
          otherwise: () =>
            yup
              .string()
              // .required(t('validation.required.entry'))
              .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
        }),
      // ordererSsn: yup.string()
      //     .required(t('validation.required.entry'))
      //     .matches(/^\d+$/, t('validation.numbers.only'))
      //     .test('maxByte', t('validation.max.byte', {maxByte: 13}), (val) => getByteSize(val) <= 13),
      ordererEmail: yup
        .string()
        .required(t('validation.required.entry'))
        .email(t('validation.valid.email.address'))
        .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
      ordererMobile: yup
        .string()
        .required(t('validation.required.entry'))
        .matches(/^\d+$/, t('validation.numbers.only'))
        .test('maxByte', t('validation.max.byte', { maxByte: 20 }), (val) => getByteSize(val) <= 20),
      ordererSubAddress: yup
        .string()
        .test('maxByte', t('validation.max.byte', { maxByte: 256 }), (val) => getByteSize(val) <= 256),
    },
    [['ordererNameKo', 'ordererNameEn']],
  );
}

export function generatePatentFormCompanyValidationYup(t) {
  const commonSchema = generatePatentFormCommonValidationYup(t);
  const newSchema = commonSchema.concat(
    yup.object().shape({
      companyNo: yup
        .string()
        .required(t('validation.required.entry'))
        .matches(/^\d+$/, t('validation.numbers.only'))
        .test('maxByte', t('validation.max.byte', { maxByte: 20 }), (val) => getByteSize(val) <= 20),
      companyBizNo: yup
        .string()
        .required(t('validation.required.entry'))
        .matches(/^\d+$/, t('validation.numbers.only'))
        .test('maxByte', t('validation.max.byte', { maxByte: 20 }), (val) => getByteSize(val) <= 20),
      ordererPhone: yup
        .string()
        .required(t('validation.required.entry'))
        .matches(/^\d+$/, t('validation.numbers.only'))
        .test('maxByte', t('validation.max.byte', { maxByte: 20 }), (val) => getByteSize(val) <= 20),
    }),
  );

  return newSchema;
}
