import styled from 'styled-components';
import {RowBox} from "../../../styles";

export const SelectBox = styled(RowBox)`
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.gray50};
  border-radius: 10px;

  svg {
    position: absolute;
    right: 18px;
    top: 10px;
  }
`;

export const SelectStyle = styled.select`
  width: 100%;
  padding: 9px 16px;
  font-size: 14px;
  line-height: 1.5;
  background-color: white;
  border: 2px solid
    ${({ $invalid, theme }) =>
      ($invalid === 'success' ? theme.colors.success : $invalid === 'error' && theme.colors.errorLine) ||
      'transparent'};
  border-radius: 8px;
  transition: 150ms;
  box-sizing: border-box;
  box-shadow: 0 0 0 3px
    ${({ $invalid, theme }) =>
      ($invalid === 'success' ? theme.colors.successLine : $invalid === 'error' && theme.colors.errorLine) ||
      'transparent'};
  ${({ $invalid, theme }) => $invalid === 'error' && `border-color:${theme.colors.error}`};

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue100};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.inputLine};
  }
`;

export const OptionStyle = styled.option`
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.5;
  background-color: white;
  border: 2px solid
    ${({ $invalid, theme }) =>
      ($invalid === 'success' ? theme.colors.success : $invalid === 'error' && theme.colors.errorLine) ||
      'transparent'};
  border-radius: 8px;
  transition: 150ms;
  box-sizing: border-box;
  box-shadow: 0 0 0 3px
    ${({ $invalid, theme }) =>
      ($invalid === 'success' ? theme.colors.successLine : $invalid === 'error' && theme.colors.errorLine) ||
      'transparent'};
  ${({ $invalid, theme }) => $invalid === 'error' && `border-color:${theme.colors.error}`};

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue100};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.inputLine};
  }
`;
