import { Checkbox } from './Checkbox';

export function FormikCheckbox({ children, invalid, touched, ...props }) {
  return (
    <Checkbox
      $invalid={touched && invalid && invalid.length > 0 && 'error'}
      invalidText={touched && invalid && invalid.length > 0 ? invalid : undefined}
      {...props}
    >
      {children}
    </Checkbox>
  );
}
