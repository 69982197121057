export const priceColors = ['gray100', 'cardBlue', 'purple', 'blue100', 'secondary'];
export const priceList = [
  {
    type: 'PT01',
    color: 'gray100',
    content: [
      {
        type: 'Free Trial',
        productCd: 'F01',
        color: 'gray100',
        discount: 0,
        cost: 0,
        period: 'Forever',
        currency: 'USD',
        maxRoute: 10,
        level: 0,
        list: [
          { text: `Route Recommendation`, desc: `10 times / 7-days`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
      {
        type: 'Basic',
        productCd: 'B01',
        periodType: 'PT01',
        color: 'cardBlue',
        cost: 89,
        discount: 39,
        currency: 'USD',
        period: 'per month, billed monthly',
        maxRoute: 10,
        level: 1,
        list: [
          { text: `Route Recommendation`, desc: `10 times`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
      {
        type: 'Standard',
        productCd: 'S01',
        periodType: 'PT01',
        color: 'purple',
        cost: 149,
        discount: 69,
        currency: 'USD',
        period: 'per month, billed monthly',
        maxRoute: 30,
        level: 2,
        list: [
          { text: `Route Recommendation`, desc: `30 times`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
      {
        type: 'Premium',
        productCd: 'P01',
        periodType: 'PT01',
        color: 'blue100',
        cost: 739,
        discount: 369,
        currency: 'USD',
        period: 'per month, billed monthly',
        maxRoute: 300,
        level: 3,
        list: [
          { text: `Route Recommendation`, desc: `300 times`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
      {
        type: 'Enterprise',
        productCd: 'E01',
        periodType: 'PT01',
        color: 'secondary',
        cost: 1499,
        discount: 749,
        currency: 'USD',
        period: 'per month, billed monthly',
        maxRoute: '',
        level: 4,
        list: [
          { text: `Route Recommendation`, desc: `Unlimited`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'Route forecast download' },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
    ],
  },
  {
    type: 'PT02',
    off: '(15~30% off)',
    content: [
      {
        type: 'Free Trial',
        productCd: 'F01',
        color: 'gray100',
        cost: 0,
        discount: 0,
        period: 'Forever',
        currency: 'USD',
        maxRoute: 10,
        level: 0,
        list: [
          { text: `Route Recommendation`, desc: `10 times / 7-days`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
      {
        type: 'Basic',
        productCd: 'B02',
        periodType: 'PT02',
        color: 'cardBlue',
        cost: 29,
        discount: 69,
        period: 'per month, billed monthly',
        currency: 'USD',
        maxRoute: 10,
        level: 1,
        list: [
          { text: `Route Recommendation`, desc: `10 times`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
      {
        type: 'Standard',
        productCd: 'S02',
        periodType: 'PT02',
        cost: 59,
        discount: 110,
        currency: 'USD',
        period: 'per month, billed monthly',
        maxRoute: 30,
        level: 2,
        list: [
          { text: `Route Recommendation`, desc: `30 times`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
      {
        type: 'Premium',
        productCd: 'P02',
        periodType: 'PT02',
        cost: 289,
        discount: 589,
        currency: 'USD',
        period: 'per month, billed monthly',
        maxRoute: 300,
        level: 3,
        list: [
          { text: `Route Recommendation`, desc: `300 times`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
      {
        type: 'Enterprise',
        productCd: 'E02',
        periodType: 'PT02',
        cost: 1249,
        discount: 619,
        currency: 'USD',
        period: 'per month, billed monthly',
        maxRoute: '',
        level: 4,
        list: [
          { text: `Route Recommendation`, desc: `Unlimited`, info: `info` },
          { text: `Weather Display`, info: `info` },
          { text: 'Port Forecast', info: `info` },
          { text: 'Route forecast download' },
          { text: 'No advertisements' },
          { text: 'Custom Route', service: `BETA` },
        ],
      },
    ],
  },
];

export const stepList = [
  { id: 1, title: `Check your\nChosen service` },
  { id: 2, title: `Payment\nInformation` },
  { id: 3, title: `Review\nPayment` },
];

export const cardType = [
  { type: 'visa', value: 'visa' },
  { type: 'master', value: 'master' },
  { type: 'jcb', value: 'jcb' },
  { type: 'union', value: 'unionPay' },
  { type: 'diner', value: 'dinersClub' },
  { type: 'amex', value: 'amex' },
];