/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable new-cap */

import qs from 'qs';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Img } from 'components/img/Img';
import { Box, Container, CustomH2, Text, Span, H2 } from 'styles';
import { Button, ColorCard, Alert, Spinner } from 'components';

import AuthenticationService from 'services/authentication/AuthenticationService';

export function SignupVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  let search = '';

  if (location.search && location.search.startsWith('?')) {
    search = location.search.substring(1);
  } else {
    search = location.search;
  }

  const [loading, setLoading] = React.useState(true);
  const [params, setParams] = React.useState({
    verifyCode: qs.parse(search).code
  });
  const [alert, setAlert] = React.useState(false);
  const [verifySuccess, setVerifySuccess] = React.useState(null);

  const verify = async () => {
    let response = null;

    try {
      setLoading(true);

      response = await AuthenticationService.verifySignup(params.verifyCode);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);

    if (response && response.status === 200) {
      setVerifySuccess(true);
    } else if (response && response.message) {
      setVerifySuccess(false);
      setAlert({ content: response.message });
    } else {
      setVerifySuccess(false);
      setAlert({ content: t('common.error.server') });
    }
  };

  React.useEffect(() => {
    verify();
  }, []);

  return (
    <>
      <Container $padding="50px 20px" $tPadding="24px 16px 48px" $margin="auto">
        <CustomH2>
          Account <Span $display="none" $tDisplay="block" />
          Activated
        </CustomH2>
        <ColorCard $margin="30px auto 20px" $tMargin="24px auto" $bgColor="cardBlue" $maxWidth={600}>
          <Box $padding="80px 100px" $tPadding="24px 16px">
            {loading && <Spinner />}
            {!loading && verifySuccess && (
              <>
                <Text $type="b38" $tType="b24" $color="blue100" $textAlign="center">
                  Complete!
                </Text>
                <Img src="complete" $width="fit-content" $tWidth={180} $margin="auto" $tMargin="48px auto" />
              </>
            )}
            {!loading && !verifySuccess && (
              <>
                <H2 $type="b38" $tType="b24" $color="error" $textAlign="center">
                  Failed
                </H2>
                <Text $color="gray800" $margin="4px 0 16px" $tMargin="4px 0 0" $textAlign="center">
                  The process could not be completed due to an unknown error. Please try again.
                </Text>
                <Img src="fail" $width="fit-content" $tWidth={180} $margin="auto" $tMargin="48px auto" />
              </>
            )}
            {!loading && verifySuccess && (
              <Button
                $margin="48px 0 0"
                $width="100%"
                $type="b14"
                $height={44}
                onClick={() => {
                  const btnHeaderLogin = document.getElementById('btn-header-login');
                  const btnMoHeaderLogin = document.getElementById('btn-mo-header-login');

                  if (btnHeaderLogin) {
                    btnHeaderLogin.click();
                  } else if (btnMoHeaderLogin) {
                    btnMoHeaderLogin.click();
                  }
                }}>
                Go to Login
              </Button>
            )}
            <Button mode="line" $type="b14" $color="gray900" $width="100%" $height={44} $margin="16px 0 0" onClick={() => navigate('/')}>
              Go to Main
            </Button>
          </Box>
        </ColorCard>
      </Container>
      {alert && (
        <Alert
          alert={alert}
          setAlert={setAlert}
          content={alert.content}
          onClick={() => {
            setAlert(null);
          }}
        />
      )}
    </>
  );
}
