import styled, { css } from 'styled-components';
import { flex, space } from 'styles/css';
import { hexToRGB } from 'utils';

export const Button = styled.button`
  ${space}
  ${flex}
  transition: opacity 150ms;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  &:disabled {
    opacity: 0.4;
  }

  ${({ $mode, theme, $bgColor, $main }) =>
    $mode === 'text'
      ? css`
          border-radius: 0;
          background-color: transparent;
        `
      : $mode === 'headerLine'
        ? css`
            border: 1px solid ${hexToRGB(theme.colors.gray800, 0.3)};
            font-weight: 600;
            color: ${$main ? hexToRGB(theme.colors.white, 0.5) : hexToRGB(theme.colors.gray800, 0.5)};
          `
        : $mode === 'line'
          ? css`
              border: 1px solid ${theme.colors.gray50};
              background-color: white;

              & > span {
                color: ${theme.colors.gray900};
              }
            `
          : $mode === 'redLine'
            ? css`
                background-color: ${theme.colors.red50};
                border: 1px solid ${theme.colors.error};
              `
            : css`
                background-color: ${($bgColor && theme.colors[$bgColor]) || theme.colors.blue100};
                color: white;
                font-size: 12px;
              `};
`;

// ${({ onClick }) =>
//   onClick &&
//   css`
//     cursor: pointer;
//     @media (hover: hover) {
//       &:hover {
//         opacity: 0.7;
//       }
//     }
//   `};
