import HttpRequest from '../HttpRequest';

const postPaymentError = async (values) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const response = await HttpRequest.post('/api/pg/pay_error', {},headers , JSON.stringify(values));
  return response;
};


export default {
  postPaymentError
};
