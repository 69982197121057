import { Button } from 'components/button/Button';
import { Checkbox } from 'components/form';
import { Icon } from 'components/icon/Icon';
import { useLogout } from 'hooks';
import { useEffect, useState } from 'react';
import userService from 'services/user/UserService';
import { Box, RowBox, Span, Text } from 'styles';
import { allowScroll, preventScroll } from 'utils';
import { Dim } from '../Dim';
import * as S from './ModalStyle';

export function DelModal({ open, setOpen, email }) {
  const logout = useLogout();
  const [agree, setAgree] = useState(false);

  const deleteUser = async (email) => {
    try {
      const response = await userService.delete(email);
      if (!response) return false;

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const delOnClick = async () => {
    const success = await deleteUser(email);
    if (success) {
      setOpen(false);
      logout();
    }
  };

  useEffect(() => {
    if (open) {
      const prevScrollY = preventScroll();
      return () => {
        allowScroll(prevScrollY);
      };
    }
  }, [open]);

  return (
    <Dim open={open}>
      <S.DelModalContainer>
        <Icon name="Delete" />
        <Box $marginTop={24}>
          <Text $type="b16" $color="error">
            Delete Account
          </Text>
          <Text $padding="16px 0 24px">
            Are you sure you want to delete the account linked to
            <br />
            <Span $type="b">{email}</Span>?
          </Text>
          <Checkbox name="delete" $align="flex-start" checked={agree} onChange={() => setAgree(!agree)}>
            <Text $type="m12" $color="gray800">
              I understand that I won’t be able to recover my account.
            </Text>
          </Checkbox>
          <RowBox $marginTop={24} $gap={8}>
            <Button
              mode="redLine"
              onClick={delOnClick}
              $color="error"
              $type="b"
              $width="100%"
              $height={44}
              disabled={!agree}
            >
              Delete
            </Button>
            <Button mode="line" onClick={() => setOpen(false)} $type="b" $width="100%" $height={44}>
              Cancel
            </Button>
          </RowBox>
        </Box>
      </S.DelModalContainer>
    </Dim>
  );
}
