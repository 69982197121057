import React, { useState, useEffect } from 'react';

import { SubLayout } from 'layouts';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {useAsync} from "react-use";
import { Button, ColorCard, Icon, Modal, Switch, Tooltip } from 'components';
import { Box, CardTag, Container, CustomH3, FlexList, FlexListItem, ListItem, RowBox, Span, Text } from 'styles';
import { payInfoList, stepList } from 'data';
import moment from "moment";


import OrderService from "services/order/OrderService";

import * as S from './PaymentStyle';

export function PaymentSuccess(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentStep, setCurrentStep] = useState(3);
  const [valid, setValid] = useState(false);
  const [formValues, setFormValues] = useState({});

  const paymentType = searchParams.get("paymentType");
  const orderId = searchParams.get("orderId");
  const paymentKey = searchParams.get("paymentKey");
  const amount = searchParams.get("amount");


  console.log({paymentType, orderId, paymentKey, amount});

  useAsync(async () => {
    if (orderId) {
      if(searchParams.get("paymentKey")){
        const localData = localStorage.getItem("orderData");
        if(localData){
          const orderForm = JSON.parse(localData);
          if(orderForm.orderNo === orderId){
            setValid(true);
            const values = {...orderForm, paymentKey, paymentType, paymentAmount: amount};

            const response = OrderService.payConfirm(values);
            setFormValues(response);
          }
        }
      }else{
        const orderPayment = await OrderService.getOrderPayment(orderId);
        if(orderPayment){
          setValid(true);
          setFormValues(orderPayment);
        }

      }

    }
  }, [orderId]);


  const masked = (num) => {
    if (num) {
      return num
        .split(' ')
        .map((part, index) => (index === 1 || index === 3 ? part.replace(/[0-9a-zA-Z]/g, '*') : part))
        .join(' ');
    }else{
      return '';
    }
  }

  return (
    <SubLayout h2="Payment" moH2="PAYMENT">
      <Container $padding="50px 20px 128px" $tPadding="40px 16px 88px" $margin="auto">
        <CustomH3>PAYMENT</CustomH3>
        <Box $padding="16px 0 24px">
          <Text $type="b24" $tType="b20" $color="gray100" $textAlign="center">
            {currentStep === 3 ? `Review Payment` : `Service Payment Information`}
          </Text>
          <Text $marginTop={16} $mMarginTop={8} $type="m14" $color="gray800" $textAlign="center">
            Thank you for choosing our services! We aim to make
            <br />
            the payment process straightforward and secure for your convenience.
          </Text>
        </Box>
        <S.Stepper>
          {stepList.map((step) => (
            <S.StepItem key={`step_${step.id}`} $active={currentStep === step.id}>
              <p>{step.title}</p>
            </S.StepItem>
          ))}
        </S.Stepper>
        {valid && (
          <ColorCard $bgColor="cardBlue" $maxWidth={600} $margin="auto">
            <S.ReviewBox>
              <Text $type="b24" $color="gray100" $textAlign="center" $tTextAlign="start">
                Payment Information
              </Text>
              <S.RowTextBox>
                <S.PayText>Order ID</S.PayText>
                <Text $type="b20" $tType="b16">
                  {formValues.orderNo}
                </Text>
              </S.RowTextBox>
              <S.RowTextBox>
                <S.PayText>Order Date</S.PayText>
                <Text>{moment().format('YYYY.MM.DD')}</Text>
              </S.RowTextBox>
              <S.RowTextBox>
                <S.PayText>
                  Payment
                  <br />
                  Method
                </S.PayText>
                {formValues.productCd !== 'F01' && (
                  <Text>
                    Credit Card
                    <br />
                    <Span>{masked(formValues.cardNo)}</Span>
                  </Text>
                )}
                {formValues.productCd === 'F01' && (
                  <Text>
                    {formValues.productName}
                  </Text>
                )}

              </S.RowTextBox>
              {formValues.amount && formValues.amount > 0 &&(
                <S.RowTextBox>
                  <S.PayText>Subtotal</S.PayText>
                  <Text $type="b24" $color="gray800">
                    {formValues.amount}
                  </Text>
                </S.RowTextBox>
              )}


              <Box $marginTop={20} $padding="24px 0 20px" $tPadding="8px 0" $borderTop={{ color: 'gray500' }}>
                <Text $type="b24" $color="gray100" $textAlign="center" $tTextAlign="start">
                  Payer Information
                </Text>
                <S.RowTextBox>
                  <S.PayText>User Name</S.PayText>
                  <Text $color="black19">{formValues.regUserName}</Text>
                </S.RowTextBox>
                <RowBox $gap={16} $padding="16px 0" $tPadding="8px 0">
                  <S.PayText>Address</S.PayText>
                  <Text>
                    {formValues.address1}
                    <br />
                    {formValues.address2}
                    <br />
                    {formValues.zipcode}
                  </Text>
                </RowBox>
              </Box>
            </S.ReviewBox>
          </ColorCard>
        )}

        <Box $maxWidth={600} $margin="32px auto 0" $tMargin="8px auto 0">
          <Button $width="100%" $type="b14" $height={44} onClick={()=>navigate('/myaccount/account')}>
            Go to My Account Page
          </Button>
          <Button
            mode="line"
            $color="gray900"
            $type="b14"
            $width="100%"
            $height={44}
            $margin="16px 0 0"
            $tMargin="8px 0 0"
            onClick={() => navigate('/')}
          >
            Go to Home
          </Button>
          <S.InfoList>
            {payInfoList.map((info, index) => (
              <ListItem key={`info_${index}`}>
                <RowBox $alignItems="center" $gap={8}>
                  <Icon name={info.icon} />
                  <Text $type="b18" $color="primary600">
                    {info.title}
                  </Text>
                </RowBox>
                <Text $marginTop={8} $color="gray100" $type="14">
                  {info.content}
                </Text>
              </ListItem>
            ))}
          </S.InfoList>
        </Box>


      </Container>
    </SubLayout>
  );
}
