import React from 'react';
import { Button, ColorCard, Img } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomH2, Text, Box, Span } from 'styles';

export function Complete() {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.state;

  return (
    <Container $padding="50px 20px" $tPadding="24px 16px 48px" $margin="auto">
      <CustomH2>
        {path === 'change' ? (
          <>
            Change <Span $display="none" $tDisplay="block" />
            Password
          </>
        ) : (
          <>
            Create <Span $display="none" $tDisplay="block" />
            Account
          </>
        )}
      </CustomH2>
      <ColorCard $margin="30px auto 20px" $tMargin="24px auto" $bgColor="cardBlue" $maxWidth={600}>
        <Box $padding="80px 100px" $tPadding="24px 16px">
          <Text $type="b38" $tType="b24" $color="blue100" $textAlign="center">
            Complete!
          </Text>
          <Img src="complete" $width="fit-content" $tWidth={180} $margin="auto" $tMargin="48px auto" />
          <Button $margin="48px 0 0" $width="100%" $type="b14" $height={44} onClick={() => navigate(`/login`)}>
            Go to Login
          </Button>
          <Button
            mode="line"
            $type="b14"
            $color="gray900"
            $width="100%"
            $height={44}
            $margin="16px 0 0"
            onClick={() => navigate('/')}
          >
            Go to Main
          </Button>
        </Box>
      </ColorCard>
    </Container>
  );
}
