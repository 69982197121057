import { useEffect, useState } from 'react';
import * as Imgs from 'assets/images';
import * as S from './ImgStyle';

export function Img({ src, alt, $imgWidth, ...props }) {
  const [tempSrc, setTempSrc] = useState('');

  useEffect(() => {
    if (src) {
      if (Imgs[src]) {
        setTempSrc(Imgs[src]);
      } else {
        setTempSrc(src);
      }
    }

    return () => setTempSrc('');
  }, [src]);

  return (
    <S.ImgContainer {...props}>
      <S.Image src={tempSrc} alt="images" $imgWidth={$imgWidth} />
    </S.ImgContainer>
  );
}
