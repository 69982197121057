import styled from 'styled-components';
import { Box } from 'styles';

export const Container = styled(Box)`
  background-color: white;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadow.second};
  padding: 24px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 16px;
  }
`;
