import { css } from 'styled-components';
import { checkPixel } from 'utils';

const apply = (prop, value) => prop && `${prop}: ${value}`;

export const flex = css`
  ${({ $flexWrap }) => $flexWrap && apply('flex-wrap', $flexWrap)};
  ${({ $flexDirection }) => $flexDirection && apply('flex-direction', $flexDirection)};
  ${({ $justifyContent }) => $justifyContent && apply('justify-content', $justifyContent)};
  ${({ $alignItems }) => $alignItems && apply('align-items', $alignItems)};
  ${({ $order }) => $order && apply('order', $order)};
  ${({ $gap }) => $gap && apply('gap', checkPixel($gap))};
  ${({ $rowGap }) => $rowGap && apply('row-gap', checkPixel($rowGap))};
  ${({ $colGap }) => $colGap && apply('column-gap', checkPixel($colGap))};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $tFlexWrap }) => $tFlexWrap && apply('flex-wrap', $tFlexWrap)};
    ${({ $tFlexDirection }) => $tFlexDirection && apply('flex-direction', $tFlexDirection)};
    ${({ $tJustifyContent }) => $tJustifyContent && apply('justify-content', $tJustifyContent)};
    ${({ $tAlignItems }) => $tAlignItems && apply('align-items', $tAlignItems)};
    ${({ $tOrder }) => $tOrder && apply('order', $tOrder)};
    ${({ $tGap }) => $tGap && apply('gap', checkPixel($tGap))};
    ${({ $tRowGap }) => $tRowGap && apply('row-gap', checkPixel($tRowGap))};
    ${({ $tColGap }) => $tColGap && apply('column-gap', checkPixel($tColGap))};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({ $mFlexWrap }) => $mFlexWrap && apply('flex-wrap', $mFlexWrap)};
    ${({ $mFlexDirection }) => $mFlexDirection && apply('flex-direction', $mFlexDirection)};
    ${({ $mJustifyContent }) => $mJustifyContent && apply('justify-content', $mJustifyContent)};
    ${({ $mAlignItems }) => $mAlignItems && apply('align-items', $mAlignItems)};
    ${({ $mOrder }) => $mOrder && apply('order', $mOrder)};
    ${({ $mGap }) => $mGap && apply('gap', checkPixel($mGap))};
    ${({ $mRowGap }) => $mRowGap && apply('row-gap', checkPixel($mRowGap))};
    ${({ $mColGap }) => $mColGap && apply('column-gap', checkPixel($mColGap))};
  }
`;
