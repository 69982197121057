import { find } from 'lodash';
import HttpRequest from '../HttpRequest';

const listCommonCodes = async (groupCode) => {
  const response = await HttpRequest.get('/api/code/list', {groupCode});

  return response;
}

const getCommonCodeFromArray = (commonCodes, code) => {
  const commonCode = find(commonCodes, (c) => c.commonCode === code);

  return commonCode || {};
}

const getCommonCodeName = (commonCodes, code) => getCommonCodeFromArray(commonCodes, code).commonCodeName;

export default {
  listCommonCodes,
  getCommonCodeFromArray,
  getCommonCodeName
}