import React from 'react';
import { Box } from 'styles';
import styled from 'styled-components';

export function ColorCard({ children, ...props }) {
  return (
    <Card {...props}>
      <CardLine $bgColor={props.$bgColor}></CardLine>
      {children}
    </Card>
  );
}

const Card = styled(Box)`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.cardLine};
`;

const CardLine = styled(Box)`
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    border-radius: 5px 5px 0 0;
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
  }
`;
