import styled, { css } from 'styled-components';
import { border, common, flex, grid, space, typography } from 'styles/css';

const commonCSS = css`
  ${common}
  ${border}
  ${space}
`;

// section

export const Section = styled.section`
  ${commonCSS}
`;

// div
export const Box = styled.div`
  ${commonCSS}
  ${flex}
`;

export const RowBox = styled(Box)`
  display: flex;
  ${flex}
`;

export const RowCenter = styled(RowBox)`
  align-items: center;
  justify-content: center;
`;

export const ColBox = styled(Box)`
  display: flex;
  flex-direction: column;
  ${flex}
`;

export const ColCenter = styled(ColBox)`
  align-items: center;
  justify-content: center;
`;

export const GridBox = styled(Box)`
  display: grid;
  ${grid}
`;

export const Container = styled(Box)`
  max-width: ${({ $type }) => ($type === 'sub' ? '1200px' : $type === 'our' ? '1048px' : '1040px')};

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 100%;
  }
`;

// ul , li

export const List = styled.ul`
  ${commonCSS}
`;

export const FlexList = styled(List)`
  display: flex;
  ${flex}
`;

export const GridList = styled(List)`
  display: grid;
  ${grid}
  ${flex}
`;

export const ListItem = styled.li`
  ${commonCSS}
`;

export const FlexListItem = styled(ListItem)`
  display: flex;
  ${flex}
`;

// typography

export const H2 = styled.h2`
  ${commonCSS}
  ${typography}
`;

export const H3 = styled.h3`
  ${commonCSS}
  ${typography}
`;

const headingStyle = css`
  font-size: 38px;
  line-height: 1.2;
  text-align: ${({ $start }) => ($start ? 'start' : 'center')};
  color: ${({ theme }) => theme.colors.primary600};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $tStart }) => $tStart && `text-align:start`};
  }
`;

export const CustomH2 = styled.h2`
  ${headingStyle}
`;

export const CustomH3 = styled.h3`
  ${headingStyle}
  ${commonCSS}
`;

export const H4 = styled.h4`
  ${commonCSS}
  ${typography}
`;

export const Text = styled.p`
  ${commonCSS}
  ${typography}
`;

export const Span = styled.span`
  ${commonCSS}
  ${typography}
`;

export const Link = styled.a`
  ${commonCSS}
  ${typography}
`;

export const InvalidText = styled.p`
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 100%;
  color: ${({ $color, theme }) => ($color && theme.colors[$color]) || 'initial'};
`;

export const Line = styled.span`
  width: 2px;
  height: 10px;
  margin: 6px 8px;
  background-color: ${({ theme }) => theme.colors.gray100};
`;

export const Label = styled.label`
  ${commonCSS}
  ${typography}
`;

export const CardTag = styled(Span)`
  color: white;
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: 2px;
  padding: 2px 4px;
  margin-right: 4px;
`;
