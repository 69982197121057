import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { menuList } from 'data';
import { hexToRGB } from 'utils';

import {
  Button,
  DropBox,
  Icon,
  Modal,
  SignupModal,
  SignupSendModal,
  SendModal,
  DeActivatedModal,
  DelModal,
  InfoModal,
  LoginModal,
  FortgotPwdModal
} from 'components';
import { Box, FlexList, H3, ListItem, RowBox } from 'styles';

import userAuthenticationStore from 'stores/AuthenticationStore';

import * as S from './HeaderStyle';
import AuthenticationService from "../../services/authentication/AuthenticationService";

function Header({ delOpen, setDelOpen, background, navigate, location, token }) {

  const isAuthenticated = userAuthenticationStore((state) => state.isAuthenticated);
  const userInfo = userAuthenticationStore((state) => state.userInfo);
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const [info, setInfo] = useState();
  const [modalOpen, setModalOpen] = useState();
  const [modalList, setModalList] = useState();
  const [signinUpRes, setSigninUpRes] = useState({
    id: '',
    verificationCode: ''
  });

  const {state} = useLocation();
  useEffect(() => {
    if(state && state.login){
      setModalOpen('login');
    }
  }, [state]);

  const signupGoogleSuccess = (data) => {
    setSigninUpRes(data);
    setModalOpen('signupSend');
  };

  return (
    <>
      <S.Header $bgColor={background || location.pathname === '/'}>
        <h1>seaStory700</h1>
        <S.Container>
          <Icon className="logo" name={background || location.pathname === '/' ? 'Logo' : 'LogoGray'} $padding="20px 25px" onClick={() => navigate('/')} />

          <Box $tDisplay="none">
            <RowBox $padding="8px 24px" $justifyContent="flex-end" $gap={8}>
              {isAuthenticated ? (
                <DropBox onClick={() => setDelOpen(true)} $background={background || location.pathname === '/'} />
              ) : (
                <Button
                  id="btn-header-login"
                  mode="text"
                  $type="14"
                  $color={hexToRGB(background || location.pathname === '/' ? '#ffffff' : '#5A6F7E', 0.5)}
                  $width={63}
                  $height={24}
                  onClick={() => setModalOpen('login')}
                  $marginRight={8}>
                  Login
                </Button>
              )}

              {!isAuthenticated && (
                <>
                  <Button mode="headerLine" $type="m11" $width={36} $height={24}>
                    EN
                  </Button>
                  <Button mode="headerLine" $type="m11" $width={36} $height={24}>
                    KO
                  </Button>
                </>
              )}
            </RowBox>
            <nav>
              <h2>menu</h2>
              <FlexList $height={60} $alignItems="center" $paddingRight={24}>
                {menuList.map((menu, index) => (
                  <ListItem
                    key={`header_menu_${menu.label}`}
                    onClick={(e) => {
                      if (menu.path !== 'disabled') {
                        navigate(`/${menu.path}`);
                      } else {
                        e.stopPropagation();
                      }
                    }}
                    $padding="15px 16px"
                    $cursor="pointer"
                    $marginLeft={index !== 0 && 16}
                    $borderBottom={{
                      color: location.pathname.includes(menu.path) ? 'primary500' : 'white',
                      opacity: location.pathname.includes(menu.path) ? 1 : 0,
                      width: 3
                    }}>
                    <H3 $color={background || location.pathname === '/' ? 'white' : 'gray800'} $type="b18">
                      {menu.label}
                    </H3>
                  </ListItem>
                ))}
                {!isAuthenticated && (
                  <ListItem $marginLeft={16}>
                    <Button $type="m12" $bgColor="blue100" $width={134} $height={36} onClick={() => setModalOpen('signup')}>
                      Create Account
                    </Button>
                  </ListItem>
                )}
              </FlexList>
            </nav>
          </Box>
        </S.Container>
      </S.Header>
      <Modal
        open={modalOpen}
        setOpen={() => setModalOpen()}
        $width={modalOpen === 'login' ? `460px` : '540px'}
        $color={modalOpen === 'deActivated' && 'error'}
        title={
          modalOpen === 'login'
            ? 'Login'
            : modalOpen === 'error'
              ? 'Not Found.'
              : modalOpen === 'deActivated'
                ? 'Account de-activated'
                : modalOpen === 'signup' || modalOpen === 'signupSend'
                  ? 'Create Account'
                  : 'Reset your password'
        }>
        {modalOpen === 'login' && <LoginModal setModalOpen={setModalOpen} />}
        {(modalOpen === 'forgot' || modalOpen === 'error') && <FortgotPwdModal setModalOpen={setModalOpen} />}
        {modalOpen === 'sendMail' && <SendModal setModalOpen={setModalOpen} />}
        {modalOpen === 'deActivated' && <DeActivatedModal setModalOpen={setModalOpen} />}
        {modalOpen === 'signup' && (
          <SignupModal setModalOpen={setModalOpen}
                       signupSuccess={signupGoogleSuccess}
                       setInfo={setInfo}
                       setModalLoginOpen={setModalOpen}
                       setModalList={setModalList} />
        )}
        {modalOpen === 'signupSend' && <SignupSendModal setModalOpen={setModalOpen} id={signinUpRes.id} verifyCode={signinUpRes.verificationCode} />}
      </Modal>
      <InfoModal type="info" open={info} setOpen={setInfo} list={modalList} />
      <DelModal open={delOpen} setOpen={setDelOpen} />
    </>
  );
}

export default Header;
