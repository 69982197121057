import styled from 'styled-components';
import { ListItem, RowBox } from 'styles';

export const BreadCrumbs = styled(RowBox)`
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  padding: 16px 0;
  ${({ $mobile }) => $mobile && `margin:0 8px`};
`;

export const TabListItem = styled(ListItem)`
  padding: 8.5px 16px;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background-color: ${({ $selected, theme }) => ($selected ? theme.colors.primary600 : 'transparent')};
  }

  @media ${({ theme }) => theme.device.tablet} {
    flex: 1;
  }
`;

export const MoRowBox = styled(RowBox)`
  padding: 24px 8px 16px;
  align-items: center;
  justify-content: space-between;
`;
