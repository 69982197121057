import React from 'react';
import * as Icons from 'assets/icons';
import * as S from './IconStyle';

export function Icon({ name, onClick, ...props }) {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <S.IconContainer onClick={onClick} {...props}>
      <IconComponent className="icon" />
    </S.IconContainer>
  );
}
