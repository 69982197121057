import { useGoogleLogin } from '@react-oauth/google';
import { Button } from 'components/button/Button';
import { Checkbox, FormikDropdown, FormikInput } from 'components/form';
import { Icon } from 'components/icon/Icon';
import { LineText } from 'components/LineText';
import { DotLoading } from 'components/loading/Loading';
import { infoList } from 'data';
import { useFormik } from 'formik';
import { useAlertContext } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCommonCodeStore from 'stores/common/CommonCodeStore';
import { Box, ColBox, RowBox, RowCenter, Span, Text } from 'styles';
import { getByteSize } from 'utils/getByteSize';
import { generateCheckPasswordValidationYup, generatePasswordValidationYup } from 'utils/validationUtils';
import * as yup from 'yup';

import { SIGNUP_ROUTE } from 'data/constants';
import AuthenticationService from 'services/authentication/AuthenticationService';

export function SignupModal({ setModalOpen, signupSuccess, setInfo, setModalList, setModalLoginOpen}) {
  const { alert } = useAlertContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [agree, setAgree] = useState(false);

  const commonCodes = useCommonCodeStore((state) => state.commonCodes);
  const getCommonCode = useCommonCodeStore((state) => state.getCommonCode);

  const signupWithGoogle = async (googleUserInfo, googleToken) => {
    let response = null;

    setIsGoogleLoading(true);

    try {
      response = await AuthenticationService.signupWithGoogle({
        googleId: googleUserInfo.id,
        googleEmail: googleUserInfo.email,
        googleName: googleUserInfo.name,
        googleCredential: googleToken
      });
    } catch (e) {
      console.error(e);
    }

    setIsGoogleLoading(false);

    if (response && response.status === 200) {
      signupSuccess({ id: googleUserInfo.email, verifyCode: response.verificationCode });
    } else if (response && response.message) {
      alert({ message: response.message });
    } else {
      alert({ message: t('common.error.server') });
    }
  };

  const googleSignin = useGoogleLogin({
    onSuccess: (response) => {
      const getGoogleInfo = async (tokenResponse) => {
        try {
          const userInfoResponse = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json'
            }
          });

          const jsonResponse = await userInfoResponse.json();

          if (jsonResponse && jsonResponse) {
            signupWithGoogle(jsonResponse, tokenResponse.access_token);
          } else {
            alert({ message: t('signup.message.cannot.log.in.with.google') });
          }
        } catch (e) {
          alert({ message: t('signup.message.cannot.log.in.with.google') });
        }
      };

      getGoogleInfo(response);
    },
    onError: (respone) => {
      alert({ message: t('signup.message.cannot.log.in.with.google') });
    }
  });

  const handleOnSubmit = async (values) => {
    let response = null;

    if (!agree) {
      alert({ message: t('agree.terms.and.privacy') });
      return;
    }

    setIsEmailLoading(true);

    try {
      response = await AuthenticationService.signup({
        email: values.id,
        signupRoute: SIGNUP_ROUTE.email,
        ...values
      });
    } catch (e) {
      console.error(e);
    }

    setIsEmailLoading(false);

    if (response && response.status === 200) {
      signupSuccess({ id: values.id, verifyCode: response.verificationCode });
    } else if (response && response.message) {
      alert({ message: response.message });
    } else {
      alert({ message: t('common.error.server') });
    }
  };

  const checkEmailAvailable = async (id, resolve, reject) => {
    let response = null;

    try {
      response = await AuthenticationService.checkEmail(id);
    } catch (e) {
      console.error(e);
    }

    resolve(response && response.available === true);
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      lastName: '',
      password: '',
      checkPassword: '',
      usePlan: '',
      userStyle: '',
      industryType: '',
      industry: '',
      department: '',
      departmentType: '',
      company: '',
      vesselType: '',
      vessel: ''
    },
    validationSchema: yup.object().shape({
      id: yup
        .string()
        .email(t('validation.valid.email.address'))
        .required(t('validation.required.email.address'))
        .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100)
        .test(
          'checkEmailAvailable',
          t('message.email.unavailable'),
          (value) =>
            new Promise((resolve, reject) => {
              checkEmailAvailable(value, resolve, reject);
            })
        ),
      password: generatePasswordValidationYup(t('validation.valid.password')),
      checkPassword: generateCheckPasswordValidationYup('password', t('validation.password.check.same')),
      name: yup
        .string()
        .required(t('validation.required.name'))
        .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
      lastName: yup
        .string()
        .required(t('validation.required.name'))
        .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
      usePlan: yup.string().required(t('validation.required.entry')),
      vesselType: yup.string().required(t('validation.required.entry')),
      vessel: yup.string().when('vesselType', {
        is: (value) => value === 'VT99',
        then: (schema) => schema.required(t('validation.required.entry'))
      }),

      //
      userStyle: yup.string().when('usePlan', {
        is: (value) => {
          const isTrue = value === 'UP01';
          return isTrue;
        },
        then: (schema) => schema.required(t('validation.required.entry'))
      }),

      //
      industryType: yup.string().when('usePlan', {
        is: (value) => {
          const isTrue = value === 'UP02';
          return isTrue;
        },
        then: (schema) => schema.required(t('validation.required.entry'))
      }),
      industry: yup.string().when(['usePlan', 'industryType'], {
        is: (usePlan, industryType) => {
          const isTrue = usePlan === 'UP02' && industryType === 'IT99';
          return isTrue;
        },
        then: (schema) =>
          schema.required(t('validation.required.entry')).test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100)
      }),
      departmentType: yup.string().when('usePlan', {
        is: (value) => {
          const isTrue = value === 'UP02';
          return isTrue;
        },
        then: (schema) => schema.required(t('validation.required.entry'))
      }),
      department: yup.string().when(['usePlan', 'departmentType'], {
        is: (usePlan, departmentType) => {
          const isTrue = usePlan === 'UP02' && departmentType === 'DT99';
          return isTrue;
        },
        then: (schema) =>
          schema.required(t('validation.required.entry')).test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100)
      }),
      company: yup.string().when('usePlan', {
        is: (value) => {
          const isTrue = value === 'UP02';
          return isTrue;
        },
        then: (schema) =>
          schema.required(t('validation.required.entry')).test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100)
      })
    }),
    onSubmit: handleOnSubmit
  });

  useEffect(() => {
    getCommonCode('UP');
    getCommonCode('UT');
    getCommonCode('IT');
    getCommonCode('DT');
    getCommonCode('VT');
  }, []);

  return (
    <>
      <ColBox $gap={32} $tGap={24}>
        <RowCenter $cursor="pointer" $height={44} $borderRadius={5} $border={{ width: '2px', color: 'black19' }} onClick={() => googleSignin()}>
          {!isGoogleLoading && <Icon name="SignupGoogle" />}
          {isGoogleLoading && (
            <Box $position="relative">
              <DotLoading $dotColor="primary" />
            </Box>
          )}
        </RowCenter>
        <LineText>or</LineText>
        <form onSubmit={formik.handleSubmit}>
          <ColBox $gap={32} $tGap={24}>
            <FormikInput
              $tWidth="100%"
              label="email"
              name="id"
              htmlFor="id"
              value={formik.values.id}
              invalid={formik.errors.id}
              touched={formik.touched.id}
              onChange={(_name, value) => {
                formik.setFieldValue('id', value);
              }}
              onBlur={formik.handleBlur}
              $require
            />
            <RowBox $gap={16}>
              <FormikInput
                $flex={5}
                label="Last Name"
                name="lastName"
                htmlFor="lastName"
                value={formik.values.lastName}
                invalid={formik.errors.lastName}
                touched={formik.touched.lastName}
                onChange={(_name, value) => {
                  formik.setFieldValue('lastName', value);
                }}
                onBlur={formik.handleBlur}
                $require
              />
              <FormikInput
                $flex={5}
                label="First Name"
                name="name"
                htmlFor="name"
                value={formik.values.name}
                invalid={formik.errors.name}
                touched={formik.touched.name}
                onChange={(_name, value) => {
                  formik.setFieldValue('name', value);
                }}
                onBlur={formik.handleBlur}
                $require
              />
            </RowBox>
            <div>
              <FormikInput
                $flex={5}
                type="password"
                label="Password"
                name="password"
                htmlFor="password"
                value={formik.values.password}
                invalid={formik.errors.password}
                touched={formik.touched.password}
                onChange={(_name, value) => {
                  formik.setFieldValue('password', value);
                }}
                onBlur={formik.handleBlur}
                $require
              />
              <Text $type="m12" $color="primary600" $textAlign="right">
                8 or more characters, Upper & lowercase letters, At least on number
              </Text>
            </div>

            <FormikInput
              $flex={5}
              type="password"
              label="confirm Password"
              name="checkPassword"
              htmlFor="checkPassword"
              value={formik.values.checkPassword}
              invalid={formik.errors.checkPassword}
              touched={formik.touched.checkPassword}
              onChange={(_name, value) => {
                formik.setFieldValue('checkPassword', value);
              }}
              onBlur={formik.handleBlur}
              $require
            />
            <FormikDropdown
              label="Plan to use"
              placeholder={t('signup.select.placeholder')}
              name="usePlan"
              onChange={(_name, value) => {
                formik.setFieldValue('usePlan', value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.usePlan}
              invalid={formik.errors.usePlan}
              touched={formik.touched.usePlan}
              list={commonCodes['UP']}
              textField="commonCodeName"
              valueField="commonCode"
              $require
            />
            {formik.values.usePlan === 'UP01' && (
              <FormikDropdown
                label="Which Of The Following Describes You Best?"
                placeholder={t('signup.select.placeholder')}
                name="userStyle"
                onChange={(_name, value) => {
                  formik.setFieldValue('userStyle', value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.userStyle}
                invalid={formik.errors.userStyle}
                touched={formik.touched.userStyle}
                list={commonCodes['UT']}
                textField="commonCodeName"
                valueField="commonCode"
                $require
              />
            )}

            {formik.values.usePlan === 'UP02' && (
              <>
                <RowBox $gap={16}>
                  <FormikDropdown
                    label="Industry"
                    placeholder={t('signup.select.placeholder')}
                    name="industryType"
                    onChange={(_name, value) => {
                      formik.setFieldValue('industryType', value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.industryType}
                    invalid={formik.errors.industryType}
                    touched={formik.touched.industryType}
                    $flex={5}
                    list={commonCodes['IT']}
                    textField="commonCodeName"
                    valueField="commonCode"
                    $require
                  />
                  <FormikDropdown
                    label="Department"
                    placeholder={t('signup.select.placeholder')}
                    name="departmentType"
                    onChange={(_name, value) => {
                      formik.setFieldValue('departmentType', value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.departmentType}
                    invalid={formik.errors.departmentType}
                    touched={formik.touched.departmentType}
                    $flex={5}
                    list={commonCodes['DT']}
                    textField="commonCodeName"
                    valueField="commonCode"
                    $require
                  />
                </RowBox>
                {formik.values.industryType === 'IT99' && (
                  <FormikInput
                    label="Other Industry"
                    name="industry"
                    htmlFor="industry"
                    value={formik.values.industry}
                    invalid={formik.errors.industry}
                    touched={formik.touched.industry}
                    onChange={(_name, value) => {
                      formik.setFieldValue('industry', value);
                    }}
                    onBlur={formik.handleBlur}
                    $require
                  />
                )}
                {formik.values.departmentType === 'DT99' && (
                  <FormikInput
                    label="Other Department"
                    name="department"
                    htmlFor="department"
                    value={formik.values.department}
                    invalid={formik.errors.department}
                    touched={formik.touched.department}
                    onChange={(_name, value) => {
                      formik.setFieldValue('department', value);
                    }}
                    onBlur={formik.handleBlur}
                    $require
                  />
                )}
                <FormikInput
                  label="Company"
                  name="company"
                  htmlFor="company"
                  value={formik.values.company}
                  invalid={formik.errors.company}
                  touched={formik.touched.company}
                  onChange={(_name, value) => {
                    formik.setFieldValue('company', value);
                  }}
                  onBlur={formik.handleBlur}
                  $require
                />
              </>
            )}

            <FormikDropdown
              label="Vessels Type"
              placeholder={t('signup.select.placeholder')}
              name="vesselType"
              onChange={(_name, value) => {
                formik.setFieldValue('vesselType', value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.vesselType}
              invalid={formik.errors.vesselType}
              touched={formik.touched.vesselType}
              list={commonCodes['VT']}
              textField="commonCodeName"
              valueField="commonCode"
              $require
            />
            {formik.values.vesselType === 'VT99' && (
              <FormikInput
                label="Other Vessel Type"
                name="vessel"
                htmlFor="vessel"
                value={formik.values.vessel}
                invalid={formik.errors.vessel}
                touched={formik.touched.vessel}
                onChange={(_name, value) => {
                  formik.setFieldValue('vessel', value);
                }}
                onBlur={formik.handleBlur}
                $require
              />
            )}

            <Checkbox
              name="agree"
              checked={agree}
              onChange={(_name, value) => {
                setAgree(value);
              }}>
              <Text $type="m14">
                I agree&nbsp;
                <Span
                  $type="m14"
                  $color="blue100"
                  onClick={(e) => {
                    e.preventDefault();
                    setInfo('Terms & Conditions');
                    setModalList(infoList[0]);
                  }}>
                  Terms of use
                </Span>
                &nbsp;and&nbsp;
                <Span
                  $type="m14"
                  $color="blue100"
                  onClick={(e) => {
                    e.preventDefault();
                    setInfo('Privacy Policy');
                    setModalList(infoList[2]);
                  }}>
                  Privacy Policy
                </Span>
                <Span $type="m14" $lineHeight="14px !important" $tLineHeight="18px !important" $color="alert">
                  *
                </Span>
              </Text>
            </Checkbox>
            <ColBox $gap={16}>
              <Button
                $width="100%"
                $height={44}
                loading={isEmailLoading}
                onClick={() => {
                  formik.submitForm();
                }}>
                Create Account
              </Button>
              <Button mode="line" $width="100%" $height={44} onClick={() => setModalOpen('')}>
                Cancel
              </Button>
            </ColBox>
          </ColBox>
        </form>

        <Text $color="gray800" $type="14" $textAlign="center">
          Already have an account?&nbsp;
          <Span
            $type="14"
            $color="blue100"
            $cursor="pointer"
            onClick={() => {
              setModalOpen('');
              setModalLoginOpen('login');
            }}>
            Log in
          </Span>
        </Text>
      </ColBox>
    </>
  );
}

export default SignupModal;
