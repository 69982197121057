import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useOutside } from 'hooks';
import { Box, ListItem, InvalidText } from 'styles';
import { Icon } from 'components/icon/Icon';
import { InputLabel } from './inputLabel/InputLabel';

export function Dropdown({
  path,
  label,
  htmlFor,
  list,
  disabled,
  name,
  placeholder,
  value,
  onChange,
  $flex,
  $require,
  $invalid,
  textField,
  valueField,
  invalidText,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();

  const handleOnChange = (item) => {
    onChange(name, item[valueField]);
    setOpen(false);
  };

  useOutside([dropdownRef], () => {
    setOpen(false);
  });

  return (
    <Box $label={label} $flex={$flex}>
      {label && <InputLabel path={path} label={label} htmlFor={htmlFor} require={$require}></InputLabel>}
      <Container ref={dropdownRef} $flex={$flex} {...props}>
        <label htmlFor={`drop_${name}`}>
          <input id={`drop_${name}`} className="hidden-input" readOnly disabled={disabled} />
          <DropBox open={open} value={value} disabled={disabled} onClick={() => !disabled && setOpen(!open)} $invalid={$invalid}>
            <SelectText>{(list && list.find((item) => String(item[valueField]) === String(value))?.[textField]) || placeholder}</SelectText>
            <Icon name="ArrowDrop" $transform={`rotate(${open ? `-180deg` : `0deg`})`} />
          </DropBox>
        </label>
        {$invalid === 'error' && <InvalidText $color="error">{invalidText ?? 'invalid.text'}</InvalidText>}
        {/*  list */}
        <List open={open}>
          {list &&
            list.map((item, index) => (
              <DropListItem key={`item_${index}`} selected={(item[valueField] || item) === value} onClick={() => handleOnChange(item)}>
                <p>{item[textField]}</p>
              </DropListItem>
            ))}
        </List>
      </Container>
    </Box>
  );
}

export const Text = styled.p`
  font-size: 14px;
  line-height: 1;
`;

const Container = styled(Box)`
  position: relative;
  ${({ $flex }) => $flex && `flex=${$flex}`};

  & > label > .hidden-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0px;
  }
`;

const DropBox = styled(Box)`
  display: flex;
  padding: 8px 16px;
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  height: ${({ $height }) => ($height ? `${$height}px` : '45px')};
  border-radius: 8px;
  gap: 10px;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
  border: 2px solid
    ${({ $invalid, theme }) => ($invalid === 'success' ? theme.colors.success : $invalid === 'error' && theme.colors.errorLine) || theme.colors.gray50};
  box-shadow: 0 0 0 3px
    ${({ $invalid, theme }) => ($invalid === 'success' ? theme.colors.successLine : $invalid === 'error' && theme.colors.errorLine) || 'transparent'};

  ${({ open, theme }) =>
    open &&
    `
  box-shadow: 0 0 0 3px ${theme.colors.inputLine};
  border-color:${theme.colors.blue100}
  `};
  color: ${({ value, theme }) => (value && theme.colors.gray900) || theme.colors.placeholder};

  ${({ $invalid, theme }) => $invalid === 'error' && `border-color:${theme.colors.error}`};
`;

const List = styled.ul`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  gap: 2px;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  max-height: 142px;
  background-color: white;
  border-radius: 5px;
  padding: 4px;
  position: absolute;
  z-index: 1;

  width: 100%;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.inputLine};

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray300};
    border-radius: 10px;
  }
`;

const DropListItem = styled(ListItem)`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 5px;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.blue100 : '#ffffff')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue100};
    & > p {
      color: white;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      p {
        color: white;
      }
    `};
`;

const SelectText = styled.p`
  font-size: 14px;
`
