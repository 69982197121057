import { css } from 'styled-components';
import { flex } from './flex';

const apply = (grid, fr) => grid && `grid-template-columns: repeat(${grid}, ${fr || '1fr'})`;

export const grid = css`
  ${flex}
  ${({ $grid, $fr }) => $grid && apply($grid, $fr)};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $tGrid, $tFr }) => $tGrid && apply($tGrid, $tFr)};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({ $mGrid, $mFr }) => $mGrid && apply($mGrid, $mFr)};
  }

  @media ${({ theme }) => theme.device.sMobile} {
    ${({ $smGrid, $smFr }) => $smGrid && apply($smGrid, $smFr)};
  }
`;
