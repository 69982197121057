import React, { useState, useEffect, useRef } from 'react';
import { Box, ListItem, Text } from 'styles';
import { hexToRGB } from 'utils';
import { Icon } from 'components/icon/Icon';
import { useNavigate } from 'react-router-dom';

import userAuthenticationStore from 'stores/AuthenticationStore';
import AuthenticationService from 'services/authentication/AuthenticationService';

import * as S from './DropBoxStyle';

export function DropBox({ $background, onClick }) {
  const userInfo = userAuthenticationStore((state) => state.userInfo);
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  const navigate = useNavigate();
  const dropBoxRef = useRef();
  const [listOpen, setListOpen] = useState(false);

  const logout = async () => {
    const logoutResponse = await AuthenticationService.logout(userInfo.id);

    if (logoutResponse && logoutResponse.status === 200) {
      setAuthenticated(false);
      setUserInfo(null);
      navigate('/', {
        replace: true,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropBoxRef.current && !dropBoxRef.current.contains(event.target)) {
        setListOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box $position="relative" ref={dropBoxRef}>
      <S.DropBox $background={$background} $listOpen={listOpen} onClick={() => setListOpen(!listOpen)}>
        <Text $type="m12" $color={hexToRGB($background ? '#ffffff' : '#5A6F7E', 0.5)}>
          {userInfo ? userInfo.id : ''}
        </Text>
        <Icon
          name="ArrowAccount"
          $pathFill={$background ? '#ffffff' : '#5A6F7E'}
          $transform={listOpen && `rotate(-180deg)`}
        />
      </S.DropBox>
      {listOpen && (
        <S.DropList>
          <ListItem
            $cursor="pointer"
            onClick={() => {
              setListOpen(false);
              navigate(`/myaccount/account`);
            }}
          >
            <Text>My account</Text>
          </ListItem>
          <ListItem $cursor="pointer" onClick={() => {
            logout();
          }}>
            <Text $color="alert">Logout</Text>
          </ListItem>
        </S.DropList>
      )}
    </Box>
  );
}
