export const filterList = ['All', 'category1', 'category2', 'category3'];

export const faqList = [
  { id: 1, subjectNo: 'category1', question: 'question1', answer: `answer\nanswer\nanswer\nanswer\nanswer\nanswer` },
  { id: 2, subjectNo: 'category2', question: 'question1', answer: `answer\nanswer` },
  { id: 3, subjectNo: 'category1', question: 'question1', answer: `answer\nanswer\nanswer\nanswer\nanswer\nanswer` },
  { id: 4, subjectNo: 'category3', question: 'question1', answer: `answer\nanswer` },
  { id: 5, subjectNo: 'category3', question: 'question1', answer: `answer\nanswer\nanswer\nanswer\nanswer\nanswer` },
  { id: 6, subjectNo: 'category2', question: 'question1', answer: `answer\nanswer` },
  { id: 7, subjectNo: 'category1', question: 'question1', answer: `answer\nanswer` },
  { id: 8, subjectNo: 'category3', question: 'question1', answer: `answer\nanswer` },
  { id: 9, subjectNo: 'category1', question: 'question1', answer: `answer\nanswer\nanswer\nanswer\nanswer\nanswer` },
  { id: 10, subjectNo: 'category2', question: 'question1', answer: `answer\nanswer` },
];
