import { GoogleOAuthProvider } from '@react-oauth/google';
import { ScrollToTop } from 'components';
import { Complete, Failed } from 'components/auth';
import { Layout } from 'layouts';
import {
  AccountChangePwd,
  AccountOutlet,
  ChangePw,
  Faq,
  ForgotPwd,
  ForgotPwdRecheck,
  Login,
  Main,
  Management,
  MyAccount,
  EditAccount,
  Payment,
  PaymentSuccess,
  PaymentFail,
  Pricing,
  Product,
  Qna,
  Signup,
  SignupEmail,
  SignupRecheck,
  SignupVerification,
  SupportOutlet,
} from 'pages';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from 'styles';
import { AlertContextProvider } from 'hooks';

import AuthenticationService from 'services/authentication/AuthenticationService';
import userAuthenticationStore from 'stores/AuthenticationStore';

import ProtectedPageWrapper from 'pages/ProtectedPageWrapper';
import i18n from './i18n/i18n';

function App(props) {
  const isAuthenticated = userAuthenticationStore((state) => state.isAuthenticated);
  const setAuthenticated = userAuthenticationStore((state) => state.setAuthenticated);
  const setUserInfo = userAuthenticationStore((state) => state.setUserInfo);

  React.useEffect(() => {
    async function fetchMeData() {
      const userInfo = await AuthenticationService.getMyInfo();

      if (userInfo && userInfo.id) {
        setAuthenticated(true);
        setUserInfo(userInfo);
      }
    }

    if (!isAuthenticated) {
      fetchMeData();
    }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_AUTH_GOOGLE_CLIENTID}>
        <ThemeProvider theme={theme}>
          <AlertContextProvider>
            <GlobalStyle />
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                <Route element={<Layout />}>
                  <Route path="/" element={<Main />} />
                  <Route path="/changepassword" element={<ChangePw />} />
                  <Route path="/signup/verify" element={<SignupVerification />} />
                  <Route path="/complete" element={<Complete />} />
                  <Route path="/failed" element={<Failed />} />
                  <Route path="/products" element={<Product />} />
                  <Route path="/pricing" element={
                      <ProtectedPageWrapper>
                        <Pricing />
                      </ProtectedPageWrapper>
                    }
                  />
                  <Route path="/pricing/payment"
                     element={
                       <ProtectedPageWrapper>
                        <Payment />
                       </ProtectedPageWrapper>
                     }
                  />
                  <Route path="/payment/success" element={<PaymentSuccess />} />
                  <Route path="/payment/fail" element={<PaymentFail />} />
                  <Route path="/support" element={<SupportOutlet />}>
                    <Route path="faq" element={<Faq />} />
                    <Route path="qna" element={<Qna />} />
                    <Route path="contact" element={<Qna />} />
                  </Route>
                  <Route
                    path="/myaccount"
                    element={
                      <ProtectedPageWrapper>
                        <AccountOutlet />
                      </ProtectedPageWrapper>
                    }
                  >
                    <Route
                      path="account"
                      element={
                        <ProtectedPageWrapper>
                          <MyAccount />
                        </ProtectedPageWrapper>
                      }
                    />
                    <Route
                      path="modify"
                      element={
                        <ProtectedPageWrapper>
                          <EditAccount />
                        </ProtectedPageWrapper>
                      }
                    />
                    <Route
                      path="management"
                      element={
                        <ProtectedPageWrapper>
                          <Management />
                        </ProtectedPageWrapper>
                      }
                    />
                    <Route
                      path="changepassword"
                      element={
                        <ProtectedPageWrapper>
                          <AccountChangePwd />
                        </ProtectedPageWrapper>
                      }
                    />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </AlertContextProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </I18nextProvider>
  );
}

export default App;
