import styled from 'styled-components';
import { Box, RowBox, slideDown } from 'styles';

export const AlertContainer = styled(Box)`
  background-color: white;
  border-radius: 8px;
  min-width: 280px;
  width: 516px;
  padding: 16px;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  @media ${({ theme }) => theme.device.tablet} {
    width: 90%;
    min-width: unset;
  }
`;

export const TitleBox = styled(RowBox)`
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
`;

export const ContentBox = styled(Box)`
  margin-top: 24px;
  max-height: 80vh;
  overflow: auto;
  padding: 0 16px 16px;

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    max-height: calc(80vh - 140px);
    margin-top: 0;
    padding: 24px;
  }
`;
