import React from 'react';
import styled from 'styled-components';

export function Radio({ name, value, onChange, children, checked, ariaLabel, disabled }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  return (
    <Label htmlFor={`${name}_${value}`} disabled={disabled}>
      <InputRadio
        id={`${name}_${value}`}
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        aria-label={ariaLabel}
        disabled={disabled}
      />
      {children}
    </Label>
  );
}

const Label = styled.label`
  width: fit-content;
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  ${({ disabled }) => disabled && `cursor: not-allowed`};
  color: ${({ disabled }) => (disabled ? 'rgba(36, 36, 36, 0.2)' : 'inherit')};
`;

const InputRadio = styled.input.attrs({ type: 'radio' })`
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid ${({ theme }) => theme.colors.gray300};
  border-radius: 50%;
  position: relative;

  &:checked {
    border-color: ${({ theme }) => theme.colors.blue100};
    background-color: white;

    &::before {
      content: '';
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) => theme.colors.blue100};
      transform: translate(-50%, -50%);
    }
  }
`;
