import { Button, ColorCard, FormikInput, Alert } from 'components';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/authentication/AuthenticationService';
import { Box, Container, CustomH2, Span } from 'styles';
import { generatePasswordPatentValidationYup } from 'utils';
import * as yup from 'yup';

const PASSWORD_CHANGE_DELAY = 1000;

export function AccountChangePwd(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const timer = useRef(null);

  const [currentPassword, setCurrentPassword] = useState('');
  const [alert, setAlert] = useState(false);

  const checkPwd = (password) =>
    new Promise((resolve, _reject) => {
      clearTimeout(timer.current);
      timer.current = setTimeout(async () => {
        try {
          const response = await AuthenticationService.checkPwd({ password });
          if (!response) resolve(false);

          setCurrentPassword(password);
          resolve(response.valid);
        } catch (error) {
          console.error(error);
          resolve(false);
        }
      }, PASSWORD_CHANGE_DELAY);
    });

  const changedPassword = async (form) => {
    try {
      const response = await AuthenticationService.changePassword(form.currentPassword, form.newPassword);

      if (!response) return;

      setAlert({ content: 'Your password has been successfully changed.', back: true });
      formik.resetForm();
    } catch (error) {
      console.error(error);
      setAlert({ content: t('common.error.server') });
    }
  };

  const schema = yup.object().shape({
    currentPassword: yup
      .string()
      .required('Please enter your current password.')
      .test('checkPwd', 'The current password is incorrect.', async (value) => {
        if (currentPassword === value) return;

        if (value) {
          const isValid = await checkPwd(value);
          return isValid;
        }
        return false;
      }),
    newPassword: yup
      .string()
      .concat(generatePasswordPatentValidationYup(t('validation.valid.password')))
      .required('Please enter your new password.'),
    confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords do not match.'),
  });

  const handleOnSubmit = (values) => {
    changedPassword(values);
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });

  return (
    <>
      <Container $padding="50px 20px" $tPadding="24px 16px 48px" $margin="auto">
        <CustomH2>
          Change <Span $display="none" $tDisplay="block" />
          Password
        </CustomH2>
        <ColorCard $margin="30px auto 20px" $tMargin="24px auto" $bgColor="cardBlue" $maxWidth={600}>
          <Box $padding="80px 100px" $tPadding="24px 16px">
            <Box $paddingBottom={24} $marginBottom={24} $borderBottom={{ color: 'gray500' }}>
              <FormikInput
                type="password"
                label="Current Password"
                htmlFor="currentPassword"
                name="currentPassword"
                placeholder="Password"
                value={formik.values.currentPassword}
                invalid={formik.errors.currentPassword}
                touched={formik.touched.currentPassword}
                onChange={(_name, value) => formik.setFieldValue('currentPassword', value, false)}
                onBlur={formik.handleBlur}
                $require
              />
            </Box>
            <FormikInput
              $marginTop={24}
              type="password"
              label="New Password"
              htmlFor="newPassword"
              name="newPassword"
              placeholder="New Password"
              value={formik.values.newPassword}
              invalid={formik.errors.newPassword}
              touched={formik.touched.newPassword}
              onChange={(_name, value) => formik.setFieldValue('newPassword', value, false)}
              onBlur={formik.handleBlur}
              $require
            />
            <FormikInput
              $marginTop={24}
              type="password"
              label="Confirm New Password"
              htmlFor="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm New Password"
              value={formik.values.confirmPassword}
              invalid={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
              onChange={(_name, value) => formik.setFieldValue('confirmPassword', value, false)}
              onBlur={formik.handleBlur}
              $require
            />
            <Button $margin="48px 0 0" $width="100%" $type="b14" $height={44} onClick={() => formik.submitForm()}>
              Submit
            </Button>
            <Button
              mode="line"
              $type="b14"
              $width="100%"
              $height={44}
              $margin="16px 0 0"
              onClick={() => {
                if (location.state) {
                  navigate(-1);
                } else {
                  navigate('/');
                }
              }}
            >
              Cancel
            </Button>
          </Box>
        </ColorCard>
      </Container>
      {alert && (
        <Alert
          alert={alert}
          setAlert={setAlert}
          content={alert.content}
          onClick={() => {
            setAlert(null);

            if (alert.back) {
              navigate(-1);
            }
          }}
        />
      )}
    </>
  );
}
