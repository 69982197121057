import { css } from 'styled-components';
import { theme } from 'styles/Theme';

const line = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
const getType = ($type) => {
  const fontType = {
    l: 300,
    r: 400,
    m: 500,
    sb: 600,
    b: 700,
    xb: 800,
    bl: 900,
  };

  if (!$type) $type = 'r';

  let typeToMatch = $type;
  if ($type.startsWith('btn')) {
    typeToMatch = $type.slice(3);
  }

  let match = typeToMatch.match(/^([a-z]*)(\d+)?$/i);

  if (!match) {
    match = ['r', 'r', '16'];
  }

  if (!match[1] && match[2]) {
    match = ['r', 'r', match[2]];
  }

  const fontWeight = fontType[match[1]] || 400;
  const fontSize = match[2] ? Number(match[2]) : 16;
  const lineHeight = $type.startsWith('btn') ? 1 : fontSize >= 38 ? 1.2 : 1.5;

  return `font-weight: ${fontWeight}; font-size: ${fontSize}px; line-height: ${lineHeight};`;
};

const apply = (prop, value) => prop && `${prop}: ${value}`;

export const typography = css`
  ${({ $type }) => $type && getType($type)}
  ${({ $color }) => $color && apply('color', theme.colors[$color] || $color)};
  ${({ $wordBreak }) => $wordBreak && apply('word-break', $wordBreak)};
  ${({ $whiteSpace }) => $whiteSpace && apply('white-space', $whiteSpace)};
  ${({ $textAlign }) => $textAlign && apply('text-align', $textAlign)};
  ${({ $maxLine }) => $maxLine && `${line} -webkit-line-clamp: ${$maxLine}`};
  ${({ $textDecoration }) => $textDecoration && apply('text-decoration', $textDecoration)};
  ${({ $decoColor }) => $decoColor && apply('text-decoration-color', $decoColor)};
  ${({ $letter }) => $letter && apply('letter-spacing', '-0.2px')};

  ${({ $type }) => !$type && getType('r')} // Add this line to ensure default type

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $tType }) => $tType && getType($tType)}
    ${({ $tColor }) => $tColor && apply('color', theme.colors[$tColor] || $tColor)};
    ${({ $tLineHeight }) => $tLineHeight && apply('line-height', $tLineHeight)};
    ${({ $tWordBreak }) => $tWordBreak && apply('word-break', $tWordBreak)};
    ${({ $tWhiteSpace }) => $tWhiteSpace && apply('white-space', $tWhiteSpace)};
    ${({ $tTextAlign }) => $tTextAlign && apply('text-align', $tTextAlign)};
    ${({ $tMaxLine }) => $tMaxLine && `${line} -webkit-line-clamp: ${$tMaxLine}`};
    ${({ $tTextDecoration }) => $tTextDecoration && apply('text-decoration', $tTextDecoration)};
    ${({ $tLetterSpacing }) => $tLetterSpacing && apply('letter-spacing', $tLetterSpacing)};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({ $mType }) => $mType && getType($mType)}
    ${({ $mColor }) => $mColor && apply('color', theme.colors[$mColor] || $mColor)};
    ${({ $mWordBreak }) => $mWordBreak && apply('word-break', $mWordBreak)};
    ${({ $mWhiteSpace }) => $mWhiteSpace && apply('white-space', $mWhiteSpace)};
    ${({ $mTextAlign }) => $mTextAlign && apply('text-align', $mTextAlign)};
    ${({ $mMaxLine }) => $mMaxLine && `${line} -webkit-line-clamp: ${$mMaxLine}`};
    ${({ $mTextDecoration }) => $mTextDecoration && apply('text-decoration', $mTextDecoration)};
    ${({ $mLetterSpacing }) => $mLetterSpacing && apply('letter-spacing', $mLetterSpacing)};
  }
`;
