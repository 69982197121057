import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing:border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Pretendard";
  font-size: 16px;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  background-color: white;
  letter-spacing:-0.2px;
}

nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

a {
  display: inline-block;
  text-decoration: none;
  color: initial;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, textarea, button {
  outline: none;
  font-family: inherit; 
}

button {
  line-height: 1;
  background-color: inherit;
}

textarea{
  resize:none;
}

`;
