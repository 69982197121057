import styled from 'styled-components';

export const Textarea = styled.textarea`
  box-sizing: border-box;
  min-height: 137px;
  width: 100%;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  border: 2px solid
    ${({ $invalid, theme }) =>
      ($invalid === 'success' ? theme.colors.success : $invalid === 'error' && theme.colors.errorLine) ||
      theme.colors.gray50};
  border-radius: 10px;
  ${({ $invalid, theme }) => $invalid === 'error' && `border-color:${theme.colors.error}`};
  padding: 12px 16px;
  transition: background-image 150ms;

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue100};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.inputLine};
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.darkGray};
  }

  @media ${({ theme }) => theme.device.mobile} {
  }
`;

export const Text = styled.p`
  font-size: 14px;
  text-align: right;
  padding: 10px 10px 0;
  color: ${({ theme }) => theme.colors.placeholder};
`;
