import React from "react";

export function isEmptyString(str) {
  if (str === undefined || str === null) return true;

  if (typeof str === 'string') {
    str = str.trim();
    return str === '';
  } else {
    return false;
  }
}

export function isNotEmptyString(str) {
  return !isEmptyString(str);
}

export function getFileSizeString(size) {
  let string;
  if (size >= (1000 * 1000 * 1000 * 1000) / 10) {
    size /= (1000 * 1000 * 1000 * 1000) / 10;
    string = 'TB';
  } else if (size >= (1000 * 1000 * 1000) / 10) {
    size /= (1000 * 1000 * 1000) / 10;
    string = 'GB';
  } else if (size >= (1000 * 1000) / 10) {
    size /= (1000 * 1000) / 10;
    string = 'MB';
  } else if (size >= 1000 / 10) {
    size /= 1000 / 10;
    string = 'KB';
  } else {
    size *= 10;
    string = 'B';
  }

  const value = Math.round(size) / 10;
  return `${value} ${string}`;
}

export function generateRandomString(len){
  return window.btoa(Math.random()).slice(0, len)
}

export function renderHTML(escapedHTML){
  const content = React.createElement("div", {dangerouslySetInnerHTML: {__html: escapedHTML}});
  return content;
}
