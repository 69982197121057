import React from 'react';
import { SubLayout } from 'layouts';
import { Icon, Img } from 'components';
import { Box, Container, CustomH3, GridBox, H4, List, ListItem, RowBox, Span, Text } from 'styles';
import * as S from './ProductStyle';

export function Product(props) {
  return (
    <SubLayout h2="Products & Features" moH2="Products">
      <CustomH3 $paddingTop={50}>OUR SERVICES</CustomH3>
      <S.BgBox>
        <Container $margin="auto" $padding="0 20px" $tPadding="0 16px">
          <GridBox $grid={3} $gap={32} $tGrid={1} $tGap={8}>
            <Box $tPaddingBottom={16}>
              <H4 $type="b38" $color="cardBlue">
                SeaStory700
              </H4>
              <Text $color="gray200" $type="b18" $padding="8px 0">
                AI-based Voyage simulation for
                <br />
                search routes
              </Text>
              <Text $color="gray100" $type="m14">
                Recommended Route, Standard Route and Alternative Routes from one of 3,000 ports in the world to another
              </Text>
            </Box>
            <S.BorderBox>
              <RowBox $gap={8} $alignItems="center" $height={72}>
                <Icon name="Routing" />
                <H4 $type="b" $color="gray200">
                  Route Forecast reflecting limit option of wave, wind and beaufort scale
                </H4>
              </RowBox>
              <Text $marginTop={16} $color="gray100" $type="14" $tHeight={84}>
                Date, Time, Latitude, Longitude, Distance, Engine Speed, Heading, Beaufort Scale, Wind Speed, Wind
                Direction, Wave Height, Wave Direction, Current, Current Direction
              </Text>
            </S.BorderBox>
            <S.BorderBox>
              <RowBox $gap={8} $alignItems="center" $height={72}>
                <Icon name="Note" />
                <H4 $type="b" $color="gray200">
                  Pre Voyage Summary
                </H4>
              </RowBox>
              <Text $marginTop={16} $color="gray100" $type="14" $tHeight={84}>
                Route Forecast, ETD, ETA, Distance Sailing, Time en Route, Engine Speed, Average Speed, Weather Factor,
                Current Factor for recommended route
              </Text>
            </S.BorderBox>
            <S.BorderBox>
              <RowBox $gap={8} $alignItems="center" $height={72}>
                <Icon name="Route" />
                <H4 $type="b" $color="gray200">
                  Routes Comparison
                </H4>
              </RowBox>
              <Text $marginTop={16} $color="gray100" $type="14" $tHeight={84}>
                Distance sailing, ETD, ETA, Time en Route, Engine Speed, Weather Factor, Current Factor, Average Speed
                for Recommended Route, Standard Route and Alternative Routes
              </Text>
            </S.BorderBox>
            <S.BorderBox>
              <RowBox $gap={8} $alignItems="center" $height={72}>
                <Icon name="Wind" />
                <H4 $type="b" $color="gray200">
                  Weather on your routes and for your ships
                </H4>
              </RowBox>
              <Text $marginTop={16} $color="gray100" $type="14" $tHeight={84}>
                Wave, Wind, Swell, Current, Tropical Storm, Ice Cover, Surface Pressure, 500hPa Height, Visibility for
                10days
              </Text>
            </S.BorderBox>
            <S.BorderBox>
              <RowBox $gap={8} $alignItems="center" $height={72}>
                <Icon name="Ship" />
                <H4 $type="b" $color="gray200">
                  Weather in your ports and for your ships
                </H4>
              </RowBox>
              <Text $marginTop={16} $color="gray100" $type="14" $tHeight={84}>
                Port Name, Location, Nationality, Local Time, Wave, Wind, Gust, Current, Weather Condition, Visibility
                for 10days
              </Text>
            </S.BorderBox>
          </GridBox>
        </Container>
      </S.BgBox>
      <Container $padding="98px 0 152px" $tPadding="72px 16px 128px" $margin="auto" $type="our">
        <Box>
          <Box $maxWidth={720} $margin="auto">
            <CustomH3 $textAlign="center">OTHER SERVICES</CustomH3>
            <Text $color="primary600" $type="b18" $paddingTop={48} $textAlign="center">
              Innovative and differentiated diverse marine weather information and ship route services
            </Text>
            <Text $paddingTop={16} $color="gray100" $textAlign="center">
              Embark on a journey with the "SeaStory" Platform, where innovation meets the seas. Our cutting-edge
              service is tailored to provide unparalleled marine weather information and ship route services, setting a
              new standard in maritime navigation.
            </Text>
          </Box>
          <S.GrayBox $tFlexDirection="column">
            <Img src="story500f" />
            <Box>
              <H4 $type="b24" $tType="b20" $color="primary600">
                SeaStory 500F
              </H4>
              <Text $color="gray100" $type="m" $marginTop={8}>
                Intelligent Fleet Management System Based on the Marine Meteorological Information Platform
              </Text>
              <List $maxWidth={568}>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      1.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      Fleet Management
                      <Span $color="gray100">
                        <br />- Location and details of vessels
                        <br />- Pre-voyage report / Performance report
                        <br />- Alert for divergence and heavy weather
                      </Span>
                    </Text>
                  </RowBox>
                </ListItem>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      2.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      Route Recommendation
                      <Span $color="gray100">
                        <br />- Recommended route based on AI
                        <br />- Standard route and alternative routes
                      </Span>
                    </Text>
                  </RowBox>
                </ListItem>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      3.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      Fluid motion visualization for weather display
                    </Text>
                  </RowBox>
                </ListItem>
              </List>
            </Box>
          </S.GrayBox>
          <S.GrayBox $tFlexDirection="column-reverse">
            <Box>
              <H4 $type="b24" $tType="b20" $color="primary600">
                SeaStory 300Vx
              </H4>
              <Text $color="gray100" $type="m" $marginTop={8}>
                Marine Meteorological Information Services for Yachts and Boats
              </Text>
              <List $maxWidth={568}>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      1.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      Location tracking
                    </Text>
                  </RowBox>
                </ListItem>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      2.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      Weather condition, wind, wave, current and heavy weather
                    </Text>
                  </RowBox>
                </ListItem>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      3.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      High-resolution forecast for spacetime
                    </Text>
                  </RowBox>
                </ListItem>
              </List>
            </Box>
            <Img src="story300vx" />
          </S.GrayBox>
          <S.GrayBox $tFlexDirection="column">
            <Img src="story300m" />
            <Box>
              <H4 $type="b24" $tType="b20" $color="primary600">
                SeaStory 300M
              </H4>
              <Text $color="gray100" $type="m" $marginTop={8}>
                ‘SeaStory300M’ is the android version of ‘SeaStory300’
              </Text>
              <List $maxWidth={568}>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      1.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      Weather on your routes
                      <Span $color="gray100">
                        <br />- Wave, Wind, Swell, Current, Tropical Storm for 10days
                      </Span>
                    </Text>
                  </RowBox>
                </ListItem>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      2.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      Weather in your ports
                      <Span $color="gray100">
                        <br />- Port Name, Location, Wave, Wind, Swell, Current for 3days
                      </Span>
                    </Text>
                  </RowBox>
                </ListItem>
                <ListItem $marginTop={8}>
                  <RowBox>
                    <Span $type="b" $color="gray800">
                      3.&nbsp;
                    </Span>
                    <Text $type="b" $color="gray800">
                      Standard Routes from one of 3,000 ports to one of 3,000 ports
                    </Text>
                  </RowBox>
                </ListItem>
              </List>
            </Box>
          </S.GrayBox>
        </Box>
      </Container>
    </SubLayout>
  );
}
