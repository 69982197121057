import React, { useState } from 'react';
import { SingleInputFile } from 'components/form';
import { Box, ColBox, Container, CustomH3, Text, RowBox } from 'styles';
import { Alert, Button, Icon, Input, InputLabel, FormikTextarea, FormikInput, FormikDropdown, Confirm } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getByteSize } from 'utils';
import { useTranslation } from 'react-i18next';
import CommonCodeService from 'services/common/CommonCodeService';
import { useAsync } from 'react-use';
import { useNavigate } from 'react-router-dom';
import boardService from 'services/board/BoardService';

export function Qna(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [reset, setReset] = useState(null);

  const [priorityCodes, setPriorityCodes] = useState([]);

  useAsync(async () => {
    const codes = await CommonCodeService.listCommonCodes('CP');

    setPriorityCodes(codes);
  }, []);

  const saveContent = async () => {
    if (!loading) {
      setLoading(true);
      setDisabledSubmit(true);

      const response = await boardService.saveContent(formValues);

      setLoading(false);
      setDisabledSubmit(false);

      if (response && response.success) {
        setAlert(true);
        if (typeof reset === 'function') {
          reset();
        }
      }
    }
  };

  const schema = yup.object().shape({
    regUserEmail: yup
      .string()
      .required(t('validation.required.entry'))
      .email(t('validation.valid.email.address'))
      .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
    title: yup
      .string()
      .required(t('validation.required.entry'))
      .test('maxByte', t('validation.max.byte', { maxByte: 100 }), (val) => getByteSize(val) <= 100),
    content: yup.string().required(t('validation.required.entry'))
  });

  const formik = useFormik({
    initialValues: {
      regUserEmail: '',
      title: '',
      content: '',
      priority: '',
      attachments: null,
      agree: false
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      console.info(values);
      setFormValues(values);
      setReset(resetForm);
      setConfirm(true);
    }
  });

  return (
    <>
      <Container $padding="74px 20px 128px" $tPadding="40px 16px 64px" $margin="auto">
        <CustomH3 $tStart>QUESTION AND ANSWER</CustomH3>
        <Text $color="gray100" $type="b18" $padding="32px 0" $tPadding="16px 0" $textAlign="center" $tTextAlign="start">
          Please enter the details of your request.
          <br />A member of our support staff will respond as soon as possible.
        </Text>
        <ColBox $gap={24} $boxShadow="new" $margin="40px" $padding="40px" $tMargin="0" $tPadding="16px" $borderRadius={15}>
          <FormikInput
            path="qna"
            name="regUserEmail"
            label="Your email address"
            htmlFor="regUserEmail"
            placeholder=""
            $flex={1}
            $tWidth="100%"
            maxLength={100}
            value={formik.values.regUserEmail}
            invalid={formik.errors.regUserEmail}
            touched={formik.touched.regUserEmail}
            onChange={(_name, value) => {
              formik.setFieldValue('regUserEmail', value);
            }}
            onBlur={formik.handleBlur}
            $require
          />
          <FormikInput
            path="qna"
            name="title"
            label="Subject"
            htmlFor="title"
            placeholder=""
            $flex={1}
            $tWidth="100%"
            maxLength={100}
            value={formik.values.title}
            invalid={formik.errors.title}
            touched={formik.touched.title}
            onChange={(_name, value) => {
              formik.setFieldValue('title', value);
            }}
            onBlur={formik.handleBlur}
            $require
          />

          <FormikTextarea
            label="Description"
            htmlFor="content"
            name="content"
            value={formik.values.content}
            invalid={formik.errors.content}
            touched={formik.touched.content}
            onChange={(_name, value) => {
              formik.setFieldValue('content', value);
            }}
            onBlur={formik.handleBlur}
            $require
          />

          <FormikDropdown
            label="Priority"
            name="priority"
            placeholder="Select"
            valueField="commonCode"
            textField="commonCodeName"
            value={formik.values.priority || ''}
            invalid={formik.errors.priority}
            touched={formik.touched.priority}
            onChange={(name, value) => {
              formik.setFieldValue('priority', value);
            }}
            list={priorityCodes}
          />

          <Box>
            <InputLabel label="Attachement" />
            <SingleInputFile
              onChange={(file) => {
                formik.setFieldValue('attachments', file);
              }}
            />
          </Box>
          <RowBox $gap={16} $justifyContent="center">
            <Button onClick={formik.handleSubmit} $width={196} $height={54} loading={loading} disabled={disabledSubmit}>
              Submit
            </Button>

            <Button
              $width={196}
              $height={54}
              $color="gray900"
              $type="b14"
              mode="line"
              onClick={() => {
                navigate('/');
              }}>
              Cancel
            </Button>
          </RowBox>
        </ColBox>
      </Container>
      <Alert
        open={alert}
        setOpen={setAlert}
        onClick={() => {
          setAlert(false);
          navigate('/');
        }}>
        <Text $color="gray800">
          The response will be sent to the email address registered when making the request. If the deadline has passed, We would appreciate it if you
          could send us a request email again.
        </Text>
        <Box $width="fit-content" $margin="28px auto">
          <Icon name="EmailAnswer" />
        </Box>
        <Text $color="gray800">
          We will respond to your request as soon as possible. Please understand that it may take some time to respond, as we respond in the order in which
          requests are received.
        </Text>
      </Alert>

      <Confirm
        open={confirm}
        setOpen={setConfirm}
        onClickOk={() => {
          setConfirm(false);
          saveContent();
        }}>
        Would you like to submit a QnA?
      </Confirm>
    </>
  );
}
