import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ko from './translations/ko.json';
import en from './translations/en.json';

i18n
  .use(initReactI18next)
  .init({
    lng: "en",
    // we init with resources
    resources: {
      en: {
        translations: en
      },
      ko: {
        translations: ko
      }
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i']
    }
});

export default i18n;