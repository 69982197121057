import { css } from 'styled-components';
import { checkPixel } from 'utils';

const apply = (prop, value) => prop && `${prop}: ${value};`;

export const common = css`
  ${({ $scrollMarginTop }) => $scrollMarginTop && `scroll-margin-top:${checkPixel($scrollMarginTop)}`};
  ${({ $display }) => $display && apply('display', $display)};
  ${({ $overflow }) => $overflow && apply('overflow', $overflow)};
  ${({ $overflowY }) => $overflowY && apply('overflow-y', $overflowY)};
  ${({ $visibility }) => $visibility && apply('visibility', $visibility)};
  ${({ $transform }) => $transform && apply('transform', $transform)};
  ${({ $cursor }) => $cursor && apply('cursor', $cursor)};
  ${({ $flex }) => $flex && apply('flex', $flex)};
  ${({ $transition }) => $transition && apply('transition', $transition)};
  ${({ $lineHeight }) => $lineHeight && apply('line-height', $lineHeight)};
  ${({ $boxShadow, theme }) => $boxShadow && apply('box-shadow', theme.shadow[$boxShadow])};
  ${({ $backgroundColor, theme }) =>
    $backgroundColor && apply('background-color', theme.colors[$backgroundColor] || $backgroundColor)};

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.darkGray};
  }

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $tScrollMarginTop }) => $tScrollMarginTop && `scroll-margin-top:${checkPixel($tScrollMarginTop)}`};
    ${({ $tDisplay }) => $tDisplay && apply('display', $tDisplay)};
    ${({ $tOverflowY }) => $tOverflowY && apply('overflow-y', $tOverflowY)};
    ${({ $tVisibility }) => $tVisibility && apply('visibility', $tVisibility)};
    ${({ $tTransform }) => $tTransform && apply('transform', $tTransform)};
    ${({ $tCursor }) => $tCursor && apply('cursor', $tCursor)};
    ${({ $tFlex }) => $tFlex && apply('flex', $tFlex)};
    ${({ $tTransition }) => $tTransition && apply('transition', $tTransition)};
    ${({ $tBackgroundColor, theme }) =>
      $tBackgroundColor && apply('background-color', theme.colors[$tBackgroundColor] || $tBackgroundColor)};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({ $mScrollMarginTop }) => $mScrollMarginTop && `scroll-margin-top:${checkPixel($mScrollMarginTop)}`};
    ${({ $mDisplay }) => $mDisplay && apply('display', $mDisplay)};
    ${({ $mOverflowY }) => $mOverflowY && apply('overflow-y', $mOverflowY)};
    ${({ $mVisibility }) => $mVisibility && apply('visibility', $mVisibility)};
    ${({ $mTransform }) => $mTransform && apply('transform', $mTransform)};
    ${({ $mFlex }) => $mFlex && apply('flex', $mFlex)};
    ${({ $mCursor }) => $mCursor && apply('cursor', $mCursor)};
    ${({ $mTransition }) => $mTransition && apply('transition', $mTransition)};
    ${({ $mBackgroundColor, theme }) =>
      $mBackgroundColor && apply('background-color', theme.colors[$mBackgroundColor] || $mBackgroundColor)};

    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;
